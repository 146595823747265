import { InputStyled } from './Input.styled';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  textarea?: boolean;
  className?: string;
  errorMessage?: string;
}

export const Input: React.FC<InputProps> = ({
  className,
  type = 'text',
  label = '',
  name = 'Input',
  accept = '*',
  textarea = false,
  pattern = '[^#_@£$|*/]{1,500}',
  errorMessage = '',
  ...rest
}) => (
  <InputStyled className={className}>
    {label && <label htmlFor={name}>{label}</label>}

    {textarea && <textarea id={name} {...rest} />}
    {!textarea && (
      <input
        type={type}
        id={name}
        name={name}
        accept={accept}
        pattern={pattern}
        {...rest}
      />
    )}
    {errorMessage && <span className="ErrorMsg">{errorMessage}</span>}
  </InputStyled>
);
