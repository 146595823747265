import { GuidePlugStyled, Intro, Title } from './GuidePlug.styled';
import WorkwearSvg from './workwear.svg';

export interface GuidePlugProps {
  title?: string | null;
  intro?: string | null;
  workwear?: boolean;
  onClick?: React.MouseEventHandler;
}

export const GuidePlug: React.FC<GuidePlugProps> = ({
  onClick,
  workwear,
  title,
  intro,
}) => (
  <GuidePlugStyled onClick={onClick}>
    {workwear && (
      <WorkwearSvg
        aria-hidden={true}
        focusable={false}
        width={48}
        height={48}
      />
    )}
    <Title>{title}</Title>
    <Intro>{intro}</Intro>
  </GuidePlugStyled>
);
