import TrashSvg from '../../svg/trash.svg';

interface TrashIconProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  className?: string;
}

export const TrashIcon: React.FC<TrashIconProps> = ({
  width = 16,
  ...rest
}) => {
  return (
    <TrashSvg
      aria-hidden={true}
      focusable={false}
      width={width}
      height={rest.height || width}
      {...rest}
    />
  );
};
