import { AggregatedJeansSize, JeansSizeLists } from '@hultafors/snickers/types';

import { GENDERS, SIZE_CATEGORIES } from '../sizes';

export const aggregateJeansLists = (
  sizeLists: JeansSizeLists,
): AggregatedJeansSize[] => {
  const result: AggregatedJeansSize[] = [];

  const womenLeg = sizeLists.women.map((x) => x.leg);
  const menLeg = sizeLists.men.map((x) => x.leg);

  const combineLeg = [...womenLeg, ...menLeg].sort();

  // 3-series is contained within men and women trousers
  combineLeg.map((item) => {
    const men = sizeLists.men.find(({ leg }) => leg === item);
    if (men) {
      const newSize: AggregatedJeansSize = {
        ...men,
        gender: GENDERS['MEN'],
        sizeCategory: SIZE_CATEGORIES['TROUSERS'],
      };
      result.push(newSize);
    } else {
      const women = sizeLists.women.find((x) => x.leg === item);
      if (women) {
        const newSize: AggregatedJeansSize = {
          ...women,
          gender: GENDERS['WOMEN'],
          sizeCategory: SIZE_CATEGORIES['TROUSERS'],
        };
        result.push(newSize);
      }
    }
  });
  return result;
};
