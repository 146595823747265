import Link from 'next/link';

import { Store } from '@hultafors/shared/types';

import { OnlineStoreListStyled } from './OnlineStoreList.styled';

export interface OnlineStoreListProps {
  stores: Store[];
}
// TODO this a warning???
export const OnlineStoreList: React.FC<OnlineStoreListProps> = ({ stores }) => {
  const storeListMapper = (store: Store, index: number) => {
    return (
      <Link
        href={store.data.webSite || ''}
        target="_blank"
        rel="noopener noreferrer"
        key={`Online-Store-${index}`}
      >
        {store.data.companyName || store.data.webSite}
      </Link>
    );
  };
  return (
    <OnlineStoreListStyled>{stores.map(storeListMapper)}</OnlineStoreListStyled>
  );
};
