import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';

export const AccordionOldStyled = styled.div`
  width: 100%;

  &.FilterMenu {
    border-block-start: 1px solid ${colors.gray4};
  }

  &.ProductDetails {
    margin-block-start: 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-start: 0;
    }

    .MaterialsText {
      margin-block: 0 24px;
      font-size: ${fontSizes.body};

      @media screen and (min-width: ${breakpoints.desktop}) {
        font-size: ${fontSizes.m};
      }
    }

    .ReadMoreLink {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        inline-size: auto;
        font-weight: ${fontWeights.normal};

        svg {
          inline-size: 1rem;
          block-size: 1rem;
          margin-inline-start: 0.5rem;
          transform: rotate(-90deg);

          path {
            fill: ${colors.workwearBlack};
            transition: all 0.2s;
          }
        }
      }
    }

    .notCollapsibleItem a {
      text-decoration: none;
      inline-size: 100%;
      margin: 0;
      line-height: 70px;
      cursor: pointer;

      h4 {
        margin: 0;
      }
    }

    .notCollapsibleItem a:hover {
      color: ${colors.gray1};
    }

    .ReadMoreLink a:hover {
      color: ${colors.gray1};

      svg path {
        fill: ${colors.gray1};
      }
    }
  }

  .notCollapsibleItem {
    display: flex;
    block-size: 70px;
    border-block-start: 1px solid ${colors.gray4};
    padding: 0 20px;
    margin: 0 -20px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 0;
      padding: 0;
    }
  }

  .CollapseContent .notCollapsibleItem {
    border-block-start: 0;
    padding: 0;
    margin: 0;
  }

  .CollapseButton-Container {
    inline-size: 100%;
    block-size: 56px;
    box-sizing: border-box;
    border-block-start: 1px solid ${colors.gray4};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .CollapseButton {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-inline-start: 10px;
      inline-size: 12px;
    }
  }
`;
