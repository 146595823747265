import { useEffect, useRef } from 'react';

import Script from 'next/script';

import { DotDigitalFormFragment } from '@hultafors/snickers/types';

import { DotDigialWrapperStyled } from './DotDigitalFormBlock.styled';

export const DotDigitalFormBlock: React.FC<DotDigitalFormFragment> = ({
  formUrl,
  placeInFooter,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const iframeWrapperRef = useRef<HTMLDivElement | null>(null);

  /*
  Ugly hack to make the form render in the footer if
  the form is not a popover
  */
  function appendFormToFooter() {
    const iframeElement = document.querySelector(
      '._lpSurveyEmbed',
    ) as HTMLIFrameElement;
    const iframeWrapper = document.createElement('div');
    const footerMiddleSection = document.getElementById(
      'footer-middle-section',
    );

    // Apply styles to iframe
    iframeElement.style.width = '100%';
    iframeElement.style.position = 'relative';

    // Apply styles to wrapper
    iframeWrapper.style.width = '100%';
    iframeWrapper.style.maxWidth = '600px';
    iframeWrapper.appendChild(iframeElement);

    if (placeInFooter) {
      // Append to the footer
      footerMiddleSection?.appendChild(iframeWrapper);
    } else {
      containerRef?.current?.appendChild(iframeWrapper);
    }

    iframeWrapperRef.current = iframeWrapper;
  }

  useEffect(() => {
    return () => {
      document.querySelector('#lpclose')?.dispatchEvent(new Event('click'));
    };
  }, []);

  useEffect(() => {
    return () => {
      // Remove the iframe element from the footer when the component unmounts
      if (iframeWrapperRef.current) {
        iframeWrapperRef?.current.remove();
      }
    };
  }, []);

  return formUrl?.includes('popover')
    ? (
      <Script src={formUrl || undefined} />
      )
    : (
      <DotDigialWrapperStyled ref={containerRef}>
        <Script src={formUrl || undefined} onReady={appendFormToFooter} />
      </DotDigialWrapperStyled>
      );
};
