import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/snickers/helpers';

export const OnlineStoreListStyled = styled.div`
  a {
    display: flex;
    padding: ${spacing.small} 0;
    text-decoration: none;
    border-block-end: 1px solid ${colors.gray4};
    font-size: ${fontSizes.body};
    position: relative;

    &:first-child {
      padding-block-start: 0;
    }

    &::after {
      content: '';
      position: absolute;
      inset-inline-end: 0;
      inline-size: 16px;
      block-size: 16px;
      background-image: url('/svg/external-link.svg');
      background-repeat: no-repeat;
      background-position: center;
      animation-iteration-count: infinite;
      transform-origin: center;
    }
  }
`;
