import { useFavorites, useGlobal } from '@hultafors/snickers/hooks';
import { SnickersProduct } from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Product } from '../Product/Product';
import { SlideIn } from '../SlideIn/SlideIn';

export const FAVORITES_DRAWER_ID = 'FavoritesDrawer';

export interface FavoritesProps {
  isOpen: boolean;
  toggleMenu<T = string>(key?: T | null): void;
  header: string;
  clearText: string;
  newText: string;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header,
  clearText,
  newText,
}) => {
  const { global } = useGlobal();
  const { favorites, deleteFavorites } = useFavorites();
  return (
    <div>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={`${header} (${favorites.length})`}
        headerIcon="/assets/gfx/star-yellow.svg"
        clearText={clearText}
        clearAll={deleteFavorites}
        padContent
        fromLeft={false}
        id={FAVORITES_DRAWER_ID}
      >
        <Grid
          columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 6 }]}
        >
          {favorites.map((product, i) => {
            return (
              <GridChild
                key={i}
                columnSpan={[
                  { columns: 2 },
                  { breakpoint: 'mobileMax', columns: 3 },
                ]}
              >
                <Product
                  product={product as SnickersProduct}
                  inFavorites
                  isSmall
                  badgeText={newText}
                  rrpLabel={global.rrpLabel || ''}
                  rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
                />
              </GridChild>
            );
          })}
        </Grid>
      </SlideIn>
    </div>
  );
};
