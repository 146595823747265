export const fontSizes = {
  body: '1rem',
  contentMobile: '0.875',
  h1: '2.625rem',
  h1Large: '3.75rem',
  h1LargeMobile: '2.85rem',
  h1Mobile: '1.575rem',
  h2: '2rem',
  h3: '1.375rem',
  header1Content: '30px',
  header2: '1.875rem',
  header2Content: '22px',
  header3: '1.25rem',
  header3Content: '18px',
  header4: '1.125rem',
  heroTitle: '1.625rem',
  l: '1.125rem',
  m: '0.875rem',
  megabody: '1.25rem',
  mini1: '0.75rem',
  mini2: '0.625rem',
  mini2mobile: '0.725rem',
  productMobile: '1.425rem',
  s: '0.75rem',
  supermegabody: '1.75rem',
} as const;

export type FontSizes = keyof typeof fontSizes;
