import { H2Styled } from './H2.styled';

export interface H2Props {
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
}

export const H2: React.FC<H2Props> = ({ center, children, className }) => {
  return (
    <H2Styled $center={center} className={className}>
      {children}
    </H2Styled>
  );
};
