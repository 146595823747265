import styled from 'styled-components';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';

import { TextButton } from '../../TextButton/TextButton';

export const IntroTextButton = styled(TextButton)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.normal};
  border-bottom: 1px solid ${colors.gray4};
  letter-spacing: 0.2px;
  color: inherit;
  height: 63px;

  &::after {
    block-size: 12px;
    inline-size: 12px;
  }
`;

export const IntroSelection = styled.div`
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-block-end: 1px solid ${colors.gray4};
    padding: 20px;
    text-decoration: none;
    cursor: pointer;

    &:first-child {
      border-block-start: 1px solid ${colors.gray4};
    }

    p {
      margin: 0;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 22px;
      margin-inline-end: 25px;
    }

    img {
      inline-size: 12px;
      block-size: 12px;
    }
  }
`;
