import { useRef } from 'react';

import { VideoFileFragment, VideoFragment } from '@hultafors/snickers/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { GridChildColumnSpanProp } from '../GridChild/GridChild.styled';

import {
  ControlButton,
  VideoControls,
  VideoPlayer,
  VideoWrapper,
} from './Video.styled';

export interface VideoData {
  streamingUrl?: string;
  mp4Url?: string;
  thumbnailUrl?: string;
}

export interface VideoProps {
  video?: VideoFileFragment['video'];
  autoPlay?: boolean;
  videoThumbnail: VideoFragment['videoThumbnail'];
  fullWidth?: boolean;
}

export const Video: React.FC<VideoProps> = ({
  video,
  fullWidth,
  autoPlay,
  videoThumbnail,
}) => {
  const videoPlayer = useRef<HTMLVideoElement>(null);

  function playVideo() {
    videoPlayer.current?.play();
    const controls = document.getElementById('controls');
    if (controls) {
      controls.style.display = 'none';
    }
    videoPlayer.current?.setAttribute('controls', 'controls');
  }

  const columnSpan: GridChildColumnSpanProp = [
    { columns: 10 },
    { breakpoint: 'mobileMax', columns: 12 },
    { breakpoint: 'desktop', columns: 10, start: 2 },
  ];

  const Container: React.FC<{ children: React.ReactNode }> = ({ children }) =>
    fullWidth
      ? (
        <>{children}</>
        )
      : (
        <Grid>
          <GridChild columnSpan={columnSpan}>{children}</GridChild>
        </Grid>
        );

  return (
    <Container>
      {video
        ? (
          <VideoWrapper>
            <VideoPlayer
              preload="auto"
              ref={videoPlayer}
              poster={
              videoThumbnail
                ? `${videoThumbnail?.responsiveImage?.src}`
                : `${video.thumbnailUrl}?w=1000`
            }
              autoPlay={autoPlay}
            >
              <source src={`${video.streamingUrl}`} type="video/mp4" />
              <source src={`${video.mp4Url}`} type="video/mp4" />
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a
                {' '}
                <a href={video.mp4Url ?? ''}>link to the video</a>
                {' '}
                instead.
              </p>
            </VideoPlayer>
            <VideoControls>
              <ControlButton title="play" onClick={playVideo} id="controls" />
            </VideoControls>
          </VideoWrapper>
          )
        : null}
    </Container>
  );
};
