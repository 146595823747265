import { MinMax } from '../min-max';

export type ProductDetailsApiSectionType =
  | 'product_descr'
  | 'product_detail_attributes'
  | 'product_documents'
  | 'product_image_library'
  | 'product_price'
  | 'product_relation_list'
  | 'combined_with'
  | 'product_sku_number';

export type ProductDetailsApiSectionCodename =
  | 'accessories'
  | 'all'
  | 'brand_segment'
  | 'buy'
  | 'bynder_video'
  | 'bynder_video_2'
  | 'category'
  | 'categoryURL'
  | 'ce_icons'
  | 'cert_symbols'
  | 'certification'
  | 'certifications'
  | 'cobranding'
  | 'collection'
  | 'color_priority'
  | 'colors'
  | 'combined_with'
  | 'details'
  | 'documents'
  | 'environment_img'
  | 'environment_selection'
  | 'environment'
  | 'esd'
  | 'extension-pack'
  | 'feature1'
  | 'feature2'
  | 'feature3'
  | 'feature4'
  | 'feature5'
  | 'features'
  | 'fit'
  | 'garment'
  | 'gender'
  | 'images'
  | 'inheritable'
  | 'intro'
  | 'isNew'
  | 'materials'
  | 'measure_image'
  | 'models_hgab'
  | 'models'
  | 'name'
  | 'new_flag'
  | 'parentCategory'
  | 'pictograms'
  | 'premium'
  | 'price_temp'
  | 'price'
  | 'product_relations'
  | 'product_relations2'
  | 'productprice'
  | 'productvideo'
  | 'related_products'
  | 'safety_class'
  | 'size'
  | 'sizes'
  | 'sku'
  | 'snr'
  | 'spareparts'
  | 'sustainability1'
  | 'sustainability2'
  | 'sustainable'
  | 'technical_data'
  | 'technical_description'
  | 'usp'
  | 'video_2'
  | 'video'
  | 'washing';

export interface ProductSectionSystem {
  self: string | null;
  id: number;
  type?: ProductDetailsApiSectionType;
  codename?: ProductDetailsApiSectionCodename;
  slug?: string | null;
  contentSchema?: string | null;
  versionId?: string | null;
}

export interface ProductDetailsApiDocument {
  url: string;
  name: string;
  filetype: string;
  size: string;
  attributes?: ProductDetailsValue[];
}

export interface ProductDetailsApiImage {
  placeholder?: string;
  sku?: string;
  url: string;
  description?: null | string;
  format?: null | string;
}

export function isProductDetailsApiImage(
  image: any,
): image is ProductDetailsApiImage {
  if (image && Object.prototype.hasOwnProperty.call(image, '__typename')) {
    return false;
  }
  if (image && Object.prototype.hasOwnProperty.call(image, 'url')) {
    return true;
  }
  return false;
}

export interface ProductDetailsValue {
  text: string;
  image: ProductDetailsApiImage | null;
}

export interface ProductDetailsAttribute {
  name: string;
  slug?: string | null;
  values: ProductDetailsValue[];
}

export interface ProductSectionData {
  images?: ProductDetailsApiImage[];
  field?: string;
  value?: string;
  sku?: string;
  attributes?: ProductDetailsAttribute[] | null;
  documents?: ProductDetailsApiDocument[];
  net?: MinMax;
  gross?: any;
  netIncludingVAT?: any;
  grossIncludingVAT?: any;
  currency?: Currency;
}

export interface ProductDetailsApiContent {
  system: ProductSectionSystem;
}

export interface ProductDetailsApiSection {
  item?: any;
  system: ProductSectionSystem;
  data: ProductSectionData | null;
}

interface Currency {
  code: string;
}

interface BaseProductDetailsApiResponse {
  system?: ProductSectionSystem;
  header?: ProductDetailsApiContent | null;
  footer?: ProductDetailsApiContent | null;
  sections: ProductDetailsApiSection[];
}
export type ProductDetailsApiResponse = BaseProductDetailsApiResponse;

// export type ProductDetailsApiResponse = BaseProductDetailsApiResponse &
//   Record<
//     Exclude<any, keyof BaseProductDetailsApiResponse>,
//     ProductDetailsApiSection
//   >;
