import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  FeedBlockPlug,
  isDoubleTextBlock,
  isDualImage,
  isFactBox,
  isImageBlock,
  isImageSlider,
  isLinkBlock,
  isPerson,
  isPersonInfo,
  isQuote,
  isTextBlock,
  isVideo,
} from '@hultafors/snickers/types';

const Video = dynamic(() =>
  import('../Video/Video').then((module) => module.Video),
);
const ArticleSection = dynamic(() =>
  import('../ArticleSection/ArticleSection').then(
    (module) => module.ArticleSection,
  ),
);
const DocumentLinkBlock = dynamic(() =>
  import('../DocumentLinkBlock/DocumentLinkBlock').then(
    (module) => module.DocumentLinkBlock,
  ),
);
const DoubleImage = dynamic(() =>
  import('../DoubleImage/DoubleImage').then((module) => module.DoubleImage),
);
const FactBox = dynamic(() =>
  import('../FactBox/FactBox').then((module) => module.FactBox),
);
const Grid = dynamic(() =>
  import('../Grid/Grid').then((module) => module.Grid),
);
const GridChild = dynamic(() =>
  import('../GridChild/GridChild').then((module) => module.GridChild),
);
const ImageBlock = dynamic(() =>
  import('../ImageBlock/ImageBlock').then((module) => module.ImageBlock),
);
const ImageSlider = dynamic(() =>
  import('../ImageSlider/ImageSlider').then((module) => module.ImageSlider),
);
const Person = dynamic(() =>
  import('../Person/Person').then((module) => module.Person),
);
const PersonInfo = dynamic(() =>
  import('../PersonInfo/PersonInfo').then((module) => module.PersonInfo),
);
const QuoteBlock = dynamic(() =>
  import('../QuoteBlock/QuoteBlock').then((module) => module.QuoteBlock),
);
const Section = dynamic(() =>
  import('../Section/Section').then((module) => module.Section),
);
const TextBlockOld = dynamic(() =>
  import('../TextBlockOld/TextBlockOld').then((module) => module.TextBlockOld),
);
const TwoColumnText = dynamic(() =>
  import('../TwoColumnText/TwoColumnText').then(
    (module) => module.TwoColumnText,
  ),
);

export interface DynamicContentProps {
  content?: FeedBlockPlug[];
}

export const DynamicContent: React.FC<DynamicContentProps> = ({
  content = [],
}) => {
  const uid = useId();

  const feedMapper = (item: any, index: number): React.ReactNode => {
    const key = `Feed-${uid}-${item.__typename}-${index}`;
    if (isDoubleTextBlock(item)) {
      return (
        <Section noMarginTop padding key={key}>
          <TwoColumnText
            header={item.header || ''}
            firstColumnText={item.leftBody || ''}
            secondColumnText={item.rightBody || ''}
            CTAText={item.ctaLabel || ''}
            CTALink={item.ctaLink || ''}
          />
        </Section>
      );
    }
    if (isDualImage(item)) {
      return (
        <Section paddingBottom fullBleed key={key}>
          <DoubleImage images={item.dualImage} />
        </Section>
      );
    }
    if (isFactBox(item)) {
      return <FactBox key={key} {...item} />;
    }
    if (isImageBlock(item)) {
      return (
        <Section noMarginTop zeroBottom key={key}>
          <ImageBlock image={item.image} />
        </Section>
      );
    }
    if (isImageSlider(item)) {
      return (
        <Section padding key={key}>
          <Grid>
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12 },
                { breakpoint: 'desktop', columns: 10, start: 2 },
              ]}
            >
              <ImageSlider images={item.imageSlider} />
            </GridChild>
          </Grid>
        </Section>
      );
    }
    if (isLinkBlock(item)) {
      return (
        <Section noMarginTop padding key={key}>
          <DocumentLinkBlock {...item} />
        </Section>
      );
    }
    if (isPerson(item)) {
      return (
        <ArticleSection key={key}>
          <Grid
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'mobileMax', columnGap: 20 },
            ]}
            columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
          >
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 10, start: 2 },
                { breakpoint: 'desktop', columns: 8, start: 3 },
                { breakpoint: 'desktopMedium', columns: 6, start: 4 },
              ]}
            >
              {item.name && item.image?.responsiveImage?.src && (
                <Person
                  name={item.name}
                  image={item.image}
                  interviewLabel={item.interviewLabel || ''}
                />
              )}
            </GridChild>
          </Grid>
        </ArticleSection>
      );
    }
    if (isPersonInfo(item)) {
      return (
        <Section noMarginTop padding key={key}>
          <ArticleSection>
            <PersonInfo
              name={item.name || ''}
              image={item.image}
              size={item.size || ''}
              age={item.age}
              family={item.family || ''}
              lives={item.lives || ''}
              profession={item.profession || ''}
              placeOfWork={item.placeOfWork || ''}
              favoriteGarment={item.favoriteGarment || ''}
              sizeLabel={item.sizeLabel || ''}
              ageLabel={item.ageLabel || ''}
              familyLabel={item.familyLabel || ''}
              whereILiveLabel={item.whereILiveLabel || ''}
              professionLabel={item.professionLabel || ''}
              workplaceLabel={item.workplaceLabel || ''}
              favoriteGarmentLabel={item.favoriteGarmentLabel || ''}
            />
          </ArticleSection>
        </Section>
      );
    }
    if (isQuote(item)) {
      return (
        <Section noMarginTop zeroBottom key={key}>
          <QuoteBlock quote={item.text || ''} />
        </Section>
      );
    }
    if (isTextBlock(item)) {
      return (
        <Section noMarginTop fullBleed key={key}>
          <TextBlockOld
            title={item.header || ''}
            text={item.body || ''}
            CTAText={item.ctaLabel || ''}
            CTALink={item.ctaLink || ''}
          />
        </Section>
      );
    }
    if (isVideo(item)) {
      let video;
      if (item.videoUrl?.video) {
        video = item.videoUrl?.video;
      }
      return (
        <Section key={key}>
          <Video key={key} video={video} videoThumbnail={item.videoThumbnail} />
        </Section>
      );
    }
    return null;
  };

  return content?.length ? <>{content.map(feedMapper)}</> : null;
};
