import { ArticleSection } from '../ArticleSection/ArticleSection';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H1 } from '../H1/H1';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';
import { TextBlockOld } from '../TextBlockOld/TextBlockOld';

export interface ContactInformationProps {
  title: string;
  subTitle?: string;
  phoneNumber?: string;
  addressBlock: string;
  visitUsLabel: string;
  visitUsBlock: string;
  customerServiceLabel: string;
  customerServiceBlock: string;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  title,
  subTitle,
  phoneNumber,
  addressBlock,
  visitUsBlock,
  visitUsLabel,
  customerServiceBlock,
  customerServiceLabel,
}) => {
  if (!title && !subTitle) {
    return null;
  }
  return (
    <Section noTop>
      <ArticleSection articleTop>
        <Grid
          columnGap={[
            { columnGap: 15 },
            { breakpoint: 'mobileMax', columnGap: 20 },
          ]}
          columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
        >
          {title && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 10, start: 2 },
                { breakpoint: 'desktop', columns: 8, start: 3 },
              ]}
            >
              <div className="articleTopContent">
                <Grid columns={8}>
                  <GridChild
                    columnSpan={[
                      { columns: 8 },
                      {
                        breakpoint: 'desktopMedium',
                        columns: 6,
                        start: 2,
                      },
                    ]}
                  >
                    <H1>{title}</H1>
                    {subTitle && <Paragraph>{subTitle}</Paragraph>}
                  </GridChild>
                </Grid>
              </div>
            </GridChild>
          )}
        </Grid>
      </ArticleSection>
      {addressBlock && <TextBlockOld text={addressBlock} />}
      {phoneNumber && <TextBlockOld text={phoneNumber} />}
      {visitUsLabel && visitUsBlock && (
        <TextBlockOld title={visitUsLabel} text={visitUsBlock} />
      )}
      {customerServiceLabel && customerServiceBlock && (
        <TextBlockOld
          title={customerServiceLabel}
          text={customerServiceBlock}
        />
      )}
    </Section>
  );
};
