import { StyledDoubleColorText, White, Yellow } from './DoubleColorText.styled';

interface DoubleColorTitle {
  text: string;
  className?: string;
}

export const DoubleColorText: React.FC<DoubleColorTitle> = ({
  text,
  className,
}) => {
  const textFields = text.split('*');

  return (
    <StyledDoubleColorText className={className}>
      <Yellow>{textFields[0]}</Yellow>
      <White>{textFields[1]}</White>
    </StyledDoubleColorText>
  );
};
