import styled from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

export const ResultContainer = styled.div`
  padding-inline: 20px;
`;

export const BottomTitle = styled.p`
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  text-align: center;
`;

export const BottomList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const BottomItem = styled.li`
  list-style: none;
  border-bottom: 1px solid ${colors.gray3};
  padding: 16px 0;
  font-size: 14px;
  text-align: center;
`;
