import { RouterLink } from '../RouterLink/RouterLink';

import { LinkBlockStyled } from './LinkBlock.styled';

export interface LinkBlockProps {
  linkText?: string;
  linkUrl?: string;
  external?: boolean;
  iconNext?: boolean;
}

export const LinkBlock: React.FC<LinkBlockProps> = ({
  linkText,
  linkUrl,
  external,
  iconNext,
}) => {
  if (!linkUrl || !linkText) {
    return null;
  }
  return (
    <LinkBlockStyled>
      <RouterLink
        className="TextLink"
        href={linkUrl}
        external={external}
        iconNext={iconNext}
      >
        {linkText}
      </RouterLink>
    </LinkBlockStyled>
  );
};
