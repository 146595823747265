import Image from 'next/image';
import { CSSProperties } from 'styled-components';

import { PersonFragment } from '@hultafors/snickers/types';

import { ImageWrapper, PersonStyled, TextWrapper } from './Person.styled';

export interface PersonProps extends Omit<PersonFragment, 'id'> {
  marginTopMobile?: CSSProperties['marginTop'];
}

export const Person: React.FC<PersonProps> = ({
  image,
  name,
  interviewLabel,
  marginTopMobile,
}) => {
  return (
    <PersonStyled $marginTopMobile={marginTopMobile}>
      <ImageWrapper>
        <Image
          src={image?.responsiveImage?.src || ''}
          alt={image?.alt || ''}
          fill
          sizes="64px"
          blurDataURL={image?.responsiveImage?.base64 || ''}
          placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
        />
      </ImageWrapper>
      <TextWrapper>
        <p className="Label">{interviewLabel}</p>
        <p className="Name">{name}</p>
      </TextWrapper>
    </PersonStyled>
  );
};
