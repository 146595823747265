import { AnimatePresence, m } from 'framer-motion';

import {
  createProductsMenuUrl,
  recordTypes,
} from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import {
  GlobalFields,
  MenuItemRecord,
  SecondaryMenuItemRecord,
} from '@hultafors/snickers/types';

import { SearchBox } from '../SearchBox/SearchBox';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  DesktopMenuStyled,
  FirstTwoTabItems,
  MenuContentItem,
  MenuItems,
  MenuSection,
  MenuSectionHeader,
  MenuSectionHeaderLink,
  MenuSectionHeaderNoLink,
  MenuSectionItem,
  MenuSectionLink,
  MenuSectionList,
  TabContent,
  TabContentWrapper,
  TabItems,
} from './DesktopMenu.styled';

export const DESKTOP_MENU_DRAWER_ID = 'DesktopMenuDrawer';
export type DesktopMenuType = 'search' | 'CategoryPageRecord';

export interface DesktopMenuProps {
  isOpen: boolean;
  type: DesktopMenuType | null;
  menuItems?: GlobalFields['allMenuItems'];
  menuExtras?: GlobalFields['menuExtra'];
  toggleDesktopMenu(...args: any[]): unknown;
  settings?: unknown;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  menuItems,
  menuExtras,
  toggleDesktopMenu,
  isOpen,
  type,
}) => {
  const { settings } = useGlobal();
  const productsMenu = menuItems?.find(({ children }) => !!children?.length);
  const secondColumnItemIDs = [
    'OQF2OcldTHanZ69mZwd1gg',
    'dNJqbH3MQ9SByUhGj1TE3w',
    'EiFmDul6QGGmQfTAEZjwQQ',
  ];

  const isUsMarket = settings?.market === 'us';

  const productMenuMapper = ({
    id,
    title,
    url,
  }: Partial<SecondaryMenuItemRecord>) => {
    return (
      <MenuSection key={`TabItems-${id}`} title={title || ''}>
        {url && title ? (
          <MenuSectionHeaderLink
            href={url}
            onClick={() => toggleDesktopMenu(recordTypes.CATEGORY)}
          >
            {title}
          </MenuSectionHeaderLink>
        ) : (
          <MenuSectionHeader as="span">{title || ''}</MenuSectionHeader>
        )}
      </MenuSection>
    );
  };

  const categoryMapper = (item: any) => {
    const castItem = item as MenuItemRecord;
    const subItemMapper = (subItem: Partial<MenuItemRecord>) => {
      return (
        !subItem.hide &&
        !subItem.hideOnDesktop && (
          <MenuSectionItem key={`TabSubItems-${subItem.id}`}>
            <MenuSectionLink
              href={createProductsMenuUrl({
                currentNode: subItem,
                parentNode: castItem,
              })}
              onClick={() => toggleDesktopMenu(recordTypes.CATEGORY)}
            >
              {subItem.title}
            </MenuSectionLink>
          </MenuSectionItem>
        )
      );
    };
    return castItem?.children?.length && !castItem.hide ? (
      <MenuSection key={`TabItems-${castItem.id}`}>
        {castItem.url || castItem.pageLink ? (
          <MenuSectionHeaderLink
            href={createProductsMenuUrl({
              currentNode: castItem,
            })}
            onClick={() => toggleDesktopMenu(recordTypes.CATEGORY)}
          >
            {castItem.title}
          </MenuSectionHeaderLink>
        ) : (
          <MenuSectionHeaderNoLink> {castItem.title}</MenuSectionHeaderNoLink>
        )}

        {castItem.children && !castItem.hide && (
          <MenuSectionList>
            {castItem.children.filter(Boolean).map(subItemMapper)}
          </MenuSectionList>
        )}
      </MenuSection>
    ) : (
      !castItem.hide && (
        <li key={`TabItems-${castItem?.id}`} title={castItem?.title || ''}>
          <MenuSectionLink
            href={castItem?.url || ''}
            onClick={() => toggleDesktopMenu(recordTypes.CATEGORY)}
          >
            {castItem?.title}
          </MenuSectionLink>
        </li>
      )
    );
  };

  const secondColumnFilter = (item: any) => {
    const castItem = item as MenuItemRecord;
    return (
      castItem?.pageLink?.slug &&
      secondColumnItemIDs.includes(castItem.pageLink.id)
    );
  };

  const otherColumnsFilter = (item: any) => {
    const castItem = item as MenuItemRecord;
    return (
      castItem?.pageLink?.slug &&
      !secondColumnItemIDs.includes(castItem.pageLink.id)
    );
  };

  return (
    <DesktopMenuStyled>
      <SlideIn
        toggle={toggleDesktopMenu}
        isOpen={isOpen}
        fromTop
        small={type === 'search'}
        id={DESKTOP_MENU_DRAWER_ID}
      >
        <AnimatePresence>
          {type === 'search' && (
            <m.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <MenuContentItem>
                <SearchBox
                  wide
                  toggleMenu={toggleDesktopMenu}
                  isOpen={isOpen}
                  isAutoComplete={true}
                />
              </MenuContentItem>
            </m.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {type === recordTypes.CATEGORY && (
            <m.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <MenuContentItem>
                <MenuItems>
                  {productsMenu && (
                    <TabContentWrapper>
                      <TabContent id="TabContent">
                        <TabItems>
                          <FirstTwoTabItems>
                            {menuExtras?.productMenu?.map(productMenuMapper)}
                          </FirstTwoTabItems>
                          {!isUsMarket && (
                            <FirstTwoTabItems>
                              {productsMenu?.children
                                ?.filter(Boolean)
                                .filter(secondColumnFilter)
                                .map(categoryMapper)}
                            </FirstTwoTabItems>
                          )}
                          {productsMenu?.children
                            ?.filter(Boolean)
                            .filter(otherColumnsFilter)
                            .map(categoryMapper)}
                        </TabItems>
                      </TabContent>
                    </TabContentWrapper>
                  )}
                </MenuItems>
              </MenuContentItem>
            </m.div>
          )}
        </AnimatePresence>
      </SlideIn>
    </DesktopMenuStyled>
  );
};
