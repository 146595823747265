import { Market } from '@hultafors/shared/types';

/*
Coords from:
https://github.com/gavinr/world-countries-centroids
*/

/**
 * @desc Single source of truth for markets and languages.
 *       When adding a new market and or languages this is the place to do it
 *       Insert new markets in alphabetic order
 */
export const markets: Market[] = [
  {
    azure: { companyCode: '' },
    defaultCoords: {
      latitude: 57.6983323,
      longitude: 12.7388379,
    },
    hostname: 'www.snickersworkwear.com',
    languages: [
      {
        datoLocale: 'en',
        lang: 'en',
        ptLang: '',
      },
    ],
    market: 'com',
    pt: { siteId: '027' },
  },
  {
    azure: { companyCode: '75' },
    defaultCoords: {
      latitude: 47.631858269895794,
      longitude: 13.797778364631036,
    },
    hostname: 'www.snickersworkwear.at',
    languages: [
      {
        datoLocale: 'de_AT',
        lang: 'de-AT',
        ptLang: '',
      },
    ],
    market: 'at',
    pt: { siteId: '035' },
  },
  {
    azure: { companyCode: '30' },
    defaultCoords: {
      latitude: 50.6182138854095,
      longitude: 4.675010154696485,
    },
    hostname: 'www.snickersworkwear.be',
    languages: [
      {
        datoLocale: 'nl_BE',
        lang: 'nl-BE',
        name: 'Nederlands',
        ptLang: '',
        urlPrefix: '',
      },
      {
        datoLocale: 'fr_BE',
        lang: 'fr-BE',
        name: 'Français',
        ptLang: 'fr', // field used to call parttrap API
        urlPrefix: 'fr', // field used for path in url e.g sitename.be/fr/contact
      },
    ],
    market: 'be',
    pt: { siteId: '031' },
  },
  {
    azure: { companyCode: '80' },
    defaultCoords: {
      latitude: 46.73678128684938,
      longitude: 8.286928794895285,
    },
    hostname: 'www.snickersworkwear.ch',
    languages: [
      {
        datoLocale: 'de_CH',
        lang: 'de-CH',
        name: 'Deutsch',
        ptLang: '',
        urlPrefix: '',
      },
      {
        datoLocale: 'fr_CH',
        lang: 'fr-CH',
        name: 'Français',
        ptLang: 'fr',
        urlPrefix: 'fr',
      },
    ],
    market: 'ch',
    pt: { siteId: '036' },
  },
  {
    azure: { companyCode: '70' },
    defaultCoords: {
      latitude: 51.08304539800482,
      longitude: 10.426171427430804,
    },
    hostname: 'www.snickersworkwear.de',
    languages: [
      {
        datoLocale: 'de_DE',
        lang: 'de-DE',
        ptLang: '',
      },
    ],
    market: 'de',
    pt: { siteId: '033' },
  },
  {
    azure: { companyCode: '25' },
    defaultCoords: {
      latitude: 56.00118817971057,
      longitude: 9.378670542409406,
    },
    hostname: 'www.snickersworkwear.dk',
    languages: [
      {
        datoLocale: 'da_DK',
        lang: 'da-DK',
        ptLang: '',
      },
    ],
    market: 'dk',
    pt: { siteId: '029' },
  },
  {
    azure: { companyCode: '15' },
    defaultCoords: {
      latitude: 65.01578959749911,
      longitude: 25.65738433454702,
    },
    hostname: 'www.snickersworkwear.fi',
    languages: [
      {
        datoLocale: 'fi_FI',
        lang: 'fi-FI',
        ptLang: '',
      },
    ],
    market: 'fi',
    pt: { siteId: '041' },
  },
  {
    azure: { companyCode: '45' },
    defaultCoords: {
      latitude: 46.6423682169416,
      longitude: 2.1940236627886227,
    },
    hostname: 'www.snickersworkwear.fr',
    languages: [
      {
        datoLocale: 'fr_FR',
        lang: 'fr-FR',
        ptLang: '',
      },
    ],
    market: 'fr',
    pt: { siteId: '032' },
  },
  {
    azure: { companyCode: '10' },
    defaultCoords: {
      latitude: 53.30489539816495,
      longitude: -8.241128545554096,
    },
    hostname: 'www.snickersworkwear.ie',
    languages: [
      {
        datoLocale: 'en_IE',
        lang: 'en-IE',
        ptLang: '',
      },
    ],
    market: 'ie',
    pt: { siteId: '040' },
  },
  {
    azure: { companyCode: '95' },
    defaultCoords: {
      latitude: 42.98201127614267,
      longitude: 12.763657166123137,
    },
    hostname: 'www.snickersworkwear.it',
    languages: [
      {
        datoLocale: 'it_IT',
        lang: 'it-IT',
        name: 'Italiano',
        ptLang: '',
        urlPrefix: '',
      },
      {
        datoLocale: 'de_IT',
        lang: 'de-IT',
        name: 'Tedesco',
        ptLang: 'de',
        urlPrefix: 'de',
      },
    ],
    market: 'it',
    pt: { siteId: '037' },
  },
  {
    azure: { companyCode: '85' },
    defaultCoords: {
      latitude: 52.134054128923886,
      longitude: 5.554136426128487,
    },
    hostname: 'www.snickersworkwear.nl',
    languages: [
      {
        datoLocale: 'nl_NL',
        lang: 'nl-NL',
        ptLang: '',
      },
    ],
    market: 'nl',
    pt: { siteId: '030' },
  },
  {
    azure: { companyCode: '35' },
    defaultCoords: {
      latitude: 64.97775882947745,
      longitude: 16.670259272390894,
    },
    hostname: 'www.snickersworkwear.no',
    languages: [
      {
        datoLocale: 'nb_NO',
        lang: 'nb-NO',
        ptLang: '',
      },
    ],
    market: 'no',
    pt: { siteId: '038' },
  },
  {
    azure: { companyCode: '65' },
    defaultCoords: {
      latitude: 52.06848055692473,
      longitude: 19.43573279234468,
    },
    hostname: 'www.snickersworkwear.pl',
    languages: [
      {
        datoLocale: 'pl_PL',
        lang: 'pl-PL',
        ptLang: '',
      },
    ],
    market: 'pl',
    pt: { siteId: '034' },
  },
  {
    azure: { companyCode: '05' },
    defaultCoords: {
      latitude: 62.73420986108448,
      longitude: 17.062431988004956,
    },
    hostname: 'www.snickersworkwear.se',
    languages: [
      {
        datoLocale: 'sv',
        lang: 'sv-SE',
        ptLang: '',
      },
    ],
    market: 'se',
    pt: { siteId: '039' },
  },
  {
    azure: { companyCode: '55' },
    defaultCoords: {
      latitude: 53.97844735080214,
      longitude: -2.852943909329258,
    },
    hostname: 'www.snickersworkwear.co.uk',
    languages: [
      {
        datoLocale: 'en_GB',
        lang: 'en-GB',
        ptLang: '',
      },
    ],
    market: 'uk',
    pt: { siteId: '042' },
  },
  {
    defaultCoords: {
      latitude: 38.8208089190304,
      longitude: -96.33161660829639,
    },
    hostname: 'us.snickersworkwear.com',
    languages: [
      {
        datoLocale: 'en_US',
        lang: 'en-US',
        ptLang: '',
      },
    ],
    market: 'us',
    pt: { siteId: '094' },
  },
  {
    azure: { companyCode: '' },
    defaultCoords: {
      latitude: 58.648108311231034,
      longitude: 25.916870250633806,
    },
    hostname: 'www.snickersworkwear.ee',
    languages: [
      {
        datoLocale: 'en',
        lang: 'en',
        ptLang: '',
      },
    ],
    market: 'ee',
    pt: { siteId: '027' },
  },
  {
    azure: { companyCode: '' },
    defaultCoords: {
      latitude: 56.813853047554154,
      longitude: 24.693671325654403,
    },
    hostname: 'www.snickersworkwear.lv',
    languages: [
      {
        datoLocale: 'en',
        lang: 'en',
        ptLang: '',
      },
    ],
    market: 'lv',
    pt: { siteId: '027' },
  },
];
