import { transformProductForCart } from '@hultafors/snickers/product-helpers';

/**
 * @desc Adds a product to cart. Product is transformed to only needed properties.
 * @param {array} cart - Array of products from local storage
 * @param {object} product - product representation from detail page
 * @param {number} amount - Number of products to add
 * @return {array} cart
 */
export const addToCart = ({
  cart,
  product,
  color,
  size,
  productListId,
}: {
  cart: any[];
  product: any;
  color: any;
  size: any;
  productListId: any;
}) => {
  let result: any[] = [];

  if (!product) {
    return cart ? [...cart] : result;
  }

  const transformedProduct = transformProductForCart(
    product,
    color,
    size,
    productListId,
  );

  if (cart) {
    if (!doesItemExistInCart(transformedProduct, cart)) {
      result = [...cart, transformedProduct];
    }
  } else {
    result = [transformedProduct];
  }

  return result;
};

const doesItemExistInCart = (product: any, cart: any[]) =>
  cart.find((item) => getVariant(item) === getVariant(product)) || undefined;

/**
 * @desc Generate pseudo id for product variant
 * @param {object} product
 * @returns {string}
 */
const getVariant = (product: any) => {
  // identifier for color/size product
  if (product.size) {
    return `${product.sku}_${product.size.id}_${product.color.text}`;
  }
  return `${product.sku}_${product.color.text}`;
};

export const deleteItemFromCart = (cart: any[], product: any) => {
  let result = [];

  if (cart) {
    if (!product) {
      return [...cart];
    }

    const specificToRemove = getVariant(product);

    result = cart.filter((item) => {
      if (specificToRemove !== getVariant(item)) {
        return item;
      }
    });
  }
  return result;
};
