import { cleanTextFromNonLettersAndNumbers } from '@hultafors/shared/helpers';
import { ProductDetailsValue } from '@hultafors/shared/types';

import { ProductSize, SizeCategory, SizeItem } from '@hultafors/snickers/types';

import { generateTrousersList, getAlphaNumericList } from './helpers';

export const SIZE_CATEGORIES = {
  BOTTOMS: 4,
  GLOVES: 8,
  HEADWEAR: 7,
  JUNIOR: 6,
  ONE__PIECE: 5,
  TOPS: 3,
  TROUSERS: 1,
  TROUSERS_3: 2,
} as const;

export const GENDERS = {
  ALL: 3,
  MEN: 1,
  WOMEN: 2,
} as const;

export const SIZE_CATEGORIES_WITH_HIDDEN_SIZE_GROUPS: number[] = [
  SIZE_CATEGORIES['TOPS'],
];

// Ugly hack
const trouserSizes: string[] = [
  '44',
  '46',
  '48',
  '50',
  '52',
  '54',
  '56',
  '58',
  '60',
  '62',
  '64',
  '88',
  '92',
  '96',
  '100',
  '104',
  '108',
  '112',
  '116',
  '120',
  '124',
  '128',
  '144',
  '146',
  '148',
  '150',
  '152',
  '154',
  '156',
  '158',
  '160',
  '162',
  '164',
];

/**
 * @desc Categorizing sizes in Short, Regular, Long etc based on size list from product
 * @param int size (trousers, 6-series)
 * @return object
 */
export const getSizes = (sku: string, sizeList: ProductSize[]): SizeItem[] => {
  const category: SizeCategory = getCategoryFromSku(sku);

  if (
    category.name === SIZE_CATEGORIES['BOTTOMS'] &&
    sizeList[0]?.value &&
    trouserSizes.includes(sizeList[0]?.value)
  ) {
    category.name = SIZE_CATEGORIES['TROUSERS'] || 1;
  }

  if (category.name) {
    return bindSizes(category, sizeList);
  }
  // Category was not found, fallback to just returning a single list of sizes
  return [
    {
      sizes: sizeList,
    },
  ];
};

export const bindSizes = (
  category: SizeCategory,
  sizeList: ProductSize[]
): SizeItem[] => {
  let categorySizeList = [];
  let result: SizeItem[] = [];

  switch (category.name) {
    case SIZE_CATEGORIES['TROUSERS']:
      categorySizeList = generateTrousersList(category.gender);
      if (category.gender === GENDERS['WOMEN']) {
        result = getTrousersListWomanSeries(sizeList, categorySizeList);
        return result ? result : [];
      }
      result = getTrousersList6Series(sizeList, categorySizeList);
      return result ? result : [];
    case SIZE_CATEGORIES['TROUSERS_3']:
      categorySizeList = generateTrousersList(category.gender);
      result = getTrousersList3Series(sizeList, categorySizeList);
      return result ? result : [];
    case SIZE_CATEGORIES['TOPS']:
      categorySizeList = getAlphaNumericList(category.name, category.gender);
      result = getAlphaNumericSeries(sizeList, categorySizeList);
      return result ? result : [];
    case SIZE_CATEGORIES['BOTTOMS']:
      categorySizeList = getAlphaNumericList(category.name, category.gender);
      result = getAlphaNumericSeries(sizeList, categorySizeList);

      return result ? result : [];
    case SIZE_CATEGORIES['ONE__PIECE']:
      categorySizeList = generateTrousersList(category.gender); // one pice hase same size series as 3xxx trousers
      result = getTrousersList3Series(sizeList, categorySizeList);
      return result ? result : [];
    default:
      return getDefaultSizes(sizeList);
  }
};

export const hasProductSizeGuide = (sku: string) => {
  if (!sku) {
    return false;
  }
  const category = getCategoryFromSku(sku);

  switch (category.name) {
    case SIZE_CATEGORIES['TROUSERS']:
    case SIZE_CATEGORIES['TROUSERS_3']:
      return true;
    default:
      return false;
  }
};

export const getCategoryFromSku = (sku: string): SizeCategory => {
  // First two letters in sku identifies product category
  // TODO shorts, shorts3, pirates

  // 2 sku numbers has been incorrectly set by snickers, ugly hack to id them as women trousers
  const TROUSERS_WOMEN_OVERRIDE = ['6247', '6147'];

  if (
    TROUSERS_WOMEN_OVERRIDE.includes(sku) &&
    SIZE_CATEGORIES['TROUSERS'] &&
    GENDERS['WOMEN']
  ) {
    return {
      gender: GENDERS['WOMEN'],
      name: SIZE_CATEGORIES['TROUSERS'],
    };
  }

  const skuIdentifier = parseInt(sku.substring(0, 2));

  if (
    (skuIdentifier > 60 && skuIdentifier <= 65) ||
    (skuIdentifier >= 68 && skuIdentifier <= 69)
  ) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES.TROUSERS,
    };
  } else if (skuIdentifier >= 1 && skuIdentifier <= 9) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['ONE__PIECE'],
    };
  } else if (
    (skuIdentifier >= 11 && skuIdentifier <= 29) ||
    (skuIdentifier >= 42 && skuIdentifier <= 49)
  ) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['TOPS'],
    };
  } else if (
    (skuIdentifier >= 30 && skuIdentifier <= 34) ||
    (skuIdentifier >= 38 && skuIdentifier <= 39)
  ) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['TROUSERS_3'],
    };
  } else if (skuIdentifier === 37 || skuIdentifier === 67) {
    return {
      gender: GENDERS['WOMEN'],
      name: SIZE_CATEGORIES['TROUSERS'],
      // gender: GENDERS.MEN // I believe this has been changed???
    };
  } else if (skuIdentifier === 60) {
    // Overalls jackets and one piece trousers
  } else if (skuIdentifier === 66 || skuIdentifier === 94) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['BOTTOMS'],
    };
  } else if (skuIdentifier === 75) {
    return {
      gender: GENDERS['ALL'],
      name: SIZE_CATEGORIES['JUNIOR'],
    };
  } else if (skuIdentifier >= 80 && skuIdentifier <= 81) {
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['TOPS'],
    };
  } else if (skuIdentifier >= 82 && skuIdentifier <= 83) {
    // TODO both Jackets & Mens bottoms (overall)
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['TOPS'],
    };
  } else if (skuIdentifier === 85) {
    // Shirts men
    return {
      gender: GENDERS['MEN'],
      name: SIZE_CATEGORIES['TOPS'],
    };
  } else if (skuIdentifier === 90) {
    return {
      gender: GENDERS['ALL'],
      name: SIZE_CATEGORIES['HEADWEAR'],
      // gender: 'x'
    };
  } else if (skuIdentifier === 93 || skuIdentifier === 95) {
    return {
      gender: GENDERS['ALL'],
      name: SIZE_CATEGORIES['GLOVES'],
    };
  }

  return {
    gender: GENDERS['ALL'],
    name: -1,
  };

  // 85 skjorta
};

const getDefaultSizes = (sizeList: any) => {
  // for sizes where we just print out the list we get

  return [
    {
      id: '',
      name: '',
      sizes: sizeList,
    },
  ];
};

export const getIsSizeGroupsHiddenForCategory = (
  sizeCategory: SizeCategory | undefined
) => {
  if (
    sizeCategory?.name &&
    SIZE_CATEGORIES_WITH_HIDDEN_SIZE_GROUPS.indexOf(sizeCategory.name) > -1
  ) {
    return true;
  }
  return false;
};

const getAlphaNumericSeries = (sizeList: any[], topList: any[]) => {
  const short: any[] = [];
  const reg: any[] = [];
  const long: any[] = [];

  sizeList.map((item) => {
    const val = cleanTextFromNonLettersAndNumbers(item.value);
    const regularSizes = [
      'xs',
      's',
      'm',
      'l',
      'xl',
      'xxl',
      'xxxl',
      'xxxxl',
      'xxxxxl',
    ];
    const regularSizesWithRegularTitle = regularSizes.map((size) =>
      cleanTextFromNonLettersAndNumbers(size + ' Regular')
    );
    const shortSizes = regularSizes.map((size) =>
      cleanTextFromNonLettersAndNumbers(size + ' Short')
    );
    const longSizes = regularSizes.map((size) =>
      cleanTextFromNonLettersAndNumbers(size + ' Long')
    );

    if (shortSizes.indexOf(val) > -1) {
      short.push(item);
    } else if (longSizes.indexOf(val) > -1) {
      long.push(item);
    } else if (
      regularSizes.indexOf(val) > -1 ||
      regularSizesWithRegularTitle.indexOf(val) > -1
    ) {
      reg.push(item);
    }
  });

  return formatAlphaNumericSizes(topList, short, reg, long);
};

const getTrousersListWomanSeries = (sizeList: any[], trouserList: any[]) => {
  const xShort: any[] = [];
  const short: any[] = [];
  const reg: any[] = [];
  const long: any[] = [];
  const xLong: any[] = [];

  sizeList.map((item: any) => {
    if (item.value >= 116 && item.value <= 127) {
      xShort.push(item); // weird high values, not an actual span
    } else if (item.value >= 16 && item.value <= 27) {
      short.push(item);
    } else if (item.value >= 32 && item.value <= 54) {
      reg.push(item);
    } else if (item.value >= 64 && item.value <= 108) {
      long.push(item);
    } else if (item.value >= 164 && item.value <= 208) {
      xLong.push(item);
    }
  });

  return formatTrousersSizes(trouserList, xShort, short, reg, long, xLong);
};

export const transformSpecialSizes = (
  sizes: ProductDetailsValue[] = [],
  category: any
): ProductSize[] => {
  const result: ProductSize[] = sizes
    .filter(({ text }) => {
      return (
        category.name !== SIZE_CATEGORIES['JUNIOR'] || !!parseInt(`${text}`)
      );
    })
    .map(({ text }) => {
      const length = text?.length || 0;
      return {
        toOrder: text?.[length - 1] === '*',
        value: text.replace(/\*^/, ''),
      };
    });

  return result;
};

const getTrousersList6Series = (sizeList: any[], trouserList: any[]) => {
  const xShort: any[] = [];
  const short: any[] = [];
  const reg: any[] = [];
  const long: any[] = [];
  const xLong: any[] = [];

  sizeList.map((item) => {
    if (
      (item.value >= 188 && item.value <= 232) ||
      (item.value >= 536 && item.value <= 552)
    ) {
      // Extra short
      xShort.push(item);
    } else if (
      (item.value >= 80 && item.value <= 132) ||
      (item.value >= 636 && item.value <= 652)
    ) {
      // Short
      short.push(item);
    } else if (
      (item.value >= 40 && item.value <= 70) ||
      (item.value >= 772 && item.value <= 776)
    ) {
      // Regular
      reg.push(item);
    } else if (
      (item.value >= 140 && item.value <= 170) ||
      (item.value >= 872 && item.value <= 876)
    ) {
      // Long
      long.push(item);
    } else if (item.value >= 240 && item.value <= 276) {
      // Extra long
      xLong.push(item);
    }
  });

  return formatTrousersSizes(trouserList, xShort, short, reg, long, xLong);
};

const getTrousersList3Series = (sizeList: any[], trouserList: any[]) => {
  const xShort: any[] = [];
  const short: any[] = [];
  const reg: any[] = [];
  const long: any[] = [];
  const xLong: any[] = [];

  sizeList.map((item) => {
    if (item.value >= 180 && item.value <= 232) {
      xShort.push(item);
    } else if (item.value >= 80 && item.value <= 132) {
      short.push(item);
    } else if (item.value >= 42 && item.value <= 68) {
      reg.push(item);
    } else if (item.value >= 142 && item.value <= 168) {
      long.push(item);
    } else if (item.value >= 242 && item.value <= 268) {
      xLong.push(item);
    }
  });

  return formatTrousersSizes(trouserList, xShort, short, reg, long, xLong);
};

// const isSizeToOrder = (size) => {
//   // Asterix on the size text means the product is not in stock
//   return size[size.length - 1] === '*' ? true : false;
// };

const formatAlphaNumericSizes = (
  list: { id: string; name: string }[],
  short: any,
  reg: any,
  long: any
) => {
  const allowedSizes = ['Short', 'Regular', 'Long'];

  return list
    .filter(({ name }) => allowedSizes.includes(name))
    .map((item) => {
      switch (item.name) {
        case 'Short':
          if (short.length > 0) {
            return createSizeItem(item.id, item.name, short, 'Short');
          }

          break;
        case 'Regular':
          if (reg.length > 0) {
            return createSizeItem(item.id, item.name, reg, 'Regular');
          }

          break;
        case 'Long':
          if (long.length > 0) {
            return createSizeItem(item.id, item.name, long, 'Long');
          }
          break;
        default:
          break;
      }
      return;
    })
    .filter(Boolean);
};

const formatTrousersSizes = (
  list: any[],
  xShort: any,
  short: any,
  reg: any,
  long: any,
  xLong: any
) => {
  const result: SizeItem[] = []; // dont add categories with no sizes

  // TODO: extra short and extra long not getting any sizes
  const sizeLabels = {
    long: cleanTextFromNonLettersAndNumbers('Long'),
    reg: cleanTextFromNonLettersAndNumbers('Regular'),
    short: cleanTextFromNonLettersAndNumbers('Short'),
    xLong: cleanTextFromNonLettersAndNumbers('Extra Long'),
    xShort: cleanTextFromNonLettersAndNumbers('Extra Short'),
  };

  list.map((item) => {
    switch (cleanTextFromNonLettersAndNumbers(item.name)) {
      case sizeLabels.xShort:
        if (xShort.length > 0) {
          result.push(createSizeItem(item.id, item.name, xShort));
        }
        break;
      case sizeLabels.short:
        if (short.length > 0) {
          result.push(createSizeItem(item.id, item.name, short));
        }
        break;
      case sizeLabels.reg:
        if (reg.length > 0) {
          result.push(createSizeItem(item.id, item.name, reg));
        }
        break;
      case sizeLabels.long:
        if (long.length > 0) {
          result.push(createSizeItem(item.id, item.name, long));
        }
        break;
      case sizeLabels.xLong:
        if (xLong.length > 0) {
          result.push(createSizeItem(item.id, item.name, xLong));
        }
        break;
      default:
        break;
    }
  });

  return result;
};

const createSizeItem = (
  id: string,
  name: string,
  sizes: any[],
  _extra?: string
): SizeItem => {
  return {
    id,
    name,
    sizes,
  };
};
