import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const ProductGrid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);

  /* padding-inline: 20px; */
  max-width: ${breakpoints.maxPageWidth};
  margin-inline: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
