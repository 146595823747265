import { ButtonStyled } from './Button.styled';
import NextSvg from './next.svg';
export interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  letterSpacing?: React.CSSProperties['letterSpacing'];
  uppercase?: boolean;
  iconNext?: boolean;
  small?: boolean;
  isLoading?: boolean;
  yellow?: boolean;
  grey?: boolean;
  ghost?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  letterSpacing = '2px',
  uppercase = true,
  children,
  small,
  isLoading,
  yellow,
  grey,
  ghost,
  iconNext,
  disabled,
  onClick,
  className,
}) => (
  <ButtonStyled
    $small={small}
    $uppercase={uppercase}
    $letterSpacing={letterSpacing}
    onClick={onClick}
    className={className}
    disabled={disabled}
    $isLoading={isLoading}
    $grey={grey}
    $yellow={yellow}
    $ghost={ghost}
  >
    {children}
    {iconNext && (
      <NextSvg width={7} height={12} aria-hidden={true} focusable={false} />
    )}
  </ButtonStyled>
);
