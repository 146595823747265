import { Graphic } from '../Graphic/Graphic';

import { LoaderStyled } from './Loader.styled';

export interface LoaderProps {
  small?: boolean;
  center?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ small, center }) => {
  return (
    <LoaderStyled $small={small} $center={center}>
      <Graphic image="/assets/gfx/yellow-line.svg" />
    </LoaderStyled>
  );
};
