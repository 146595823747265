import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/snickers/helpers';

interface SectionStyledProps {
  $fullBleed?: boolean;
  $noTop?: boolean;
  $zeroBottom?: boolean;
  $zeroTop?: boolean;
  $borderTop?: boolean;
  $borderTopDesktop?: boolean;
  $borderBottomDesktop?: boolean;
  $largePaddingTop?: boolean;
  $largePaddingBottomDesktop?: boolean;
  $largeMarginTop?: boolean;
  $noMarginTop?: boolean;
  $moreMarginBottom?: boolean;
  $transparent?: boolean;
  $paddingBottom?: boolean;
  $padding?: boolean;
  $hide?: boolean;
}

export const SectionStyled = styled.section<SectionStyledProps>`
  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  padding: 20px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${({ $noMarginTop }) => ($noMarginTop ? '0 1rem' : '1rem')};
    margin-block-start: ${({ $noMarginTop }) => ($noMarginTop ? '0' : '20px')};


     ${({ $largeMarginTop }) =>
    $largeMarginTop
    && css`
      margin-block-start: ${spacing.huge};
    `}
  }

  ${({ $fullBleed }) =>
    $fullBleed
    && css`
      padding: 0;
    `}

  ${({ $noTop }) =>
    $noTop
    && css`
      margin-block-start: 0;
    `}

  ${({ $zeroBottom }) =>
    $zeroBottom
    && css`
      padding-block-end: 0;
    `}

  ${({ $zeroTop }) =>
    $zeroTop
    && css`
      margin-block-start: 0;
      padding-block-start: 0;
    `}

  ${({ $borderTop }) =>
    $borderTop
    && css`
      border-top: 1px solid ${colors.gray4};
    `}

    ${({ $borderTopDesktop: borderTopDesktop }) =>
    borderTopDesktop
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        border-block-start: 1px solid ${colors.gray4};
      }
    `}

    ${({ $borderBottomDesktop }) =>
    $borderBottomDesktop
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        border-block-end: 1px solid ${colors.gray4};
      }
    `}

    ${({ $largePaddingTop }) =>
    $largePaddingTop
    && css`
      padding-block-start: 50px;

      @media screen and (min-width: ${breakpoints.desktop}) {
        padding-block-start: 100px;
      }
    `}

  ${({ $largePaddingBottomDesktop }) =>
    $largePaddingBottomDesktop
    && css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        padding-block-end: 96px;
      }
    `}

  ${({ $noMarginTop }) =>
    $noMarginTop
    && css`
      margin-block-start: 0;
      padding-block-start: 0;

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-block-start: 0;
      }
    `}


  ${({ $moreMarginBottom }) =>
    $moreMarginBottom
    && css`
      margin-block-end: 280px;
      padding-block-end: 0;
    `}



  ${({ $transparent }) =>
    $transparent
    && css`
      margin-block-start: -57px;
    `}

  ${({ $paddingBottom }) =>
    $paddingBottom
    && css`
      padding-block-end: 96px;
    `}

  ${({ $padding }) =>
    $padding
    && css`
      padding: 25px 0;
    `}

     ${({ $largeMarginTop }) =>
    $largeMarginTop
    && css`
      margin-block-start: ${spacing.huge};
    `}



  hr {
    color: ${colors.gray4};
  }
`;
