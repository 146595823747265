import styled from 'styled-components';

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const SeoSectionTitle = styled.p`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.contentDesktop};
  letter-spacing: 0.02rem;
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.medium};
  margin-block: 0 ${spacing.tinier};
`;

export const SeoSectionWrapper = styled.div`
  &.MarginTop {
    margin-block-start: ${spacing.small};
  }
`;
