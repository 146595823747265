const LANDING_VIEW = '/';
const FIND_RETAILERS = 'find-retailers';
const SEARCH = 'search';
const CONTACT = 'contact';
const CAMPAIGN = 'campaigns';
const CATEGORY = 'products';
const LIST_PAGE = 'list'; // TODO change this when they have decided on a new name

export const routes = {
  CAMPAIGN,
  CATEGORY,
  CONTACT,
  FIND_RETAILERS,
  LANDING_VIEW,
  LIST_PAGE,
  SEARCH,
};
