import dynamic from 'next/dynamic';

import { createProductsMenuUrl, createUrl } from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import {
  GlobalFields,
  MenuChildFragment,
  MenuGrandChildFragment,
  MenuItemFragment,
  SecondaryMenuItemRecord,
} from '@hultafors/snickers/types';

import { RouterLink } from '../RouterLink/RouterLink';
import { SearchBox } from '../SearchBox/SearchBox';
import { SlideIn, SlideInToggler } from '../SlideIn/SlideIn';

import {
  MenuLink,
  MenuLinkLabel,
  MenuLinksWrapper,
  MenuStyled,
  MenuWrapper,
  SearchWrapper,
} from './Menu.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion)
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem)
);

export const MOBILE_MENU_DRAWER_ID = 'MobileMenuDrawer';

export interface MenuProps {
  isOpen: boolean;
  toggleMenu: SlideInToggler;
  headerText: string;
  menuData?: GlobalFields['allMenuItems'];
  menuExtras?: GlobalFields['menuExtra'];
}

export const Menu: React.FC<MenuProps> = ({
  menuData,
  menuExtras,
  toggleMenu,
  isOpen,
  headerText,
}) => {
  const { global } = useGlobal();

  const secondaryMapper = (menuItem: Partial<SecondaryMenuItemRecord>) => (
    <MenuLink
      key={`mobileMenuItem-${menuItem.id}`}
      href={createUrl(menuItem.url || '')}
    >
      {menuItem.title}
    </MenuLink>
  );

  const menuItemMapper = (item: MenuItemFragment) => {
    if (!item) {
      return null;
    }

    if (!item.children?.length) {
      return (
        <MenuLink
          key={`mobileMenuItem-${item.id}`}
          href={createUrl(item.newUrl || '')}
        >
          {item.title || ''}
        </MenuLink>
      );
    }

    const subItemMapper = (input: any) => {
      const subItem = input as MenuChildFragment;
      if (subItem?.children?.length && !subItem.hide) {
        let label: React.ReactNode = '';
        if (subItem.title) {
          label = <MenuLinkLabel>{subItem.title}</MenuLinkLabel>;
        }

        if (!subItem?.pageLink) {
          return null;
        }

        const subSubItemMapper = (input: any) => {
          const subSubItem = input as MenuGrandChildFragment;
          if (!subSubItem || subSubItem?.hide) {
            return null;
          }
          return (
            <MenuLink
              key={`mobileMenuSubSubItem-${subSubItem.id}`}
              aria-level={3}
              $level={2}
              href={createProductsMenuUrl({
                currentNode: subSubItem,
                parentNode: subItem,
              })}
            >
              {subSubItem.title}
            </MenuLink>
          );
        };
        return (
          <AccordionItem
            level={2}
            label={label}
            key={`mobileMenuSubItem-${subItem.id}`}
            menu
          >
            <MenuLinksWrapper>
              {subItem.children.map(subSubItemMapper)}
            </MenuLinksWrapper>
          </AccordionItem>
        );
      }
      if (subItem && !subItem.hide) {
        return (
          <div aria-level={2} key={`mobileMenuSubItem-${subItem.id}`}>
            <RouterLink
              href={createProductsMenuUrl({
                currentNode: subItem,
              })}
            >
              {subItem.title || subItem.url}
            </RouterLink>
          </div>
        );
      }
      return null;
    };
    return (
      <AccordionItem
        level={1}
        label={item.title ? <MenuLinkLabel>{item.title}</MenuLinkLabel> : ''}
        key={`mobileMenuItem-${item.id}`}
        menu
      >
        <Accordion allowZeroExpanded>
          {menuExtras?.productMenu?.map(secondaryMapper)}
          {item.children.map(subItemMapper)}
        </Accordion>
      </AccordionItem>
    );
  };
  return (
    <MenuStyled>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={headerText}
        headerIcon="/assets/gfx/close.svg"
        isMenu
        padContent
        partnerPortalUrl={global?.partnerPortalUrl || ''}
        id={MOBILE_MENU_DRAWER_ID}
      >
        <SearchWrapper>
          <SearchBox
            tall
            isOpen={isOpen}
            toggleMenu={toggleMenu}
            isAutoComplete={true}
            inMobileMenu={true}
          />
        </SearchWrapper>
        <MenuWrapper>
          <Accordion allowZeroExpanded>
            {menuData?.filter(Boolean)?.map(menuItemMapper)}
          </Accordion>
        </MenuWrapper>
      </SlideIn>
    </MenuStyled>
  );
};
