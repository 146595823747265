import { StyledFieldWrapper } from './FieldWrapper.styled';

export interface FieldWrapperProps {
  className?: string;
  children?: React.ReactNode;
  inline?: boolean;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  className,
  children,
  inline,
}) => {
  return (
    <StyledFieldWrapper className={className} $inline={inline}>
      {children}
    </StyledFieldWrapper>
  );
};
