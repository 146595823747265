import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { QuoteBlockStyled } from './QuoteBlock.styled';

export interface QuoteBlockProps {
  quote: string;
  fullWIdthRight?: boolean;
}

export const QuoteBlock: React.FC<QuoteBlockProps> = ({
  fullWIdthRight,
  quote,
}) => {
  return (
    <QuoteBlockStyled $fullWidthRight={fullWIdthRight}>
      <div className="QuoteWrapper">
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 10, start: 2 },
            ]}
          >
            <div className="QuoteInner">
              <span className="Quote">{quote}</span>
            </div>
          </GridChild>
        </Grid>
      </div>
    </QuoteBlockStyled>
  );
};
