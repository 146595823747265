import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { LinkButton } from '../LinkButton/LinkButton';
import { Markdown } from '../Markdown/Markdown';

import { Heading, TextBlockOldStyled } from './TextBlockOld.styled';

export interface TextBlockOldProps {
  title?: string;
  text?: string;
  CTAText?: string;
  CTALink?: string;
}

export const TextBlockOld: React.FC<TextBlockOldProps> = ({
  title = '',
  text = '',
  CTAText = '',
  CTALink = '',
}) => {
  return (
    <TextBlockOldStyled>
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'mobileMax', columnGap: 20 },
        ]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
            { breakpoint: 'desktopMedium', columns: 6, start: 4 },
          ]}
        >
          {title && <Heading>{title}</Heading>}
          {text && <Markdown options={{ forceBlock: true }}>{text}</Markdown>}
          {!!CTAText && !!CTALink && (
            <LinkButton
              large={true}
              text={CTAText}
              url={CTALink}
              className="Grey CTAButton"
            />
          )}
        </GridChild>
      </Grid>
    </TextBlockOldStyled>
  );
};
