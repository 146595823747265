import { LinkBlockFragment } from '@hultafors/snickers/types';

import FileSvg from '../../svg/file.svg';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import * as Styled from './DocumentLinkBlock.styled';

export type DocumentLinkBlockProps = LinkBlockFragment;

export const DocumentLinkBlock: React.FC<DocumentLinkBlockProps> = ({
  linkBlock: items = [],
}) => {
  return (
    <Grid
      columnGap={[
        { columnGap: 15 },
        { breakpoint: 'mobileMax', columnGap: 20 },
      ]}
      columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
    >
      <GridChild
        columnSpan={[
          { columns: 5 },
          { breakpoint: 'mobileMax', columns: 10, start: 2 },
          { breakpoint: 'desktop', columns: 8, start: 3 },
          { breakpoint: 'desktopMedium', columns: 6, start: 4 },
        ]}
      >
        <Styled.DocumentLinkBlock>
          {items?.map((item, index) => {
            return (
              <a
                key={`DocumentLink-${index}`}
                href={item?.url}
                target="_blank"
                rel="noreferrer"
              >
                <FileSvg
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
                {item?.title || 'File'}
              </a>
            );
          })}
        </Styled.DocumentLinkBlock>
      </GridChild>
    </Grid>
  );
};
