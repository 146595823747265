import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './Markdown.styled';

export interface MarkdownProps {
  children?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({ children, options }) => {
  if (!children) {
    return null;
  }
  return <StyledMarkdown options={options}>{children}</StyledMarkdown>;
};
