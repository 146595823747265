import styled from 'styled-components';

import { fontSizes, fontWeights, spacing } from '@hultafors/snickers/helpers';

export const LinkBlockStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.medium} 0 ${spacing.medium} 0;
  padding-inline-end: 2.3rem;

  a {
    text-transform: uppercase;
    font-size: ${fontSizes.m};
    font-weight: ${fontWeights.bold};
    letter-spacing: 0.1em;
  }
`;
