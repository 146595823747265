import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
} from '@hultafors/snickers/helpers';

export const SearchResultHeroStyled = styled.div`
  padding-block: 20px 50px;
  padding-inline: 20px;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > * {
    max-inline-size: 500px;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 70px;
  }
`;

export const ResultText = styled.p`
  font-family: ${fontFamilies.faktPro};
  display: block;
  position: absolute;
  margin: 0;
  font-size: ${fontSizes.body};
  line-height: ${fontSizes.body};
  font-weight: ${fontWeights.medium};
  text-align: center;
  top: calc(80px);

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inset-block-start: 140px;
  }
`;
