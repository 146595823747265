import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: ${breakpoints.maxPageWidth};
  max-width: calc(100vw - 40px);
  margin-inline: auto;
  gap: 20px;
  padding-block: 48px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);

    /* gap: 256px; */
    gap: 12.5vw;
    padding-block: 96px;
  }
`;
