import { ResultText, SearchResultHeroStyled } from './SearchResultHero.styled';

interface SearchResultHeroProps {
  children?: React.ReactNode;
  message?: string;
}

export const SearchResultHero: React.FC<SearchResultHeroProps> = ({
  children,
  message,
}) => {
  return (
    <SearchResultHeroStyled>
      {children}
      {message && <ResultText>{message}</ResultText>}
    </SearchResultHeroStyled>
  );
};
