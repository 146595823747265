import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/snickers/helpers';

interface VideoStyledProps {
  $fullWidth?: boolean;
}

export const VideoStyled = styled.div``;

export const VideoControls = styled.div``;

export const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  background-image: url('/svg/video-play.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: 94px;
    block-size: 94px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  margin: 0;
  text-align: center;
  margin-block: ${spacing.small};
`;

export const VideoPlayer = styled.video<VideoStyledProps>`
  max-width: 100%;
  width: 100%;
  max-height: calc(100vh - var(--header-height));
  object-fit: contain;

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-inline-size: ${({ $fullWidth }) => ($fullWidth ? '100vw' : '100%')};
  }
`;
