import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/snickers/types';

import { Paragraph } from '../Paragraph/Paragraph';

import {
  Content,
  DesktopImage,
  GenericLargePlugStyled,
  MobileImage,
  NameText,
  PlugIngress,
  Title,
} from './GenericLargePlug.styled';
import LinkSvg from './link.svg';

export interface GenericLargePlugProps {
  title?: string | null;
  plugIngress?: string | null;
  image?: ImageFragment | null;
  desktopImage?: ImageFragment | null;
  label?: string | null;
  name?: string | null;
  url?: string | null;
  className?: string | null;
  sizes?: string | null;
  sizesDesktop?: string | null;
}

export const GenericLargePlug: React.FC<GenericLargePlugProps> = ({
  url,
  image,
  desktopImage,
  name,
  plugIngress,
  title,
  label,
  sizes: inputSizes,
  sizesDesktop: inputSizesDesktop,
}) => {
  let imageComponent = <div className="backgroundColors" />;
  const sizes = inputSizes ?? '100vw';
  const sizesDesktop
    = inputSizesDesktop
    ?? [
      `(max-width: ${breakpoints.maxPageWidth}) 100vw`,
      `${breakpoints.maxPageWidth}`,
    ].join(', ');
  if (image?.responsiveImage?.src && desktopImage?.responsiveImage?.src) {
    imageComponent = (
      <>
        <MobileImage>
          <Image
            src={image.responsiveImage.src}
            alt={image.alt || ''}
            fill
            sizes={sizes}
            blurDataURL={image.responsiveImage?.base64 || ''}
            placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
          />
        </MobileImage>
        <DesktopImage>
          <Image
            src={desktopImage.responsiveImage.src}
            alt={desktopImage.alt || ''}
            fill
            sizes={sizesDesktop}
            blurDataURL={desktopImage.responsiveImage?.base64 || ''}
            placeholder={
              desktopImage.responsiveImage?.base64 ? 'blur' : 'empty'
            }
          />
        </DesktopImage>
      </>
    );
  }
  if (image?.responsiveImage?.src && !desktopImage?.responsiveImage?.src) {
    imageComponent = (
      <Image
        src={image.responsiveImage.src}
        alt={image.alt || ''}
        fill
        sizes={sizesDesktop}
        blurDataURL={image.responsiveImage?.base64 || ''}
        placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
      />
    );
  }
  return (
    <GenericLargePlugStyled $image={!!image} href={url ?? ''}>
      {imageComponent}
      <Content $image={!!image}>
        {label && <Paragraph>{label}</Paragraph>}
        {name && <NameText $image={!!image}>{name}</NameText>}
        <Title $image={!!image}>{title}</Title>
        {plugIngress && <PlugIngress>{plugIngress}</PlugIngress>}
        {url && (
          <LinkSvg
            width={39}
            height={18}
            aria-hidden={true}
            focusable={false}
          />
        )}
      </Content>
    </GenericLargePlugStyled>
  );
};
