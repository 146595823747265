import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontFamilies, spacing } from '@hultafors/snickers/helpers';

export const StyledBreadcrumbs = styled.div<{ $slim?: boolean }>`
  display: flex;
  flex-wrap: ${({ $slim }) => ($slim ? 'wrap' : 'nowrap')};
  gap: 0.5rem;
  padding-block: ${spacing.xSmall};
  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  margin-block-end: ${({ $slim }) => ($slim ? 0 : `-${spacing.medium}`)};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: ${({ $slim }) => ($slim ? spacing.small : spacing.medium)};
  }
`;

export const StyledLink = styled(Link)<{ $slim?: boolean }>`
  display: block;
  font-family: ${fontFamilies.mikro};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 10%;
  text-transform: uppercase;
  overflow: hidden;
  white-space: ${({ $slim }) => ($slim ? 'wrap' : 'nowrap')};
  text-overflow: ellipsis;

  /* Snickers Black */
  color: #232120;

  & + & {
    &::before {
      content: '>';
      display: inline-block;
      margin-inline-end: 0.5rem;
    }
  }

  &:last-child {
    color: ${colors.gray2};
    overflow: visible;
  }
`;
