import { SizeItem, WaistList } from '@hultafors/snickers/types';

export const trousersMenWaists: WaistList = {
  body: [
    { cm: { max: 73, min: 70 }, inch: { max: 29, min: 28 } },
    { cm: { max: 77, min: 74 }, inch: { max: 30, min: 30 } },
    { cm: { max: 81, min: 78 }, inch: { max: 32, min: 31 } },
    { cm: { max: 85, min: 82 }, inch: { max: 33, min: 33 } },
    { cm: { max: 89, min: 86 }, inch: { max: 35, min: 34 } },
    { cm: { max: 93, min: 90 }, inch: { max: 37, min: 36 } },
    { cm: { max: 97, min: 94 }, inch: { max: 38, min: 38 } },
    { cm: { max: 101, min: 98 }, inch: { max: 40, min: 39 } },
    { cm: { max: 105, min: 102 }, inch: { max: 41, min: 41 } },
    { cm: { max: 109, min: 106 }, inch: { max: 43, min: 42 } },
    { cm: { max: 117, min: 110 }, inch: { max: 46, min: 44 } },
    { cm: { max: 125, min: 118 }, inch: { max: 49, min: 47 } },
    { cm: { max: 133, min: 126 }, inch: { max: 52, min: 50 } },
    { cm: { max: 141, min: 134 }, inch: { max: 56, min: 53 } },
    { cm: { max: 149, min: 142 }, inch: { max: 59, min: 57 } },
    { cm: { max: 157, min: 150 }, inch: { max: 62, min: 60 } },
    { cm: { max: 165, min: 158 }, inch: { max: 65, min: 63 } },
    { cm: { max: 173, min: 166 }, inch: { max: 68, min: 66 } },
    { cm: { max: 181, min: 174 }, inch: { max: 71, min: 69 } },
  ],
  garment: [
    { cm: { max: 70, min: 67 }, inch: { max: 27, min: 27 } },
    { cm: { max: 74, min: 71 }, inch: { max: 29, min: 28 } },
    { cm: { max: 78, min: 75 }, inch: { max: 30, min: 30 } },
    { cm: { max: 82, min: 79 }, inch: { max: 32, min: 31 } },
    { cm: { max: 86, min: 83 }, inch: { max: 34, min: 33 } },
    { cm: { max: 90, min: 87 }, inch: { max: 35, min: 35 } },
    { cm: { max: 94, min: 91 }, inch: { max: 37, min: 36 } },
    { cm: { max: 98, min: 95 }, inch: { max: 38, min: 38 } },
    { cm: { max: 102, min: 99 }, inch: { max: 40, min: 39 } },
    { cm: { max: 106, min: 103 }, inch: { max: 42, min: 41 } },
    { cm: { max: 114, min: 107 }, inch: { max: 45, min: 43 } },
    { cm: { max: 122, min: 115 }, inch: { max: 48, min: 46 } },
    { cm: { max: 130, min: 123 }, inch: { max: 51, min: 49 } },
    { cm: { max: 138, min: 131 }, inch: { max: 54, min: 52 } },
    { cm: { max: 146, min: 139 }, inch: { max: 57, min: 55 } },
    { cm: { max: 154, min: 147 }, inch: { max: 60, min: 58 } },
    { cm: { max: 162, min: 155 }, inch: { max: 64, min: 61 } },
    { cm: { max: 170, min: 163 }, inch: { max: 67, min: 65 } },
    { cm: { max: 178, min: 171 }, inch: { max: 70, min: 68 } },
  ],
};

export const trousersMen: SizeItem[] = [
  {
    id: 'trousers_m_1',
    leg: {
      body: {
        cm: { max: 73, min: 68 },
        inch: { max: 28, min: 27 },
      },
      garment: {
        cm: { max: 73, min: 68 },
        inch: { max: 28, min: 27 },
      },
    },
    name: 'Extra Short',

    sizes: [
      {
        size: 180,
      },
      {
        size: 184,
      },
      {
        size: 188,
      },
      {
        size: 192,
      },
      {
        size: 196,
      },
      {
        size: 200,
      },
      {
        size: 204,
      },
      {
        size: 208,
      },
      {
        size: 212,
      },
      {
        size: 216,
      },
      {
        size: 220,
      },
      {
        size: 224,
      },
      {
        size: 228,
      },
      {
        size: 232,
      },
      {
        size: 536,
      },
      {
        size: 540,
      },
      {
        size: 544,
      },
      {
        size: 548,
      },
      {
        size: 552,
      },
    ],
  },
  {
    id: 'trousers_m_2',
    leg: {
      body: {
        cm: { max: 79, min: 74 },
        inch: { max: 31, min: 29 },
      },
      garment: {
        cm: { max: 79, min: 74 },
        inch: { max: 31, min: 29 },
      },
    },
    name: 'Short',
    sizes: [
      {
        size: 80,
      },
      {
        size: 84,
      },
      {
        size: 88,
      },
      {
        size: 92,
      },
      {
        size: 96,
      },
      {
        size: 100,
      },
      {
        size: 104,
      },
      {
        size: 108,
      },
      {
        size: 112,
      },
      {
        size: 116,
      },
      {
        size: 120,
      },
      {
        size: 124,
      },
      {
        size: 128,
      },
      {
        size: 132,
      },
      {
        size: 636,
      },
      {
        size: 640,
      },
      {
        size: 644,
      },
      {
        size: 648,
      },
      {
        size: 652,
      },
    ],
  },
  {
    id: 'trousers_m_3',
    leg: {
      body: {
        cm: { max: 85, min: 80 },
        inch: { max: 33, min: 32 },
      },
      garment: {
        cm: { max: 85, min: 80 },
        inch: { max: 33, min: 32 },
      },
    },
    name: 'Regular',
    sizes: [
      {
        size: 40,
      },
      {
        size: 42,
      },
      {
        size: 44,
      },
      {
        size: 46,
      },
      {
        size: 48,
      },
      {
        size: 50,
      },
      {
        size: 52,
      },
      {
        size: 54,
      },
      {
        size: 56,
      },
      {
        size: 58,
      },
      {
        size: 60,
      },
      {
        size: 62,
      },
      {
        size: 64,
      },
      {
        size: 66,
      },
      {
        size: 68,
      },
      {
        size: 70,
      },
      {
        size: 772,
      },
      {
        size: 774,
      },
      {
        size: 776,
      },
    ],
  },
  {
    id: 'trousers_m_4',
    leg: {
      body: { cm: { max: 91, min: 86 }, inch: { max: 35, min: 34 } },
      garment: { cm: { max: 91, min: 86 }, inch: { max: 35, min: 34 } },
    },
    name: 'Long',
    sizes: [
      {
        size: 140,
      },
      {
        size: 142,
      },
      {
        size: 144,
      },
      {
        size: 146,
      },
      {
        size: 148,
      },
      {
        size: 150,
      },
      {
        size: 152,
      },
      {
        size: 154,
      },
      {
        size: 156,
      },
      {
        size: 158,
      },
      {
        size: 160,
      },
      {
        size: 162,
      },
      {
        size: 164,
      },
      {
        size: 166,
      },
      {
        size: 168,
      },
      {
        size: 170,
      },
      {
        size: 872,
      },
      {
        size: 874,
      },
      {
        size: 876,
      },
    ],
  },
  {
    id: 'trousers_m_5',
    leg: {
      body: {
        cm: { max: 97, min: 92 },
        inch: { max: 38, min: 36 },
      },
      garment: { cm: { max: 97, min: 92 }, inch: { max: 38, min: 36 } },
    },
    name: 'Extra Long',
    sizes: [
      {
        size: 240,
      },
      {
        size: 242,
      },
      {
        size: 244,
      },
      {
        size: 246,
      },
      {
        size: 248,
      },
      {
        size: 250,
      },
      {
        size: 252,
      },
      {
        size: 254,
      },
      {
        size: 256,
      },
      {
        size: 258,
      },
      {
        size: 260,
      },
      {
        size: 262,
      },
      {
        size: 264,
      },
      {
        size: 266,
      },
      {
        size: 268,
      },
      {
        size: 270,
      },
      {
        size: 272,
      },
      {
        size: 274,
      },
      {
        size: 276,
      },
    ],
  },
];
