import { H3 } from '../H3/H3';
import { H4 } from '../H4/H4';
import { Paragraph } from '../Paragraph/Paragraph';

import { BackArrow, GuideHeroStyled } from './GuideHero.styled';

export interface GuideHeroProps {
  title?: string;
  introText?: string;
  backText?: string;
  currentStep: number;
  backToStep?: number;
  numberOfSteps?: number;
  changeStep(...args: any[]): unknown;
  guideType: string;
  isBig?: boolean;
  isResult?: boolean;
}

export const GuideHero: React.FC<GuideHeroProps> = ({
  isBig,
  isResult,
  backText = 'Back',
  title = '',
  introText = '',
  numberOfSteps = 0,
  backToStep = 0, // Overrides back button step order
  currentStep,
  ...props
}) => {
  const changeStepHelper = () => {
    if (backToStep && backToStep >= 0) {
      props.changeStep(backToStep, currentStep);
    } else {
      props.changeStep(currentStep - 1);
    }
  };

  return (
    <GuideHeroStyled $isBig={isBig} $isResult={isResult}>
      {currentStep > 0 && (
        <button className="BackButton" onClick={changeStepHelper}>
          <BackArrow />
          {backText}
        </button>
      )}

      {isBig
        ? (
          <H3>{title}</H3>
          )
        : (
          <H4 className={isResult ? 'title LargeH4' : 'title'}>{title}</H4>
          )}
      {introText && <Paragraph className="IntroText">{introText}</Paragraph>}
    </GuideHeroStyled>
  );
};
