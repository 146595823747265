import {
  StyledInput,
  StyledSliderSpan,
  StyledToggleSwitch,
  StyledToggleSwitchContainer,
  StyledToggleSwitchLabel,
} from './ToggleSwitch.styled';

export interface ToggleSwitchProps {
  checked?: boolean;
  onChange?(...args: any[]): unknown;
  label?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <StyledToggleSwitchContainer>
      <StyledToggleSwitch>
        <StyledInput type="checkbox" checked={checked} onChange={onChange} />
        <StyledSliderSpan className="slider" />
      </StyledToggleSwitch>
      {label && <StyledToggleSwitchLabel>{label}</StyledToggleSwitchLabel>}
    </StyledToggleSwitchContainer>
  );
};
