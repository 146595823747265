import slugify from 'slugify';

import { SnickersProduct } from '@hultafors/snickers/types';

import { useGlobal } from '../use-global/use-global';

export const useFindCategory = () => {
  const { allMenuItems } = useGlobal();
  const products = allMenuItems?.[0];
  const findCategory = (subCategory: string): string | undefined => {
    return (
      products?.children?.find((item) => {
        return !!item?.children?.find((subItem) => {
          return subItem?.pageLink?.slug === subCategory;
        });
      })?.pageLink?.slug || undefined
    );
  };
  const findSubCategory = (product: SnickersProduct): string => {
    return slugify(
      (
        product.attributes?.find(
          ({ label }) => label === 'Hellberg_secondary_product_tree_node',
        )?.value as string
      )?.toLocaleLowerCase() || '',
    ).replace('-and-', '-');
  };

  return { findCategory, findSubCategory };
};
