import styled from 'styled-components';

export const DotDigialWrapperStyled = styled.div`
  width: 100%;
  max-width: 1400px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
