import { Container } from './WorkwearGuideContainer.styled';

interface WorkwearGuideContainerProps {
  children?: React.ReactNode;
}

export const WorkwearGuideContainer: React.FC<WorkwearGuideContainerProps> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};
