import { useMemo } from 'react';

import { createBreadcrumbs } from '@hultafors/snickers/helpers';

import { useGlobal } from '../use-global/use-global';

export const useProductBreadcrumbs = (path: string, current?: string) => {
  const { allMenuItems } = useGlobal();
  return useMemo(() => {
    const parts = path.replace(/^\/?(.+?)(\?.*)?$/, '$1').split('/');
    const base = allMenuItems[0];
    let url = '';
    const links: { title: string; url: string }[] = parts.map(
      (part, index, all) => {
        url += `/${part}`;
        let title = part;
        const prev = all[index - 1];
        const parent = base?.children?.find(
          (item) => item?.pageLink?.slug === part
        );
        const child = base?.children
          ?.find((item) => item?.pageLink?.slug === prev)
          ?.children?.find((subItem) => subItem?.pageLink?.slug === part);
        if (index === 0) {
          title = base?.title || part.replace(/-/gi, ' ');
        }
        if (index === 1) {
          title = parent?.title || part.replace(/-/gi, ' ');
        }
        if (index === 2) {
          title = child?.title || part.replace(/-/gi, ' ');
        }
        if (index === 3) {
          title = current || part;
        }
        return {
          title,
          url,
        };
      }
    );

    return createBreadcrumbs(links);
  }, [allMenuItems, path, current]);
};
