import NextBoldSvg from '../../svg/next-bold.svg';

import { RouterLinkStyled } from './RouterLink.styled';

export interface RouterLinkProps {
  children?: React.ReactNode;
  href?: string;
  name?: string;
  id?: string;
  onClick?: React.MouseEventHandler;
  dataValue?: string;
  external?: boolean;
  className?: string;
  linkType?: 'special';
  iconNext?: boolean;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  href = '',
  children,
  id,
  onClick,
  className,
  linkType,
  iconNext,
}) => {
  return (
    <RouterLinkStyled
      id={id}
      className={className}
      href={href}
      onClick={onClick}
      data-value={dataValue}
      $type={linkType}
    >
      {children}
      {iconNext && (
        <NextBoldSvg
          aria-hidden={true}
          focusable={false}
          width={16}
          height={16}
        />
      )}
    </RouterLinkStyled>
  );
};
