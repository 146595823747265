export const sizing = {
  hairline: '1px',
  huge: '5rem',
  large: '4rem',
  medium: '2.5rem',
  regular: '2px',
  small: '2rem',
  thick: '3px',
  tiny: '1.5rem',
};
