import ChevronSvg from './chevron.svg';
import { ReadMoreLinkStyled } from './ReadMoreLink.styled';

interface ReadMoreLinkProps {
  href: string;
  children?: React.ReactNode;
}

export const ReadMoreLink: React.FC<ReadMoreLinkProps> = ({
  href,
  children,
}) => {
  return (
    <ReadMoreLinkStyled href={href}>
      {children}
      <ChevronSvg />
    </ReadMoreLinkStyled>
  );
};
