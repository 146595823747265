import styled from 'styled-components';

export const ProductColorsPreviewStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block-start: 8px;

  > * {
    display: block;
    block-size: 12px;
    inline-size: 12px;
    margin-inline-end: 8px;
    line-height: 0;
  }
`;

export const RemainingColors = styled.span`
  display: block;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
`;
