export const workwearGuideDefault = {
  ceCertificationTitle: '',
  ceCertificationTitleProtectWork: 'What CE Certification are you looking for',
  ceNormsTitle: '',
  chooseCategoryTitle: 'Choose category',
  coldWetEnvironmentTitle: 'What kind of environment are you working in xx?',
  environmentTitle: '',
  environmentTitleGloves: 'What kind of environment will you use the glove',
  featuresTitle: '',
  featuresTitleGloves: 'Are you in need of any specific feature',
  fitTitle: '',

  garmentTitle: '',

  genderTitle: '',

  genderTitleProtectWork: 'Are you looking for female or male clothing',

  headerIntro: '',

  headerText: '',

  lessPerfectMatch: 'Good',

  occupationTitle: '',

  occupationTitleGloves: 'What is your occupation',
  // TODO translation in DU
  occupationTitleJackets: 'What task will you perform in your jacket',

  perfectMatch: 'Perfect',
  personalFilter: 'Your personal filter',
  precisionTitle: 'What type of work will you use these gloves for',
  productNode: null,
  riskEnvironmentTitle: '',
  semiPerfectMatch: 'Excellent',
  weRecommend: 'We recommend',
};
