import styled, { css } from 'styled-components';

export const CenterStyled = styled.div<{ $fill?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  ${({ $fill }) =>
    $fill
    && css`
      width: 100%;
      height: 100%;
    `}
`;
