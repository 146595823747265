import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

interface ProductStyledProps {
  $isSmall?: boolean;
  $inFavorites?: boolean;
}

export const ProductStyled = styled.article<ProductStyledProps>`
  position: relative;
  margin-block-end: ${spacing.small};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    margin-block-end: ${spacing.medium};
  }

  .Badge {
    position: absolute;
    inset-block-start: 8px;
    inset-inline-start: 8px;
  }
`;
export const ProductColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    padding-inline-start: 0;

    a {
      margin-inline-end: 8px;
      margin-block-end: 0;
    }
  }
`;

export const ProductImage = styled.div<ProductStyledProps>`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.gray5};
  max-height: ${({ $isSmall }) => ($isSmall ? '200px' : 'none')};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    block-size: 280px;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    block-size: 400px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    block-size: 300px;
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    block-size: 400px;
  }
`;

export const ImageLink = styled(RouterLink)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageWrapper = styled.span`
  display: block;
  width: 85%;
  height: 85%;
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  position: relative;

  img {
    mix-blend-mode: multiply;
  }
`;

export const ProductLink = styled(Link)`
  display: block;
  font-size: ${fontSizes.body};
  line-height: 1.285rem;
  font-weight: ${fontWeights.medium};
  margin-block-end: 0.25rem;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.m};
    line-height: 1.175rem;
  }

  &::after {
    position: absolute;
    inset: 0;
    content: '';
  }
`;

export const ProductInfo = styled.div`
  padding-block-start: 15px;
`;

export const ProductPrice = styled.div`
  display: block;
  font-size: ${fontSizes.body};
  line-height: 1.285rem;
  font-weight: ${fontWeights.medium};
  margin-block-end: 0.25rem;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.m};
    line-height: 1.175rem;
  }
`;

export const ProductPriceExplanation = styled.span`
  font-size: 10px;
  color: ${colors.gray2};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

export const FavoriteContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding-inline-start: 8px;
  padding-block-end: 8px;
`;
