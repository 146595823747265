import { ProductInfoMobileStyled } from './ProductInfoMobile.styled';

export interface ProductInfoMobileProps {
  children?: React.ReactNode;
}

export const ProductInfoMobile: React.FC<ProductInfoMobileProps> = ({
  children,
}) => {
  return <ProductInfoMobileStyled>{children}</ProductInfoMobileStyled>;
};
