import { GraphicStyled } from './Graphic.styled';

export interface GraphicProps {
  image: string;
}

export const Graphic: React.FC<GraphicProps> = ({ image }) => {
  return (
    <GraphicStyled>
      <img src={image} alt="" />
    </GraphicStyled>
  );
};
