import { GaugeStyled } from './Gauge.styled';

export interface GaugeProps {
  soFar: string | number;
  toGo: string | number;
}

export const Gauge: React.FC<GaugeProps> = ({ soFar = 0, toGo = 100 }) => (
  <GaugeStyled className="Gauge">
    <div className="SoFar" style={{ width: `${soFar}%` }} />
    <div className="ToGo" style={{ width: `${toGo}%` }} />
  </GaugeStyled>
);
