import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';
import { SearchInput } from '../SearchInput/SearchInput';

interface WideProps {
  $wide?: boolean;
}

interface TallProps {
  $tall?: boolean;
}

interface TransparentProps {
  $transparent?: boolean;
}

interface ShowProps {
  $show?: boolean;
}

type SearchBoxStyledProps = WideProps & TallProps & TransparentProps;

export const SearchBoxStyled = styled.div<SearchBoxStyledProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

type ResultProps = WideProps & ShowProps & { $inMobileMenu?: boolean };

export const Result = styled.div<ResultProps>`
  position: absolute;
  top: ${({ $inMobileMenu }) => ($inMobileMenu ? '50px' : '60px')};
  background-color: ${colors.white};
  padding: 0 15px;
  z-index: 4;
  border: 1px solid ${colors.gray4};
  border-radius: 2px;
  box-shadow: 0 5px 3px 0 ${colors.boxshadowTransparent};
  text-align: start;
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  width: 100%;
  transition: height 100ms ease-out;
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: ${({ $wide }) => ($wide ? '650px' : 'auto')};
    padding-block-end: 60px;
    max-block-size: calc(100vh - 200px);
  }
`;

export const ResultHeading = styled.p`
  font-weight: ${fontWeights.medium};
  margin: 15px 0 5px;
  font-size: 14px;
  color: ${colors.workwearBlack};
  text-align: center;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: 25px 0 15px;
  }
`;

export const ResultLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: calc(100vh - 220px);

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-block-size: calc(100vh - 400px);
  }
`;

export const ResultEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
`;

export const ResultLink = styled(RouterLink)`
  display: block;
  margin-block: 20px;
  text-align: center;
`;

export const SearchInputStyled = styled(SearchInput)`
  max-width: 500px;
`;
