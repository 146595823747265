import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const StyledDoubleColorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${spacing.tinier} 0;
  font-size: ${fontSizes.h1Large};
  font-weight: ${fontWeights.bold};
  font-family: ${fontFamilies.fontHero};
`;

const spanStyle = css`
  font-size: ${fontSizes.h1};
  font-weight: inherit;
  font-family: inherit;
`;

export const Yellow = styled.span`
  ${spanStyle};
  color: ${colors.workwearYellow};
`;

export const White = styled.span`
  ${spanStyle};
  color: ${colors.white};
`;
