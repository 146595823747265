import { Container } from './WorkwearGuideBottomSection.styled';

interface WorkwearGuideBottomSectionProps {
  children?: React.ReactNode;
  result?: boolean;
}

export const WorkwearGuideBottomSection: React.FC<
  WorkwearGuideBottomSectionProps
> = ({ children, result }) => {
  return <Container $result={result}>{children}</Container>;
};
