import {
  ListApiFilter,
  ListApiPaging,
  ListApiResponse,
} from '@hultafors/shared/types';

import {
  GlobalFields,
  SnickersProduct,
  WorkwearGuideCategory,
  WorkwearGuideFilter,
  WorkwearGuideFragment,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import {
  categoryList,
  categorySpec,
  filterSpec,
} from './sizes/data/workwearGuide';

export const getFilters = (
  filters: ListApiFilter[],
  filterCategory: WorkwearGuideStep['name']
): WorkwearGuideFilter[] => {
  switch (filterCategory) {
    case filterSpec.gender.name:
      return getCurrentFilters(filters, filterSpec.gender.id);
    case filterSpec.indoorOutdoor.name:
      return getCurrentFilters(filters, filterSpec.indoorOutdoor.id);
    case filterSpec.environment.name:
      return getCurrentFilters(filters, filterSpec.environment.id);
    case filterSpec.occupation.name:
      return getCurrentFilters(filters, filterSpec.occupation.id);
    case filterSpec.features.name:
      return getCurrentFilters(filters, filterSpec.features.id);
    case filterSpec.certification.name:
      return getCurrentFilters(filters, filterSpec.certification.id);
    case filterSpec.fit.name:
      return getCurrentFilters(filters, filterSpec.fit.id);
    case filterSpec.garment.name:
      return getCurrentFilters(filters, filterSpec.garment.id);
    case filterSpec.riskEnvironment.name:
      return getCurrentFilters(filters, filterSpec.riskEnvironment.id);
    default:
      return [];
  }
};

const getCurrentFilters = (filters: ListApiFilter[], filterId: number) => {
  const result = filters.find(({ id }) => id === filterId);
  if (result?.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const addParentToItem = (result: ListApiFilter): WorkwearGuideFilter[] => {
  return (
    result.values?.map((child) => {
      return {
        ...child,
        parentId: result.id,
        parentType: result.type,
      };
    }) || []
  );
};

// The order of the guide by category
export const resolveStep = (
  step: number,
  category?: number
): WorkwearGuideStep => {
  const resolvedStep: WorkwearGuideStep = {
    name: 'category',
    number: step,
  };
  switch (category) {
    case categorySpec.trousers:
      switch (step) {
        case 0:
          resolvedStep.name = 'category';
          break;
        case 1:
          resolvedStep.name = filterSpec.gender.name;
          break;
        case 2:
          resolvedStep.name = filterSpec.indoorOutdoor.name;
          break;
        case 3:
          resolvedStep.name = filterSpec.occupation.name;
          break;
        case 4:
          resolvedStep.name = filterSpec.features.name;
          break;
        case 5:
          resolvedStep.name = filterSpec.certification.name;
          break;
        case 6:
          resolvedStep.name = filterSpec.fit.name;
          break;
        case 7:
          resolvedStep.name = 'result';
          break;
      }
      break;
    case categorySpec.jackets:
      switch (step) {
        case 0:
          resolvedStep.name = 'category';
          break;
        case 1:
          resolvedStep.name = filterSpec.gender.name;
          break;
        case 2:
          resolvedStep.name = filterSpec.certification.name;
          break;
        case 3:
          resolvedStep.name = filterSpec.occupation.name;
          break;
        case 4:
          resolvedStep.name = filterSpec.environment.name;
          break;
        case 5:
          resolvedStep.name = filterSpec.indoorOutdoor.name;
          break;
        case 6:
          resolvedStep.name = 'result';
          break;
      }
      break;
    case categorySpec.gloves:
      switch (step) {
        case 0:
          resolvedStep.name = 'category';
          break;
        case 1:
          resolvedStep.name = filterSpec.certification.name;
          break;
        case 2:
          resolvedStep.name = filterSpec.occupation.name;
          break;
        case 3:
          resolvedStep.name = filterSpec.environment.name;
          break;
        case 4:
          resolvedStep.name = filterSpec.features.name;
          break;
        case 5:
          resolvedStep.name = 'result';
          break;
      }
      break;
    case categorySpec.protectWork:
      switch (step) {
        case 0:
          resolvedStep.name = 'category';
          break;
        case 1:
          resolvedStep.name = filterSpec.certification.name;
          break;
        case 2:
          resolvedStep.name = filterSpec.gender.name;
          break;
        case 3:
          resolvedStep.name = filterSpec.garment.name;
          break;
        case 4:
          resolvedStep.name = filterSpec.features.name;
          break;
        case 5:
          resolvedStep.name = filterSpec.occupation.name;
          break;
        case 6:
          resolvedStep.name = filterSpec.riskEnvironment.name;
          break;
        case 7:
          resolvedStep.name = filterSpec.fit.name;
          break;
        case 8:
          resolvedStep.name = 'result';
          break;
      }
      break;
  }
  return resolvedStep;
};

export const getCategories = ({
  categories,
}: Partial<WorkwearGuideFragment>): WorkwearGuideCategory[] =>
  categories
    ?.filter(
      (_category, index) =>
        !!categoryList[index]?.steps && !!categoryList[index]?.nodeId
    )
    ?.map(
      ({ title, image }, index): WorkwearGuideCategory =>
        categoryList[index] && {
          image: image || undefined,
          name: title || categoryList[index].name || '',
          nodeId: categoryList[index].nodeId,
          steps: categoryList[index].steps,
        }
    ) || [];

export const resolveResult = (
  productData: ListApiResponse<SnickersProduct>,
  filteredProducts: Pick<ListApiResponse<SnickersProduct>, 'items'>[],
  maxItemsToShow: number
): {
  perfectMatch: SnickersProduct[];
  semiPerfectMatch: SnickersProduct[];
  lessPerfectMatch: SnickersProduct[];
  loadMorePaging: Partial<ListApiPaging>;
} => {
  // Select total of 5 products, always select best matching products in result to fill total result

  const products = productData.items;

  // let semiMatchProducts = [];
  const lessMatchProducts: SnickersProduct[] = [];

  let totalMatches = 0;

  let perfectMatch: SnickersProduct[] = [];
  let notInPerfectList: SnickersProduct[] = [];

  // pager only for perfect matches, used when result is greater than MAX_MATCHES_TO_SHOW
  const loadMorePaging = {
    itemCount: productData.paging.itemCount,
    pageNumber: 1,
    pageSize: maxItemsToShow,
  };
  let semiPerfectMatch: SnickersProduct[] = [];
  let lessPerfectMatch: SnickersProduct[] = [];

  if (products.length) {
    if (products.length > maxItemsToShow) {
      // pick max matches if available
      perfectMatch = products.slice(0, maxItemsToShow);
    } else {
      perfectMatch = products;
    }

    totalMatches += products.length;
  }

  if (totalMatches < maxItemsToShow) {
    if (filteredProducts.length > 1) {
      const semiMatchedFiltered =
        filteredProducts[filteredProducts.length - 1].items;

      notInPerfectList = [];

      semiMatchedFiltered.map((item: any) => {
        // make sure we dont add products that are in perfect match list
        if (!perfectMatch.find((x) => x.sku === item.sku)) {
          notInPerfectList.push(item);
        }
      });
      semiPerfectMatch = notInPerfectList.slice(
        0,
        maxItemsToShow - totalMatches
      );
      // totalMatches += semiMatchProducts.length;
      totalMatches += semiPerfectMatch.length;
    }
  }

  if (totalMatches < maxItemsToShow) {
    let lessMatchedFiltered: SnickersProduct[] = [];

    if (filteredProducts.length > 2) {
      lessMatchedFiltered = filteredProducts[filteredProducts.length - 2].items;
    }

    if (filteredProducts.length === 1) {
      // we only got one result, pretty crappy guide :)
      lessMatchedFiltered = filteredProducts[0].items;
    }

    if (lessMatchedFiltered.length > 0) {
      notInPerfectList = [];

      lessMatchedFiltered.map((item) => {
        // make sure we dont add products that are in perfect or semi perfect match list
        const inPerfectMatch = perfectMatch.find((x) => x.sku === item.sku);
        const inSemiPerfectMatch = semiPerfectMatch.find(
          (x) => x.sku === item.sku
        );
        if (!inPerfectMatch && !inSemiPerfectMatch) {
          notInPerfectList.push(item);
        }
      });

      // TODO loop back in time to make sure we have at least 5 if other ones are empty

      lessPerfectMatch = notInPerfectList.slice(
        0,
        maxItemsToShow - totalMatches
      );
      totalMatches += lessMatchProducts.length;
    }
  }

  return {
    lessPerfectMatch,
    loadMorePaging,
    perfectMatch,
    semiPerfectMatch,
  };
};

export const resolveCurrentTitle = (
  currentStep: WorkwearGuideStep,
  category: number | undefined,
  pageContent: GlobalFields['workwearGuide']
): string => {
  switch (currentStep.name) {
    case filterSpec.gender.name:
      switch (category) {
        case categorySpec.protectWork:
          return pageContent.genderTitleProtectWork || '';
        default:
          return pageContent.genderTitle || '';
      }
    case filterSpec.indoorOutdoor.name:
      return pageContent.environmentTitle || '';
    case filterSpec.riskEnvironment.name:
      return pageContent.riskEnvironmentTitle || '';
    case filterSpec.environment.name:
      switch (category) {
        case categorySpec.gloves:
          return pageContent.environmentTitleGloves || '';
        default:
          return pageContent.coldWetEnvironmentTitle || '';
      }
    case filterSpec.occupation.name:
      switch (category) {
        case categorySpec.jackets:
          return pageContent.occupationTitleJackets || ''; // missing in PT?
        case categorySpec.gloves:
          return pageContent.occupationTitleGloves || '';
        default:
          return pageContent.occupationTitle || '';
      }
    case filterSpec.features.name:
      return pageContent.featuresTitle || '';
    case filterSpec.certification.name:
      switch (category) {
        case categorySpec.protectWork:
          return pageContent.ceCertificationTitleProtectWork || '';
        default:
          return pageContent.ceCertificationTitle || '';
      }
    case filterSpec.fit.name:
      return pageContent.fitTitle || '';
    case filterSpec.garment.name:
      return pageContent.garmentTitle || '';
    default:
      return '';
  }
};

const addWorkwearFilterChildren = ({
  existingFilters,
  parent,
  values,
}: {
  existingFilters: WorkwearGuideFilter[];
  parent: WorkwearGuideFilter;
  values: WorkwearGuideFilter[];
}) => {
  const result = [];
  for (let i = 0, l = existingFilters.length; i < l; i++) {
    if (existingFilters[i].id === parent.id) {
      result.push({
        ...existingFilters[i],
        values,
      });
    } else {
      result.push({ ...existingFilters[i] });
    }
  }
  return result;
};

const createWorkwearFilterParent = ({
  parent,
  child,
}: {
  parent: WorkwearGuideFilter;
  child: WorkwearGuideFilter;
}) => {
  return {
    ...parent,
    values: [{ ...child }],
  };
};

export const toggleWorkwearFilters = ({
  existingFilters,
  parent,
  child,
}: {
  existingFilters: WorkwearGuideFilter[];
  parent: WorkwearGuideFilter;
  child: WorkwearGuideFilter;
}) => {
  let result: WorkwearGuideFilter[] = [];
  let values: WorkwearGuideFilter[] = [];

  if (!parent || !child) {
    return existingFilters;
  }

  if (existingFilters && existingFilters.length > 0) {
    const existingParent = existingFilters.find(
      (value) => value.id === parent.id
    );
    if (existingParent) {
      values =
        existingParent.values?.filter((value: any) => value.id !== child.id) ||
        [];

      if (
        existingParent.values &&
        existingParent.values.length > values.length
      ) {
        // Removing a child filter
        if (existingParent.values.length === 1) {
          // Last child of parent, remove both parent and child
          result = existingFilters.filter((x) => x.id !== parent.id);
        } else {
          // Remove child from parent
          result = addWorkwearFilterChildren({
            existingFilters,
            parent: existingParent,
            values,
          });
        }
      } else {
        // adding a child filter to existing parent
        values = [...(existingParent.values || []), { ...child }];
        result = addWorkwearFilterChildren({
          existingFilters,
          parent: existingParent,
          values,
        });
      }
    } else {
      // Current parent doesn't exist, add new parent and child
      result = [
        ...existingFilters,
        createWorkwearFilterParent({ child, parent }),
      ];
    }
  } else {
    // Filter is empty, add first parent and child
    result = [createWorkwearFilterParent({ child, parent })];
  }

  return result;
};

export const getWorkwearGuideFilterQuery = (
  filters: WorkwearGuideFilter[]
): URLSearchParams => {
  const params = new URLSearchParams();
  let i = 0;

  filters.forEach(({ id: AttrId, values }) => {
    values
      // ?.filter(({ active = false }) => !!active)
      ?.forEach(({ id: ValueId }) => {
        params.set(`f[${i}].AttrId`, `${AttrId}`);
        params.set(`f[${i}].ValueId`, `${ValueId}`);
        i += 1;
      });
  });

  return params;
};
