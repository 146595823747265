import {
  ParagraphSize,
  ParagraphStyled,
  ParagraphStyleType,
} from './Paragraph.styled';

export interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
  size?: ParagraphSize;
  styleType?: ParagraphStyleType;
  center?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  center,
  styleType,
  size = 'regular',
  children,
  className,
}) => {
  return (
    <ParagraphStyled
      className={className}
      $center={center}
      $styleType={styleType}
      $size={size}
    >
      {children}
    </ParagraphStyled>
  );
};
