import { VisibleSeoBlockRecord } from '@hultafors/snickers/types';

import { Section } from '../../../Section/Section';
import { TextBlockOld } from '../../../TextBlockOld/TextBlockOld';

export type VisibleSeoBlockProps = Partial<VisibleSeoBlockRecord>;

export const VisibleSeoBlock: React.FC<VisibleSeoBlockProps> = ({
  title,
  description,
}) => {
  return (
    <Section largePaddingBottomDesktop darkTopBorder>
      <TextBlockOld title={title || ''} text={description || ''} />
    </Section>
  );
};
