import { MobileFilterWrapperStyled } from './MobileFilterWrapper.styled';

export interface MobileFilterWrapperProps {
  children?: React.ReactNode;
}

export const MobileFilterWrapper: React.FC<MobileFilterWrapperProps> = ({
  children,
}) => {
  return <MobileFilterWrapperStyled>{children}</MobileFilterWrapperStyled>;
};
