import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/snickers/helpers';
export const StyledRetailersAdditionalSelections = styled.div`
  display: flex;
  justify-content: center;
  margin-block: ${spacing.regular} ${spacing.regular};

  .AdditionalSelections {
    inline-size: 500px;
  }

  .RadiusSelectContainer {
    label {
      font-size: ${fontSizes.m};
    }
  }
`;
