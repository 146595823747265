import { Markdown } from '../Markdown/Markdown';
import { Paragraph, ParagraphProps } from '../Paragraph/Paragraph';

import { SeoSectionTitle, SeoSectionWrapper } from './SeoSection.styled';

const ParagraphOverride: React.FC<ParagraphProps> = ({ children, ...rest }) => {
  return (
    <Paragraph {...rest} styleType="contentDesktop">
      {children}
    </Paragraph>
  );
};

export interface SeoSectionProps {
  title?: string;
  description?: string;
}

export const SeoSection: React.FC<SeoSectionProps> = ({
  title = '',
  description = '',
}) => (
  <SeoSectionWrapper>
    <SeoSectionTitle>{title}</SeoSectionTitle>
    <Markdown
      options={{ forceBlock: true, overrides: { p: ParagraphOverride } }}
    >
      {description ?? ''}
    </Markdown>
  </SeoSectionWrapper>
);
