import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const LanguageSelectorStyled = styled.div`
  .InnerWrapper {
    max-inline-size: ${breakpoints.maxPageWidth};
    block-size: 100%;
    margin: 0 auto;
    padding: 48px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      flex-direction: row;
      padding: 5px 20px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding: 5px 20px;
    }
  }

  .InfoText {
    display: flex;
    align-self: flex-start;
    margin-block-end: 16px;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      margin-block-end: 0;
      align-self: auto;
    }

    p {
      margin: 0;
      text-align: start;
      font-size: 14px;
      max-inline-size: 650px;

      @media screen and (min-width: ${breakpoints.desktop}) {
        max-inline-size: 800px;
      }
    }
  }

  .Content {
    display: flex;
    align-self: flex-end;

    .Dropdown {
      @media screen and (min-width: ${breakpoints.desktopSmall}) {
        margin-inline-end: 32px;
      }
    }
  }

  .CloseBtn {
    position: absolute;
    inset-block-start: 16px;
    inset-inline-end: 20px;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      position: relative;
      padding: 0 6px;
      inset-block-start: 0;
      inset-inline-end: 0;
    }

    img {
      inline-size: 16px;
      block-size: 16px;
    }
  }
`;
