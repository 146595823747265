import { useContext } from 'react';

import { WorkwearGuideContext } from '@hultafors/snickers/context';

export const useWorkwearGuide = () => {
  const context = useContext(WorkwearGuideContext);
  if (!context) {
    throw new Error(
      'useWorkwearGuide must be used within WorkwearGuideContext!',
    );
  }
  return context;
};
