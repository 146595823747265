import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/snickers/helpers';

export const VideoStyled = styled.div`
  aspect-ratio: 16 / 9;

  .azuremediaplayer {
    block-size: 100%;
  }

  /* The fontSize controls the Play button that appreas in the
    video player. The default size is (at the time of writing) .06em
    which causes the button to not show */

  .vjs-big-play-button {
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
  }

  .vjs-big-play-button::before {
    font-size: ${fontSizes.h2};
    background: ${colors.black};
    padding: ${spacing.xSmall};
    border-radius: 100%;
    opacity: 0.8;
  }

  /* Need increase width to make poster-image fill entire video */
  .vjs-poster {
    img {
      object-fit: cover;
      inline-size: 100%;
      block-size: 100%;
    }
  }
`;
