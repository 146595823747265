import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

interface LoaderStyledProps {
  $small?: boolean;
  $center?: boolean;
}

export const LoaderStyled = styled.div<LoaderStyledProps>`
  width: 20px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;

  ${({ $center }) =>
    $center
    && css`
      justify-content: center;
    `}

  ${({ $small, $center }) =>
    $small
    && css`
      height: 100px;
      margin-block-end: 40px;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        margin-block-end: ${!$center ? '120px' : 'inherit'};
      }
    `}

  img {
    inline-size: 100%;
    animation: spin infinite forwards 1.3s;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      80% {
        transform: rotate(360deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
