import { DoubleTextBlockFragment } from '@hultafors/snickers/types';

import { Grid } from '../../../Grid/Grid';
import { GridChild } from '../../../GridChild/GridChild';
import { Markdown } from '../../../Markdown/Markdown';

import {
  ButtonGridChild,
  Content,
  FirstContent,
  StyledHeader,
  StyledLinkButton,
  Wrapper,
} from './TwoColumnTextBlock.styled';

type TwoColumnTextBlockProps = Omit<DoubleTextBlockFragment, 'id'>;

export const TwoColumnTextBlock: React.FC<TwoColumnTextBlockProps> = ({
  leftBody,
  rightBody,
  header,
  ctaLink,
  ctaLabel,
}) => {
  return (
    <Wrapper>
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'mobileMax', columnGap: 30 },
        ]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild>
          {!!header && <StyledHeader>{header}</StyledHeader>}
        </GridChild>
        <GridChild
          columnSpan={[{ breakpoint: 'mobileMax', columns: 5, start: 2 }]}
        >
          {!!leftBody && (
            <FirstContent>
              <Markdown>{leftBody}</Markdown>
            </FirstContent>
          )}
        </GridChild>
        <GridChild
          columnSpan={[{ breakpoint: 'mobileMax', columns: 5, start: 7 }]}
        >
          {!!rightBody && (
            <Content>
              <Markdown>{rightBody}</Markdown>
            </Content>
          )}
        </GridChild>
        <ButtonGridChild>
          {!!ctaLabel && !!ctaLink && (
            <StyledLinkButton text={ctaLabel} url={ctaLink} className="Grey" />
          )}
        </ButtonGridChild>
      </Grid>
    </Wrapper>
  );
};
