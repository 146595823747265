import { H4BottomSpacing, H4Styled } from './H4.styled';

export interface H4Props {
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  large?: boolean;
  underline?: boolean;
  bottomSpacing?: H4BottomSpacing;
}

export const H4: React.FC<H4Props> = ({
  center,
  large,
  underline,
  bottomSpacing,
  children,
  className,
}) => {
  return (
    <H4Styled
      $center={center}
      $large={large}
      $underline={underline}
      $bottomSpacing={bottomSpacing}
      className={className}
    >
      {children}
    </H4Styled>
  );
};
