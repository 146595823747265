import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/snickers/types';

import { PlugIcon } from '../PlugIcon/PlugIcon';

import {
  Inner,
  ProductPlugImage,
  ProductPlugStyled,
  ProductPlugTitle,
  TextWrapper,
} from './ProductPlug.styled';

export interface ProductPlugProps {
  title?: string | null;
  image?: ImageFragment | null;
  color?: string;
  url?: string | null;
}

export const ProductPlug: React.FC<ProductPlugProps> = ({
  url = '',
  image,
  title = '',
}) => {
  const sizes = [
    '100vw',
    `(min-width: ${breakpoints.largeMobile}) 50vw`,
    `(min-width: ${breakpoints.desktopMedium}) 25vw`,
    `(min-width: ${breakpoints.desktopLarge}) 338px`,
  ].join(', ');
  return (
    <ProductPlugStyled>
      <Inner href={url || ''}>
        <ProductPlugImage>
          {image?.responsiveImage?.src && (
            <Image
              src={image.responsiveImage.src}
              alt={image.alt ?? title ?? ''}
              sizes={sizes}
              fill
            />
          )}
        </ProductPlugImage>
        <TextWrapper>
          <PlugIcon />
          <ProductPlugTitle $hasImage={!!image?.responsiveImage}>
            {title}
          </ProductPlugTitle>
        </TextWrapper>
      </Inner>
    </ProductPlugStyled>
  );
};
