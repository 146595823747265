import { ApiSettings } from '@hultafors/shared/types';

import { Select } from '../Select/Select';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
// import { RADIUS_ALTERNATIVES } from 'views/FindRetailersView';

import { StyledRetailersAdditionalSelections } from './RetailersAdditionalSelections.styled';

export interface RetailersAdditionalSelectionsProps {
  settings: ApiSettings;
  data?: any;
  radiusSelectChange: any;
  selectedRadius?: any;
  internationalSearchToggleChange?: any;
  isRetailerSearchExpandable?: any;
  isSearchInternational?: any;
  radiusAlternatives?: (number | string)[];
}

export const RetailersAdditionalSelections: React.FC<
  RetailersAdditionalSelectionsProps
> = ({
  settings,
  data,
  radiusSelectChange,
  selectedRadius,
  internationalSearchToggleChange,
  isRetailerSearchExpandable,
  isSearchInternational,
  radiusAlternatives,
}) => {
  const renderRadiusSelect = () => {
    if (!isRetailerSearchExpandable) return null;
    return (
      <div className="RadiusSelectContainer">
        <Select
          id="radiusSelect"
          label={data.radiusHelpText}
          handleChange={radiusSelectChange}
          selected={`${selectedRadius} km`}
          options={radiusAlternatives?.map(
            (radiusAlternative) => `${radiusAlternative}`,
          )}
        />
      </div>
    );
  };

  const renderInternationalSearchToggle = () => {
    const isDotCom = settings.market === 'com';
    if (isDotCom) return null;
    return (
      <ToggleSwitch
        label={data.otherCountriesIncluded}
        checked={isSearchInternational}
        onChange={internationalSearchToggleChange}
      />
    );
  };

  return (
    <StyledRetailersAdditionalSelections>
      <div className="AdditionalSelections">
        {renderRadiusSelect()}
        {renderInternationalSearchToggle()}
      </div>
    </StyledRetailersAdditionalSelections>
  );
};
