import { ProductNameStyled } from './ProductName.styled';

export interface ProductNameProps {
  children?: React.ReactNode;
  center?: boolean;
}

export const ProductName: React.FC<ProductNameProps> = ({
  center,
  children,
}) => {
  return <ProductNameStyled $center={center}>{children}</ProductNameStyled>;
};
