import { CenterStyled } from './Center.styled';

interface CenterProps {
  children?: React.ReactNode;
  fill?: boolean;
}

export const Center: React.FC<CenterProps> = ({ children, fill }) => {
  return <CenterStyled $fill={fill}>{children}</CenterStyled>;
};
