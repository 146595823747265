import { startTransition, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { getLanguages } from '@hultafors/shared/api';
import { Language } from '@hultafors/shared/types';

import { markets } from '@hultafors/snickers/api';
import { useGlobal } from '@hultafors/snickers/hooks';

import { Dropdown } from '../Dropdown/Dropdown';
import { Paragraph } from '../Paragraph/Paragraph';

import { LanguageSelectorStyled } from './LanguageSelector.styled';

export interface LanguageSelectorProps {
  infoText?: string;
  chooseLanguageLabel: string;
  isOnlyVisibleOnDesktop?: boolean;
  languages: Language[];
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  chooseLanguageLabel,
  infoText,
  isOnlyVisibleOnDesktop,
}) => {
  const { settings } = useGlobal();
  const languages: Language[] = useMemo(
    () => getLanguages(settings?.market, markets),
    [settings?.market],
  );
  const listItems: string[] = useMemo(
    () => languages.map(({ name, lang }: Language) => name || lang || ''),
    [languages],
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const router = useRouter();

  const getIndexOfSelected = () => {
    return languages?.findIndex((item) => {
      return (
        !!settings.language?.lang
        && !!item.lang
        && item.lang === settings.language?.lang
      );
    });
  };

  useEffect(() => {
    if (
      settings
      && typeof languages?.length === 'number'
      && languages.length > 1
      && selectedIndex < 0
    ) {
      startTransition(() => {
        setSelectedIndex(getIndexOfSelected());
      });
    }
  }, [languages, settings]);

  const changeLanguage = (index: number) => {
    const selectedLang = languages[index];
    router.replace(router.asPath, router.asPath, {
      locale: selectedLang?.urlPrefix || 'com',
    });
  };

  return (
    <LanguageSelectorStyled className="Language-Selector">
      {isOnlyVisibleOnDesktop
        ? (
          <div className="OuterWrapper">
            <div className="InnerWrapper">
              {infoText && (
                <div className="InfoText">
                  <Paragraph>{infoText}</Paragraph>
                </div>
              )}

              <div className="Content">
                <Dropdown
                  className="Dropdown"
                  label={chooseLanguageLabel}
                  onSelectItem={changeLanguage}
                  listItems={listItems}
                  selectedIndex={selectedIndex}
                  dark
                />
              </div>
            </div>
          </div>
          )
        : (
          <div className="MobileDropdown Content">
            <Dropdown
              className="Dropdown"
              label={chooseLanguageLabel}
              onSelectItem={changeLanguage}
              selectedIndex={selectedIndex}
              listItems={listItems}
              dark
            />
          </div>
          )}
    </LanguageSelectorStyled>
  );
};
