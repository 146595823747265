import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, fontWeights, spacing } from '@hultafors/snickers/helpers';

export const TextBlockOldStyled = styled.div`
  margin: 0 16px;

  p,
  li {
    line-height: 24px;
    margin: 12px 0;
    padding: 6px 0;
    white-space: pre-wrap;

    strong {
      font-weight: ${fontWeights.medium};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      line-height: 26px;
    }
  }

  p:first-child,
  li:first-child {
    margin-block-start: 0;
  }

  ol,
  ul {
    margin: 24px 0;
  }

  .CTAButton {
    display: inline-block;
    margin-block-start: ${spacing.tiny};
  }
`;

export const Heading = styled.h2`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.header2};
  line-height: ${fontSizes.header2};
  padding: 10px 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 20px 0 6px;
  }
`;
