import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { Heading, RelatedProductsStyled } from './RelatedProducts.styled';

export interface RelatedProductsProps {
  children?: React.ReactNode;
  header?: string;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  header = '',
  children,
}) => {
  if (!children) {
    return null;
  }
  return (
    <RelatedProductsStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <Heading>{header}</Heading>
        </GridChild>
      </Grid>
      {children}
    </RelatedProductsStyled>
  );
};
