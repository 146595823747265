import { CSSProperties } from 'styled-components';

import { FlexStyled } from './Flex.styled';

export interface FlexProps {
  className?: string;
  children?: React.ReactNode;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  dir?: CSSProperties['flexDirection'];
  m?: CSSProperties['margin'];
  p?: CSSProperties['padding'];
  wrap?: boolean;
}

export const Flex: React.FC<FlexProps> = ({
  className,
  children,
  align = 'flex-start',
  justify = 'center',
  dir = 'row',
  m,
  p,
  wrap,
}) => {
  return (
    <FlexStyled
      className={className}
      $wrap={wrap}
      $align={align}
      $justify={justify}
      $dir={dir}
      $m={m}
      $p={p}
    >
      {children}
    </FlexStyled>
  );
};
