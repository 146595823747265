export interface AccordionItemOldProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  children?: React.ReactNode;
  $label: string;
  $forceCollapsible?: boolean;
}

export const AccordionItemOld: React.FC<AccordionItemOldProps> = ({
  children,
  ...rest
}) => {
  return <li {...rest}>{children}</li>;
};
