import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/snickers/helpers';

import { Button } from '../../Button/Button';

export const Sizes = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
  gap: ${spacing.tinier};
  margin-block-start: ${spacing.small};
  margin-inline: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.gray5};
`;

interface SizeButtonProps {
  $selected?: boolean;
}

export const SizeButton = styled(Button)<SizeButtonProps>`
  background-color: ${colors.gray5};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  height: 44px;
  border: none;
  color: ${colors.workwearBlack};
  font-family: inherit;
  letter-spacing: 0.2px;

  &:hover {
    background-color: ${colors.gray3};
  }

  ${({ $selected }) =>
    $selected
    && css`
      background-color: ${colors.workwearBlack};
      color: ${colors.white};
    `}
`;
