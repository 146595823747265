export const lineHeights = {
  body: '20px',
  bodyDesktop: 1.625,
  bodyMobile: 1.7,
  contentDesktop: '1.625rem',
  contentMobile: '24px',
  header1: '42px',
  header1Content: '37px',
  header2: '36px',
  header2Content: '28px',
  header3: '24px',
  header3Content: '24px',
  header4: '28px',
  ingressDesktop: '1.75rem',
  l: 1.7,
  m: 1.5,
  productDesktop: 1.3,
  productMobile: 1.4,
  s: 1.25,
  xs: 1,
} as const;

export type LineHeights = keyof typeof lineHeights;
