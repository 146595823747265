import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
} from '@hultafors/snickers/helpers';

interface StyledDesktopMenuItemProps {
  $active?: boolean;
  $transparent?: boolean;
}

export const StyledDesktopMenuItem = styled.a<StyledDesktopMenuItemProps>`
  display: inline-flex;
  position: relative;
  font: ${fontFamilies.fontRegular};
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.5px;
  color: ${colors.workwearBlack};
  text-decoration: none;
  transition: all 0.2s linear;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  height: 48px;

  &:empty {
    display: none;
  }

  &:hover {
    color: ${colors.gray1};
  }

  &::after {
    content: '';
    position: absolute;
    block-size: 2px;
    inline-size: 16px;
    inset-block-end: 8px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background: ${colors.gray1};
    border-radius: 2px;
    visibility: hidden;
    ${({ $active }) =>
      $active
      && css`
        visibility: visible;
      `}
  }

  &:hover::after {
    visibility: visible;
  }

  ${({ $transparent }) =>
    $transparent
    && css`
      color: ${colors.white};

      &::after {
        background: ${colors.white};
      }

      &:hover {
        color: ${colors.white};
      }
    `}
`;
