import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, fontWeights, spacing } from '@hultafors/snickers/helpers';

import { GridChild } from '../../../GridChild/GridChild';
import { LinkButton } from '../../../LinkButton/LinkButton';

export const ButtonGridChild = styled(GridChild)`
  justify-self: start;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    justify-self: center;
  }
`;

export const Content = styled.div`
  padding-inline: ${spacing.regular};
  font-size: ${fontSizes.body};

  > p {
    font-size: ${fontSizes.body};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
    margin-block-end: 0;
  }
`;

export const FirstContent = styled.div`
  padding-inline: ${spacing.regular};
  font-size: ${fontSizes.body};

  > p {
    font-size: ${fontSizes.body};
  }

  margin-block-end: 20px;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
    margin-block-end: 0;
  }
`;

export const StyledHeader = styled.h2`
  margin-inline: auto;
  padding-inline-start: ${spacing.regular};
  padding-block-start: ${spacing.regular};
  text-align: start;
  margin-block: 0 24px;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.header2};
  line-height: ${fontSizes.header2};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline-start: 0;
    padding-block-start: 0;
    max-inline-size: 50%;
    text-align: center;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-block-start: ${spacing.regular};
`;

export const Wrapper = styled.div``;
