import {
  WorkwearGuideCategory,
  WorkwearGuideSelection,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import { Button } from '../Button/Button';
import { Center } from '../Center/Center';
import { GuideHero } from '../GuideHero/GuideHero';
import { Loader } from '../Loader/Loader';
import { WorkwearGuideBottomSection } from '../WorkwearGuideBottomSection/WorkwearGuideBottomSection';
import { WorkwearGuideCategoryCard } from '../WorkwearGuideCategoryCard/WorkwearGuideCategoryCard';
import { WorkwearGuideContainer } from '../WorkwearGuideContainer/WorkwearGuideContainer';
import { WorkwearGuideInner } from '../WorkwearGuideInner/WorkwearGuideInner';

import { Categories } from './WorkwearGuideStart.styled';

export interface WorkwearGuideStartProps {
  changeStep(stepNumber: number): void;
  selectCategory(category: WorkwearGuideCategory): void;
  currentStep: WorkwearGuideStep;
  numberOfSteps: number;
  selection: WorkwearGuideSelection;
  categories: WorkwearGuideCategory[];
  heroTitle: string;
  heroIntro?: string;
  title: string;
  backText?: string;
  nextText?: string;
  loading?: boolean;
}

export const WorkwearGuideStart: React.FC<WorkwearGuideStartProps> = ({
  categories,
  selection,
  title,
  heroIntro = '',
  currentStep,
  backText = '',
  nextText = '',
  changeStep,
  selectCategory,
  loading = true,
}) => {
  const categoryMapper = (category: WorkwearGuideCategory) => {
    return category
      ? (
        <WorkwearGuideCategoryCard
          key={`WorkwearGuideCategory-${category.nodeId}`}
          selectCategory={selectCategory}
          selected={category?.nodeId === selection.category}
          category={category}
        />
        )
      : null;
  };
  const onClick = () => {
    changeStep(currentStep.number + 1);
  };
  return (
    <WorkwearGuideContainer>
      <WorkwearGuideInner>
        <GuideHero
          title={title}
          introText={heroIntro}
          isBig
          backText={backText}
          currentStep={currentStep.number}
          changeStep={changeStep}
          guideType="workwear"
        />

        {loading
          ? (
            <Center>
              <Loader small center />
            </Center>
            )
          : (
            <Categories>{categories.map(categoryMapper)}</Categories>
            )}
      </WorkwearGuideInner>

      <WorkwearGuideBottomSection>
        <Button
          iconNext
          onClick={onClick}
          disabled={loading || selection.category ? false : true}
        >
          {nextText}
        </Button>
      </WorkwearGuideBottomSection>
    </WorkwearGuideContainer>
  );
};
