import {
  CartItem,
  ProductDetailData,
  ProductListData,
  RawProductModel,
} from '@hultafors/snickers/types';

import { transformModel } from './transformModel';
import { transformProduct } from './transformProduct';

/**
 * @desc Transforms product object to minimal version for cart
 * @return cart (array)
 */
export const transformProductForCart = (
  product: ProductListData | ProductDetailData,
  model: RawProductModel,
  amount: number,
  productListId: string
): CartItem => {
  const transformedProduct = transformProduct(product);
  const cartItem: CartItem = {
    model: transformModel(model, amount),
    name: model.name ? model.name : transformedProduct.name || '',
    productListId,
  };

  return cartItem;
};
