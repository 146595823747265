import { StyledOptionsBarButton } from './OptionsBarButton.styled';

type OptionsBarButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  chevron?: boolean;
  sizeGuide?: boolean;
  color?: boolean;
};

export const OptionsBarButton: React.FC<OptionsBarButtonProps> = ({
  chevron,
  sizeGuide,
  color,
  ...rest
}) => {
  return (
    <StyledOptionsBarButton
      $sizeGuide={sizeGuide}
      $chevron={chevron}
      $color={color}
      {...rest}
    />
  );
};
