import { BoxStyled } from './Box.styled';

export interface BoxProps {
  children?: React.ReactNode;
  marginTop?: React.CSSProperties['marginTop'];
  shadow?: boolean;
  className?: string;
}

export const Box: React.FC<BoxProps> = ({
  children,
  className,
  marginTop,
  shadow,
}) => {
  return (
    <BoxStyled className={className} $marginTop={marginTop} $shadow={shadow}>
      {children}
    </BoxStyled>
  );
};
