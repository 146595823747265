import {
  WorkwearGuideFilter,
  WorkwearGuideSelection,
  WorkwearGuideSelectionMulti,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import { Button } from '../Button/Button';
import { Center } from '../Center/Center';
import { GuideHero } from '../GuideHero/GuideHero';
import { Loader } from '../Loader/Loader';
import { WorkwearGuideBottomSection } from '../WorkwearGuideBottomSection/WorkwearGuideBottomSection';
import { WorkwearGuideContainer } from '../WorkwearGuideContainer/WorkwearGuideContainer';
import { WorkwearGuideInner } from '../WorkwearGuideInner/WorkwearGuideInner';

import {
  FilterGrid,
  SquareBox,
  SquareBoxInput,
  SquareBoxLabel,
} from './WorkwearGuideSquareBoxes.styled';

export interface WorkwearGuideSquareBoxesProps {
  changeStep(stepNumber: number): void;
  selectFilter(
    category: WorkwearGuideSelectionMulti,
    item: WorkwearGuideFilter
  ): void;
  currentStep: WorkwearGuideStep;
  numberOfSteps: number;
  selection: WorkwearGuideSelection;
  filters: WorkwearGuideFilter[];
  title: string;
  backText?: string;
  nextText?: string;
  loading?: boolean;
}

/* For multiple selections */

export const WorkwearGuideSquareBoxes: React.FC<
  WorkwearGuideSquareBoxesProps
> = ({
  currentStep,
  changeStep,
  selectFilter,
  numberOfSteps,
  selection,
  filters,
  title,
  backText = '',
  nextText = '',
  loading = true,
}) => {
  const stepName = currentStep.name as WorkwearGuideSelectionMulti;
  const isSelected = (item: WorkwearGuideFilter): boolean =>
    !!selection[stepName]?.some(({ id }) => id === item.id);

  const filterMapper = (item: WorkwearGuideFilter, index: number) => {
    const onChange = () => selectFilter(stepName, item);
    return (
      <SquareBox key={`SquareBoxes-${index}`} $selected={isSelected(item)}>
        <SquareBoxInput
          id={`SquareBoxes-${item.id}`}
          name={item.description}
          type="checkbox"
          value={item.id}
          checked={isSelected(item)}
          onChange={onChange}
        />
        <SquareBoxLabel>{item.description}</SquareBoxLabel>
      </SquareBox>
    );
  };

  const onClick = () => changeStep(currentStep.number + 1);

  return (
    <WorkwearGuideContainer>
      {loading
        ? (
          <Center>
            <Loader small />
          </Center>
          )
        : (
          <WorkwearGuideInner>
            <GuideHero
              backText={backText}
              currentStep={currentStep.number}
              numberOfSteps={numberOfSteps}
              changeStep={changeStep}
              title={title}
              guideType="workwear"
            />

            <FilterGrid>{filters.map(filterMapper)}</FilterGrid>
          </WorkwearGuideInner>
          )}

      <WorkwearGuideBottomSection>
        <Button iconNext onClick={onClick} disabled={loading}>
          {nextText}
        </Button>
      </WorkwearGuideBottomSection>
    </WorkwearGuideContainer>
  );
};
