import { FactBoxFragment } from '@hultafors/snickers/types';

import YellowLineMobileSvg from '../../svg/yellow-line-mobile.svg';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';

import { FactBoxStyled, Heading } from './FactBox.styled';

// currently extends fragment to not break old functionality
// Will be changed when feed block is not used.
type FactBoxProps = FactBoxFragment;

export const FactBox: React.FC<FactBoxProps> = ({ header, text }) => {
  return (
    <FactBoxStyled data-test-id="Factbox">
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'mobileMax', columnGap: 20 },
        ]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
          ]}
        >
          <div className="FactInner" data-test-id="FactInner">
            <Heading>
              <YellowLineMobileSvg aria-hidden={true} focusable={false} />
              {header}
            </Heading>
            {text && <Markdown options={{ forceBlock: true }}>{text}</Markdown>}
          </div>
        </GridChild>
      </Grid>
    </FactBoxStyled>
  );
};
