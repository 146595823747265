import styled from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

export const FilterList = styled.div`
  > * + * {
    margin-block-start: 8px;
  }

  padding-block-end: 16px;
`;

export const FilterIcon = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0.25ch;
`;

export const Filter = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-block: 4px;
  padding-inline: 24px;

  @media screen and (hover: none) {
    min-block-size: 48px;
  }

  & + & {
    margin-block-start: 8px;
  }
`;

export const Label = styled.span`
  flex: 1;
  font-size: 14px;
  line-height: 18px;

  @media screen and (hover: none) {
    font-size: 16px;
  }
`;

export const CheckBox = styled.input`
  cursor: pointer;
  appearance: none;
  border: 0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  background-color: ${colors.gray4};
  color: ${colors.black};

  &:checked {
    background-color: ${colors.black};
    color: ${colors.white};
    background-image: url('/svg/check-mark-white.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const FilterLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  min-height: 32px;
`;
