import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/snickers/helpers';

export const ImageBlockStyled = styled.div``;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;
  aspect-ratio: 16 / 9;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block: ${spacing.medium};
  }
`;
