import styled from 'styled-components';

export const PageStyled = styled.main<{ $fullscreen?: boolean }>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-inline: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
