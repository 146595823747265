import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const RelatedArticlesStyled = styled.div`
  h3 {
    margin-block-end: 30px;
    letter-spacing: 1px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: 75px;
    }
  }

  .Col-2 {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: 0;
    }
  }

  .alignBottom {
    display: flex;
    block-size: 100%;
    inline-size: 100%;
    align-items: flex-end;
  }
`;

export const PlugWrapper = styled.div`
  > * {
    aspect-ratio: 1;
  }
`;
