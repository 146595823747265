import {
  AlertPlacement,
  AlertSize,
  AlertStatus,
  StyledAlert,
} from './Alert.styled';

export interface AlertProps {
  children?: React.ReactNode;
  size?: AlertSize;
  status?: AlertStatus;
  position?: React.CSSProperties['position'];
  placement?: AlertPlacement;
}

export const Alert: React.FC<AlertProps> = ({
  size = 'medium',
  status = 'info',
  position = 'relative',
  placement,
  children,
}) => {
  return (
    <StyledAlert
      $size={size}
      $status={status}
      $position={position}
      $placement={placement}
      data-test-id="Alert"
    >
      {children}
    </StyledAlert>
  );
};
