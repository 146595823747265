// import { CartData } from '@hultafors/snickers/api'
import { createContext, startTransition, useEffect, useState } from 'react';

import {
  addToCart,
  deleteItemFromCart,
  LocalStorage,
} from '@hultafors/snickers/helpers';

interface CartContextValue {
  cart: any[];
  deleteCart(): void;
  deleteCartItem(props: any): void;
  addItemToCart(props: any): void;
}

export const CartContext = createContext<CartContextValue | null>(null);

export interface CartProviderProps {
  children?: React.ReactNode;
  value: CartContextValue;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const KEY = 'snickers_cart';
  const [cart, setCart] = useState<any[]>([]);

  useEffect(() => {
    const result = LocalStorage?.get(KEY);
    if (result) {
      startTransition(() => {
        setCart(result);
      });
    }
  }, []);

  const addItemToCart = ({
    product,
    color,
    size,
    productListId,
  }: {
    product: any;
    color: any;
    size: any;
    productListId: any;
  }) => {
    const newCart = addToCart({ cart, color, product, productListId, size });
    startTransition(() => {
      setCart(newCart);
      LocalStorage?.set(KEY, cart);
    });
  };

  const deleteCartItem = (product: any) => {
    const newCart = deleteItemFromCart(cart, product);
    startTransition(() => {
      setCart(newCart);
      LocalStorage?.set(KEY, cart);
    });
  };

  const deleteCart = () => {
    startTransition(() => {
      setCart([]);
      LocalStorage?.remove(KEY);
    });
  };

  const value: CartContextValue = {
    addItemToCart,
    cart,
    deleteCart,
    deleteCartItem,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
