import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

export const InfoBoxStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  margin: 36px 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0 0 36px;
  }

  img {
    margin-inline-end: 16px;
    inline-size: 40px;
    block-size: 40px;
  }
`;
