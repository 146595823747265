import styled from 'styled-components';

import { colors, fontFamilies, fontWeights } from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

export const CertificatePlugStyled = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  background-color: ${colors.gray4};
  cursor: pointer;
  color: ${colors.black};

  &:hover {
    color: ${colors.black};
  }
`;

export const Title = styled.p`
  font-family: ${fontFamilies.mikro};
  font-size: 14px;
  line-height: 22px;
  font-weight: ${fontWeights.bold};
  letter-spacing: 0.5px;
  margin: 8px 0 0;
`;

export const Description = styled.p`
  font-family: ${fontFamilies.faktPro};
  font-size: 14px;
  line-height: 24px;
  max-width: 320px;
  margin-block-start: 0;
  align-self: center;
`;
