import { LinkBlockFragment } from '@hultafors/snickers/types';

import FileSvg from '../../../../svg/file.svg';

import { DocumentLinkBlockStyled } from './DocumentLinkBlock.styled';
export const DocumentLinkBlockNew: React.FC<LinkBlockFragment> = ({
  linkBlock,
}) => {
  return (
    <DocumentLinkBlockStyled>
      {linkBlock?.map((item, index) => {
        return (
          <a
            key={`DocumentLink-${index}`}
            href={item?.url}
            target="_blank"
            rel="noreferrer"
          >
            <FileSvg
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
            {item?.title || 'File'}
          </a>
        );
      })}
    </DocumentLinkBlockStyled>
  );
};
