import styled from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

export const LinkBoxStyled = styled.div`
  height: 216px;
  width: 100%;
  background-color: ${colors.workwearYellow};
  box-sizing: border-box;
  transition: all 0.3s ease;

  a {
    text-decoration: none;
  }

  .LinkBoxInner {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    inline-size: 100%;
    block-size: 100%;
    padding: 24px;
    cursor: pointer;
  }

  .LinkBoxLabel {
    text-decoration: none;
    font-size: 16px;
    font-weight: ${fontWeights.medium};
  }

  .Arrow {
    path.Line {
      transform: scaleX(1);
      transition: transform 0.3s ease;
    }
  }

  &:hover {
    background-color: ${colors.workwearYellow2};

    .Arrow path.Line {
      transform: scaleX(1.2);
    }
  }
`;
