import { startTransition, useEffect, useState } from 'react';

import {
  DEFAULT_SELECTION,
  otherBrandSizes,
  SIZE_GUIDE_STEPS,
} from '@hultafors/snickers/product-helpers';
import { SizeSelection } from '@hultafors/snickers/types';

import { Badge } from '../../Badge/Badge';
import { Button } from '../../Button/Button';
import { Dropdown } from '../../Dropdown/Dropdown';
import { GuideHero } from '../../GuideHero/GuideHero';
import { SizeGuideStyled } from '../SizeGuide.styled';

export interface OtherBrandProps {
  changeStep(...args: any[]): unknown;
  steps: typeof SIZE_GUIDE_STEPS;
  selection: SizeSelection;
  title?: string;
  backText?: string;
  chooseBrandText?: string;
  chooseLegLengthText?: string;
  chooseSizeText?: string;
  changeSize(...args: any[]): unknown;
  toggleGuide(...args: any[]): unknown;
  showMySize?: string;
  introText?: string;
}

export const OtherBrand: React.FC<OtherBrandProps> = ({
  changeStep,
  steps,
  selection,
  title,
  backText,
  chooseBrandText,
  chooseLegLengthText,
  chooseSizeText,
  changeSize,
  showMySize,
  introText,
}) => {
  const [brand, setBrand] = useState(-1);
  const [legList, setLegList] = useState<any[]>([]);
  const [legLength, setLegLength] = useState<number>();
  const [sizeList, setSizeList] = useState<any[]>([]);
  const [sizeIndex, setSizeIndex] = useState<number>();
  const [resetLegLabel, setResetLegLabel] = useState(false);
  const [resetSizeLabel, setResetSizeLabel] = useState(false);

  useEffect(() => {
    if (selection.otherBrand?.size) {
      reloadValues();
    }
  }, []);

  const reloadValues = () => {
    if (selection.otherBrand) {
      const { brandIndex, legIndex, sizeIndex } = selection.otherBrand || {};
      // const brandIndex = selection.otherBrand?.brandIndex
      // const legIndex = selection.otherBrand?.legIndex
      // const sizeIndex = selection.otherBrand?.sizeIndex

      const legList = otherBrandSizes[brandIndex]?.values.map((x) => x.name);
      const sizeList = otherBrandSizes[brandIndex]?.values[legIndex]?.sizes.map(
        (x) => x.self
      );

      startTransition(() => {
        if (legList) {
          setLegList(legList);
        }
        if (sizeList) {
          setSizeList(sizeList);
        }
        setBrand(brandIndex);
        setLegLength(legIndex);
        setSizeIndex(sizeIndex);
      });
    }
  };

  const changeBrand = (brandIndex: number) => {
    setBrand(brandIndex);

    const legLengths = otherBrandSizes[brandIndex]?.values.map((x) => x.name);

    if (legLengths) {
      startTransition(() => {
        setLegLength(undefined);
        setResetLegLabel(true);
        setResetSizeLabel(true);
        setLegList(legLengths);
      });
    }

    changeSize(0, {
      ...DEFAULT_SELECTION.otherBrand,
      brandIndex: parseInt(`${brandIndex}`),
    });
  };

  const changeLegLength = (index: number) => {
    startTransition(() => {
      setResetLegLabel(false);
      setResetSizeLabel(true);
      setLegLength(index);
    });

    const sizes = otherBrandSizes[brand]?.values[index]?.sizes.map(
      (x) => x.self
    );
    if (sizes) {
      startTransition(() => {
        setSizeList(sizes);
      });
    }

    changeSize(0, {
      ...DEFAULT_SELECTION.otherBrand,
      brandIndex: parseInt(`${brand}`),
      legIndex: parseInt(`${index}`),
    });
  };

  const localChangeSize = (index: number) => {
    startTransition(() => {
      setResetSizeLabel(false);
    });

    if (index) {
      const size = otherBrandSizes[brand]?.values[legLength || 0]?.sizes[index];

      if (size) {
        changeSize(size.snickers, {
          ...DEFAULT_SELECTION.otherBrand,
          brandIndex: brand,
          legIndex: legLength,
          size: size.self,
          sizeIndex: index,
        });
      }
    }
  };

  const validateScreen = () => {
    return selection.otherBrand?.size ? false : true;
  };

  return (
    <SizeGuideStyled>
      <GuideHero
        title={title}
        introText={introText || ''}
        isBig={false}
        backText={backText}
        currentStep={1}
        changeStep={changeStep}
        guideType="size"
      />

      <div className="DropdownWrapper">
        <Badge round large className="DropdownBadge">
          1
        </Badge>
        <Dropdown
          className="Dropdown"
          label={chooseBrandText || ''}
          onSelectItem={changeBrand}
          listItems={otherBrandSizes.map((x) => x.name)}
          selectedIndex={brand}
        />
      </div>
      <div className="DropdownWrapper">
        <Badge
          round
          large
          disabled={brand === -1 ? true : false}
          className="DropdownBadge"
        >
          2
        </Badge>
        <Dropdown
          className="Dropdown"
          label={chooseLegLengthText || ''}
          onSelectItem={changeLegLength}
          listItems={legList}
          disabled={brand === -1 ? true : false}
          reset={resetLegLabel ? true : false}
          selectedIndex={legLength}
        />
      </div>

      <div className="DropdownWrapper">
        <Badge
          round
          large
          disabled={legLength ? false : true}
          className="DropdownBadge"
        >
          3
        </Badge>
        <Dropdown
          className="Dropdown"
          label={chooseSizeText || ''}
          onSelectItem={changeSize}
          listItems={sizeList}
          disabled={legLength ? false : true}
          reset={resetSizeLabel ? true : false}
          selectedIndex={sizeIndex}
        />
      </div>

      <div className="Summary">
        <Button
          onClick={() => changeStep(steps['RESULT'])}
          disabled={validateScreen()}
        >
          {showMySize}
        </Button>
      </div>
    </SizeGuideStyled>
  );
};
