import { SectionStyled } from './Section.styled';

export interface SectionProps {
  darkTopBorder?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  hide?: boolean;
  fullBleed?: boolean;
  noTop?: boolean;
  zeroBottom?: boolean;
  zeroTop?: boolean;
  borderTop?: boolean;
  borderTopDesktop?: boolean;
  borderBottomDesktop?: boolean;
  largePaddingTop?: boolean;
  largePaddingBottomDesktop?: boolean;
  largeMarginTop?: boolean;
  noMarginTop?: boolean;
  moreMarginBottom?: boolean;
  transparent?: boolean;
  paddingBottom?: boolean;
  padding?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  className,
  style,
  children,
  hide,
  fullBleed,
  noTop,
  zeroBottom,
  zeroTop,
  borderTop,
  borderTopDesktop,
  borderBottomDesktop,
  largePaddingTop,
  largePaddingBottomDesktop,
  largeMarginTop,
  noMarginTop,
  moreMarginBottom,
  transparent,
  paddingBottom,
  padding,
}) => (
  <SectionStyled
    className={className}
    style={style}
    $noMarginTop={noMarginTop}
    $hide={hide}
    $fullBleed={fullBleed}
    $noTop={noTop}
    $zeroBottom={zeroBottom}
    $zeroTop={zeroTop}
    $borderTop={borderTop}
    $borderTopDesktop={borderTopDesktop}
    $borderBottomDesktop={borderBottomDesktop}
    $largePaddingTop={largePaddingTop}
    $largePaddingBottomDesktop={largePaddingBottomDesktop}
    $largeMarginTop={largeMarginTop}
    $moreMarginBottom={moreMarginBottom}
    $transparent={transparent}
    $paddingBottom={paddingBottom}
    $padding={padding}
  >
    {children}
  </SectionStyled>
);
