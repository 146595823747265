import { BadgeStyled } from './Badge.styled';

export interface BadgeProps {
  children?: string | number;
  className?: string;
  inline?: boolean;
  round?: boolean;
  small?: boolean;
  yellow?: boolean;
  tiny?: boolean;
  large?: boolean;
  disabled?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({
  className,
  children,
  inline,
  round,
  small,
  yellow,
  tiny,
  large,
  disabled,
}) => (
  <BadgeStyled
    className={`Badge ${className}`}
    $inline={inline}
    $round={round}
    $small={small}
    $yellow={yellow}
    $tiny={tiny}
    $large={large}
    $disabled={disabled}
  >
    {children}
  </BadgeStyled>
);
