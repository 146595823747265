import { useContext } from 'react';

import { SizeGuideContext } from '@hultafors/snickers/context';

export const useSizeGuide = () => {
  const context = useContext(SizeGuideContext);
  if (!context) {
    throw new Error(
      'useSizeGuide hook is only available in a SizeGuideContext!',
    );
  }
  return context;
};
