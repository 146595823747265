import { Component, createRef } from 'react';

import { H4 } from '../H4/H4';

import { CollapseStyled } from './Collapse.styled';

export interface CollapseProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  label: string;
  subLabel?: string;
  onClick(param?: string): void;
  small?: boolean;
  noPadding?: boolean;
  inTabs?: boolean;
}

interface CollapseState {
  isOpen: boolean;
  height: number;
}

export class Collapse extends Component<CollapseProps, CollapseState> {
  comp: React.RefObject<HTMLDivElement>;

  constructor(props: CollapseProps) {
    super(props);
    this.comp = createRef();

    this.state = {
      height: 0,
      isOpen: true,
    };
  }

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  setHeight() {
    const comp = this.comp.current?.querySelector('.CollapseContent');
    let height = (comp?.scrollHeight || 0) + 70 + 30;
    const collapsibleChildren = comp?.querySelectorAll('.CollapseContent');

    collapsibleChildren?.forEach((child) => {
      height += child?.scrollHeight ?? 0;
    });

    this.setState({ height });
  }

  override componentDidMount() {
    this.setHeight();
  }

  override render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <CollapseStyled
        $height={this.state.height ?? 0}
        ref={this.comp}
        $small={!!this.props.small}
        $noPadding={!!this.props.noPadding}
        $inTabs={!!this.props.inTabs}
      >
        <button
          onClick={onClick}
          className={isOpen ? 'CollapseHeader isOpen' : 'CollapseHeader'}
        >
          <div className="InnerCollapse">
            <H4 large>{label}</H4>
            {this.props.subLabel && (
              <span className="SubLabel">{this.props.subLabel}</span>
            )}
          </div>
        </button>

        <div className={isOpen ? 'CollapseContent isOpen' : 'CollapseContent'}>
          {this.props.children}
        </div>
      </CollapseStyled>
    );
  }
}
