import { QuoteFragment } from '@hultafors/snickers/types';

import { Grid } from '../../../Grid/Grid';
import { GridChild } from '../../../GridChild/GridChild';

import {
  Quote,
  QuoteBlockStyled,
  QuoteInner,
  QuoteWrapper,
} from './QuoteBlock.styled';

export const QuoteBlockNew: React.FC<QuoteFragment> = ({ text }) => {
  return (
    <QuoteBlockStyled $fullWidthRight>
      <QuoteWrapper>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 10, start: 2 },
            ]}
          >
            <QuoteInner>
              <Quote>{text}</Quote>
            </QuoteInner>
          </GridChild>
        </Grid>
      </QuoteWrapper>
    </QuoteBlockStyled>
  );
};
