import { ArticleSectionStyled } from './ArticleSection.styled';

export interface ArticleSectionProps {
  children?: React.ReactNode;
  articleTop?: boolean;
}

export const ArticleSection: React.FC<ArticleSectionProps> = ({
  children,
  articleTop,
}) => {
  return (
    <ArticleSectionStyled $articleTop={articleTop}>
      {children}
    </ArticleSectionStyled>
  );
};
