import { useId } from 'react';

import dynamic from 'next/dynamic';

import {
  ContentAreaField,
  isBigPlugsBlock,
  isBreadcrumb,
  isContentPlugsBlock,
  isDotDigitalForm,
  isDoubleTextBlock,
  isDualImage,
  isFactBox,
  isFeedBlock,
  isFindRetailersBlock,
  isGridOneThree,
  isHeroBlock,
  isImageBlock,
  isImageSlider,
  isImageTextBlock,
  isLinkBlock,
  isMoreArticlesBlock,
  isOffices,
  isPageNavigation,
  isPerson,
  isPersonInfo,
  isProductContentPlugsBlock,
  isProductPlugsBlock,
  isQuote,
  isRelatedProductsBlock,
  isSliderBlock,
  isTextBlock,
  isVideo,
  isVideoBlock,
  isVisibleSeoBlock,
} from '@hultafors/snickers/types';

import { ContentAreaStyled } from './ContentArea.styled';

const BigPlugsBlock = dynamic(() =>
  import('../big-plugs-block/big-plugs-block').then((mod) => mod.BigPlugsBlock),
);
const Breadcrumbs = dynamic(() =>
  import('../breadcrumbs/breadcrumbs').then((mod) => mod.Breadcrumbs),
);
const ContentPlugsBlock = dynamic(() =>
  import('../content-plugs-block/content-plugs-block').then(
    (mod) => mod.ContentPlugsBlock,
  ),
);
const DocumentLinkBlockNew = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.DocumentLinkBlockNew),
);
const DoubleImageBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.DoubleImageBlock),
);
const DotDigitalFormBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.DotDigitalFormBlock),
);
const FactBox = dynamic(() =>
  import('../FactBox/FactBox').then((mod) => mod.FactBox),
);
const FeedBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.FeedBlock),
);
const FindRetailersBlock = dynamic(() =>
  import('../find-retailers-block/find-retailers-block').then(
    (mod) => mod.FindRetailersBlock,
  ),
);
const GridOneThree = dynamic(() =>
  import('../grid-one-three/grid-one-three').then((mod) => mod.GridOneThree),
);
const HeroBlock = dynamic(() =>
  import('../hero-block/hero-block').then((mod) => mod.HeroBlock),
);
const ImageBlockNew = dynamic(() =>
  import('../image-block-new/image-block-new').then((mod) => mod.ImageBlockNew),
);
const ImageSliderBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.ImageSliderBlock),
);
const ImageTextBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.ImageTextBlock),
);
const MoreArticlesBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.MoreArticlesBlock),
);
const Offices = dynamic(() =>
  import('../ContactStores/ContactStores').then((mod) => mod.ContactStores),
);
const PageNavigation = dynamic(() =>
  import('../page-navigation/page-navigation').then((mod) => mod.PageNavigation),
);
const PersonBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.PersonBlock),
);
const PersonInfoBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.PersonInfoBlock),
);
const ProductContentPlugsBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.ProductContentPlugsBlock),
);
const ProductPlugsBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.ProductPlugsBlock),
);
const QuoteBlockNew = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.QuoteBlockNew),
);
const RelatedProductsBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.RelatedProductsBlock),
);
const SliderBlock = dynamic(() =>
  import('../slider-block/slider-block').then((mod) => mod.SliderBlock),
);
const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((mod) => mod.TextBlock),
);
const TwoColumnTextBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.TwoColumnTextBlock),
);
const VideoBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.VideoBlock),
);
const VideoPlayerBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.VideoPlayerBlock),
);
const VisibleSeoBlock = dynamic(() =>
  import('./_contentAreaBlocks').then((mod) => mod.VisibleSeoBlock),
);

interface ContentAreaProps {
  content: ContentAreaField[];
}

export const ContentArea: React.FC<ContentAreaProps> = ({ content = [] }) => {
  const uid = useId();
  const contentMapper = (
    item: ContentAreaField,
    index: number,
    all: ContentAreaField[],
  ) => {
    const key = `ContentArea-${uid}-${item.__typename}-${index}`;
    let priority = index === 0;
    let firstIsNav = false;
    let secondIsNav = false;

    if (all[0]) {
      firstIsNav = isPageNavigation(all[0]) || isBreadcrumb(all[0]);
    }

    if (all[1]) {
      secondIsNav = isPageNavigation(all[1]) || isBreadcrumb(all[1]);
    }
    if (index === 1 && firstIsNav) {
      priority = true;
    }
    if (index === 2 && firstIsNav && secondIsNav) {
      priority = true;
    }

    if (isBigPlugsBlock(item)) {
      return <BigPlugsBlock key={key} {...item} />;
    }
    if (isBreadcrumb(item)) {
      return <Breadcrumbs key={key} {...item} />;
    }
    if (isContentPlugsBlock(item)) {
      return <ContentPlugsBlock key={key} {...item} />;
    }
    if (isDoubleTextBlock(item)) {
      return <TwoColumnTextBlock key={key} {...item} />;
    }
    if (isDotDigitalForm(item)) {
      return <DotDigitalFormBlock key={key} {...item} />;
    }
    if (isDualImage(item)) {
      return <DoubleImageBlock key={key} {...item} />;
    }
    if (isFactBox(item)) {
      return <FactBox key={key} {...item} />;
    }
    if (isFeedBlock(item)) {
      return <FeedBlock key={key} {...item} />;
    }
    if (isGridOneThree(item)) {
      return <GridOneThree key={key} {...item} />;
    }
    if (isHeroBlock(item)) {
      return <HeroBlock key={key} priority={priority} {...item} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlockNew key={key} {...item} />;
    }
    if (isImageSlider(item)) {
      return <ImageSliderBlock key={key} {...item} />;
    }
    if (isImageTextBlock(item)) {
      return <ImageTextBlock key={key} {...item} />;
    }
    if (isLinkBlock(item)) {
      return <DocumentLinkBlockNew key={key} {...item} />;
    }
    if (isMoreArticlesBlock(item)) {
      return <MoreArticlesBlock key={key} {...item} />;
    }
    if (isOffices(item)) {
      return <Offices {...item} key={key} priority={priority} />;
    }
    if (isPageNavigation(item)) {
      return <PageNavigation key={key} {...item} />;
    }
    if (isPerson(item)) {
      return <PersonBlock key={key} {...item} />;
    }
    if (isPersonInfo(item)) {
      return <PersonInfoBlock key={key} {...item} />;
    }
    if (isProductContentPlugsBlock(item)) {
      return <ProductContentPlugsBlock key={key} {...item} />;
    }
    if (isProductPlugsBlock(item)) {
      return <ProductPlugsBlock key={key} {...item} />;
    }
    if (isQuote(item)) {
      return <QuoteBlockNew key={key} {...item} />;
    }
    if (isRelatedProductsBlock(item)) {
      return <RelatedProductsBlock key={key} {...item} />;
    }
    if (isSliderBlock(item)) {
      return <SliderBlock key={key} priority={priority} {...item} />;
    }
    if (isTextBlock(item)) {
      return <TextBlock key={key} {...item} />;
    }
    if (isVideoBlock(item)) {
      return <VideoBlock key={key} priority={index === 0} {...item} />;
    }
    if (isVideo(item)) {
      return <VideoPlayerBlock key={key} {...item} />;
    }
    if (isVisibleSeoBlock(item)) {
      return <VisibleSeoBlock key={key} {...item} />;
    }
    if (isFindRetailersBlock(item)) {
      return <FindRetailersBlock key={key} {...item} />;
    }

    return null;
  };

  return <ContentAreaStyled>{content.map(contentMapper)}</ContentAreaStyled>;
};
