import Image from 'next/image';
import { CSSProperties } from 'styled-components';

import { PersonFragment } from '@hultafors/snickers/types';

import {
  ImageWrapper,
  Inner,
  Label,
  Name,
  PersonBlockStyled,
  TextWrapper,
} from './PersonBlock.styled';

export interface PersonBlockProps extends Omit<PersonFragment, 'id'> {
  marginTopMobile?: CSSProperties['marginTop'];
}

export const PersonBlock: React.FC<PersonBlockProps> = ({
  image,
  name,
  interviewLabel,
  marginTopMobile,
}) => {
  return (
    <PersonBlockStyled $marginTopMobile={marginTopMobile}>
      <Inner>
        <ImageWrapper>
          <Image
            src={image?.responsiveImage?.src || ''}
            alt={image?.alt || ''}
            fill
            sizes="64px"
            blurDataURL={image?.responsiveImage?.base64 || ''}
            placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
          />
        </ImageWrapper>
        <TextWrapper>
          <Label>{interviewLabel}</Label>
          <Name>{name}</Name>
        </TextWrapper>
      </Inner>
    </PersonBlockStyled>
  );
};
