import { SizeGuideRecord } from '@hultafors/snickers/types';

export const sizeGuideDefault: Partial<SizeGuideRecord> = {
  back: '',
  chooseBrand: '',
  chooseLegLengthText: 'Choose leg type',
  couldNotFindRecommendation:
    'Sorry, we could not find a matching size for you',
  haveTrousersHeader: '',
  headerText: '',
  howToMeasureHeader: '',
  inch: 'inch',
  insideLeg: '',
  lowerWaist: '',
  measurementsBrandTitle: '',
  measurementsGuideTitle: '',
  measurementsJeansTitle: '',
  measurementsOwnPairTitle: '',
  menLabel: '',
  otherBrandHeader: '',
  rememberSizes: 'Remember sizes',
  showMySize: 'Show my size',
  similarProductHeader:
    "Here's a similar product that is available in your size",
  sixSeriesLabel: '6 series size',
  sizeChart: null,
  sizeChartTitle: 'Size chart',
  sizeGuideTitle: '',
  sizeNotAvailableText: 'This garment is not available in your size',
  threeSeriesLabel: '3 series size',
  typeMeasurementsLabel: '',
  waist: '',
  waistLegHeader: '',
  waistLegsOutOfBoundsError: 'Only values between',
  womanLabel: '',
  yourSize: '',
};
