import styled from 'styled-components';

/* import { colors } from '@hultafors/snickers/helpers'; */

export const GaugeStyled = styled.div`
  width: 100%;

  div {
    block-size: 2px;
    float: inline-start;
  }

  .SoFar {
    background-color: #555;
  }

  .ToGo {
    background-color: #aaa;
  }
`;
