import MuxPlayer from '@mux/mux-player-react';
import Image from 'next/image';

import { ImageTextBlockFragment } from '@hultafors/snickers/types';

import { LinkButton } from '../../../LinkButton/LinkButton';
import { Markdown } from '../../../Markdown/Markdown';

import {
  ImageTextBlockStyled,
  Inner,
  LinkWrapper,
  MediaWrapper,
  TextContainer,
  Title,
  TitleWrapper,
} from './ImageTextBlock.styled';

export const ImageTextBlock: React.FC<ImageTextBlockFragment> = ({
  alignMediaLeft,
  textContentCenter,
  textContentRight,
  ctaLabel,
  ctaUrl,
  ctaColor,
  ctaTextColor,
  image,
  textBackgroundColor,
  textContent,
  title,
  video,
}) => {
  return (
    <ImageTextBlockStyled>
      <Inner $alignMediaLeft={!!alignMediaLeft}>
        <TextContainer
          $alignContentCenter={!!textContentCenter}
          $alignContentRight={!!textContentRight}
          $backgroundColor={textBackgroundColor?.hex}
        >
          {title && (
            <TitleWrapper
              $alignContentCenter={!!textContentCenter}
              $alignContentRight={!!textContentRight}
            >
              <Title>{title}</Title>
            </TitleWrapper>
          )}
          {textContent && (
            <Markdown>{textContent.replace(/<\/?p>/g, '')}</Markdown>
          )}
          {ctaLabel && ctaUrl && (
            <LinkWrapper
              $linkColor={ctaColor?.hex}
              $textColor={ctaTextColor?.hex}
              $alignContentCenter={!!textContentCenter}
              $alignContentRight={!!textContentRight}
            >
              <LinkButton text={ctaLabel} url={ctaUrl} />
            </LinkWrapper>
          )}
        </TextContainer>

        {!video && image && (
          <MediaWrapper>
            <Image
              alt={image?.alt || ''}
              src={image?.responsiveImage?.src || image.url}
              blurDataURL={image?.responsiveImage?.base64 || ''}
              fill
            />
          </MediaWrapper>
        )}

        {!image && video?.video?.muxPlaybackId && (
          <MediaWrapper>
            <MuxPlayer
              playbackId={video.video.muxPlaybackId}
              placeholder={video.blurUpThumb ?? undefined}
              streamType="on-demand"
              autoPlay="muted"
              muted
              title={title ?? video.title ?? video.alt ?? undefined}
              style={{ height: '100%', width: '100%' }}
            />
          </MediaWrapper>
        )}
      </Inner>
    </ImageTextBlockStyled>
  );
};
