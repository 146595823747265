import { createContext, useMemo } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings, ParsedProduct } from '@hultafors/shared/types';

import { toggleFavorite } from '@hultafors/snickers/helpers';

export interface FavoritesContextValue {
  favorites: ParsedProduct[];
  toggleFavoriteItem(product: ParsedProduct, isFavorite: boolean): void;
  deleteFavorites(): void;
  isProductFavorite(sku: string): boolean;
}

export const FavoritesContext = createContext<FavoritesContextValue | null>(
  null
);

export interface FavoritesProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
  settings,
}) => {
  const KEY = 'snickers_favorites_v2';
  const favorites = useLocalStorageValue<ParsedProduct[]>(
    `${KEY}-${settings?.market || 'com'}`,
    { defaultValue: [], initializeWithValue: false }
  );

  function toggleFavoriteItem(product: any, isFavorite: boolean) {
    const result = toggleFavorite({
      isFavorite,
      items: favorites.value || [],
      product,
    });
    favorites.set(result);
  }

  function deleteFavorites() {
    favorites.set([]);
  }

  function isProductFavorite(sku: string): boolean {
    if (favorites?.value?.find((x) => x.sku === sku)) {
      return true;
    }
    return false;
  }

  const value: FavoritesContextValue = useMemo(() => {
    return {
      deleteFavorites,
      favorites: favorites.value || [],
      isProductFavorite,
      toggleFavoriteItem,
    };
  }, [favorites.value]);

  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};
