import {
  HTagStyleTypeProp,
  HTagWhiteTextProp,
  StyledHTag,
} from './HTag.styled';

export type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HTagProps {
  className?: string;
  children?: React.ReactNode;
  type?: HTagType;
  styleType?: HTagStyleTypeProp;
  whiteText?: HTagWhiteTextProp;
  center?: boolean;
  uppercase?: boolean;

  margin?: string;
}

export const HTag: React.FC<HTagProps> = ({
  type = 'h1',
  className,
  children,
  styleType,
  whiteText,
  center,
  uppercase,
  margin,
}) => {
  return (
    <StyledHTag
      as={type}
      className={className}
      $styleType={styleType}
      $whiteText={whiteText}
      $center={center}
      $uppercase={uppercase}
      $margin={margin}
    >
      {children}
    </StyledHTag>
  );
};
