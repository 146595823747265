import { SearchField } from '../SearchInput/SearchInput.styled';

import ClearSvg from './clear.svg';
import SearchSvg from './search.svg';
import {
  ClearButton,
  ResultText,
  SearchButton,
  SearchInputChildren,
  SearchInputForm,
  SearchInputFormWrapper,
  SearchInputStyled,
} from './SearchRetailersInput.styled';

interface SearchRetailersInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.FormEventHandler;
  clear(): void;
  value: string;
  placeholder?: string;
  name?: string;
  iconUrl?: string;
  displaySearchMessage?: string;
  children?: React.ReactNode;
  clearLabel: string;
  searchLabel: string;
  className?: string;
  pattern?: string;
  disabled?: boolean;
  transparent?: boolean;
  tall?: boolean;
}

export const SearchRetailersInput: React.FC<SearchRetailersInputProps> = ({
  onSubmit,
  onChange,
  value,
  placeholder,
  clear,
  children,
  displaySearchMessage = '',
  name = 'SearchBox',
  searchLabel,
  pattern,
  transparent,
  tall,
}) => {
  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      clear();
    }
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchInputStyled
      $tall={tall}
      $transparent={transparent}
      onKeyDown={onKeyDown}
    >
      <SearchInputForm onSubmit={onSubmit} autoComplete="off">
        <SearchInputFormWrapper $tall={tall}>
          <SearchField
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            pattern={pattern}
            type="text"
            $transparent={transparent}
          />
          {value && (
            <ClearButton
              onClick={clear}
              $transparent={transparent}
              $show={value.length > 0}
            >
              <ClearSvg
                focusable={false}
                aria-hidden={true}
                width={12}
                height={12}
              />
            </ClearButton>
          )}
          <SearchButton
            $transparent={transparent}
            aria-label={searchLabel}
            onClick={onSubmit}
            disabled={!value.length}
          >
            <SearchSvg
              focusable={false}
              aria-hidden={true}
              width={12}
              height={12}
            />
          </SearchButton>
        </SearchInputFormWrapper>
      </SearchInputForm>
      {children && <SearchInputChildren>{children}</SearchInputChildren>}
      <ResultText>{displaySearchMessage}</ResultText>
    </SearchInputStyled>
  );
};
