import ArrowRightSvg from '../../svg/arrow-right.svg';
import ExternalLinkSvg from '../../svg/external-link.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';
import { Paragraph } from '../Paragraph/Paragraph';

import { StoreItemStyled } from './StoreItem.styled';
export interface StoreItemProps {
  label: string;
  coordinates?: {
    lng?: number | string;
    lat?: number | string;
  };
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  mapLink?: string;
  view_on_map?: string;
  emailAddress?: string;
  phone?: string;
  storeSells?: any[];
  this_store_also_sells?: string;
  website?: string;
  visit_web_site?: string;
  local_web?: string;
  subLabel?: string;
  keyPrefix?: string;
  id?: string;
  $forceCollapsible?: boolean;
}

export const StoreItem: React.FC<StoreItemProps> = (props) => {
  const {
    address,
    zipCode,
    city,
    country,
    mapLink,
    view_on_map,
    emailAddress,
    phone,
    storeSells,
    this_store_also_sells,
    website,
    visit_web_site,
    local_web,
  } = props;

  return (
    <StoreItemStyled>
      <Paragraph className="Address">
        {address && (
          <>
            {address}
            <br />
          </>
        )}
        {zipCode && zipCode}
        {' '}
        {city && city}
        <br />
        {country && country}
      </Paragraph>
      {view_on_map && mapLink && (
        <Paragraph className="ViewOnMap">
          <a
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
            className="LeftIconLink"
          >
            <PlaceTagSvg aria-hidden={true} focusable={false} width={16} />
            {view_on_map}
          </a>
        </Paragraph>
      )}

      {emailAddress || phone
        ? (
          <div className="Contact EmailAndPhoneWrapper">
            {emailAddress && (
              <div className="Email">
                <a href={`mailto:${emailAddress}`} className="LeftIconLink">
                  <MailSvg aria-hidden={true} focusable={false} width={16} />
                  {emailAddress}
                </a>
              </div>
            )}
            {phone && (
              <div className="Phone">
                <a href={`tel:${phone}`} className="LeftIconLink">
                  <PhoneSvg aria-hidden={true} focusable={false} width={16} />
                  {phone}
                </a>
              </div>
            )}
          </div>
          )
        : (
            ''
          )}

      {storeSells && storeSells.length > 0 && (
        <div className="Contact StoreSells">
          <div className="StoresWrapper">
            <label className="storeText">{this_store_also_sells}</label>
            <div className="Stores">
              {storeSells.map((brand, i) => {
                return (
                  <div key={`Brand-${i}`}>
                    <a
                      href={brand.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brand.name}
                    </a>
                    {i < storeSells.length - 1 && (
                      <div className="Separator">|</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {website && (
        <div className="Contact VisitSite">
          <a href={website} target="_blank" rel="noopener noreferrer">
            {visit_web_site && visit_web_site}
            {visit_web_site && (
              <ExternalLinkSvg aria-hidden={true} focusable={false} />
            )}
            {local_web && local_web}
            {local_web && (
              <ArrowRightSvg aria-hidden={true} focusable={false} />
            )}
          </a>
        </div>
      )}
    </StoreItemStyled>
  );
};
