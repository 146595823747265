import styled, { css } from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-inline: 20px;
  padding-block-end: 20px;
`;

export const SquareBox = styled.label<{ $selected?: boolean }>`
  position: relative;
  display: flex;
  height: auto;
  box-sizing: border-box;
  outline: 2px solid transparent;
  outline-offset: -2px;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  ${({ $selected }) =>
    $selected
    && css`
      outline-color: ${colors.highlightBlue};
    `}
`;

export const SquareBoxInput = styled.input`
  appearance: none;
  visibility: hidden;
`;

export const SquareBoxLabel = styled.span`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${fontWeights.medium};
`;
