import styled, { css } from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

import { Input } from '../Input/Input';

interface TallProps {
  $tall?: boolean;
}

interface TransparentProps {
  $transparent?: boolean;
}

interface ShowProps {
  $show?: boolean;
}

export const SearchFieldWrapper = styled.div<TallProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $tall }) => ($tall ? '150px' : 'auto')};
  width: 100%;
  flex: 1;
`;

export const SearchField = styled(Input)<TransparentProps>`
  width: 100%;
  margin: 0;

  input[type='text'] {
    margin: 0;
    padding: 0 40px 0 20px;
    border-radius: 24px;
    font-size: 16px;
    line-height: 3rem;
    color: ${({ $transparent }) =>
      $transparent ? colors.white : colors.workwearBlack};
    background-color: ${({ $transparent }) =>
      $transparent ? 'transparent' : colors.white};
    border: ${({ $transparent }) =>
      $transparent ? '1px solid ' + colors.white : '1px solid ' + colors.gray3};
  }

  input:placeholder-shown {
    border-color: ${({ $transparent }) =>
      $transparent ? colors.gray4 : colors.gray3};
  }

  input::placeholder {
    color: ${({ $transparent }) =>
      $transparent ? colors.gray3 : colors.gray2};
  }
`;

export const SearchFieldForm = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

const buttonStyle = css<TransparentProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  height: 32px;
  width: 32px;
  background-color: ${({ $transparent }) =>
    $transparent ? 'transparent' : colors.gray4};
  border: ${({ $transparent }) =>
    $transparent ? '1px solid ' + colors.white : 'none'};
  border-radius: 50%;
`;

export const ClearButton = styled.button<ShowProps & TransparentProps>`
  ${buttonStyle};
  right: 48px;
  transition: opacity 0.1s ease-out;
  opacity: ${({ $show }) => ($show ? '1' : '0')};
  pointer-events: ${({ $show }) => ($show ? 'inherit' : 'none')};
`;

export const SearchButton = styled.button<TransparentProps>`
  ${buttonStyle};
  right: 8px;

  svg {
    inline-size: 16px;
    block-size: 16px;

    g {
      fill: ${({ $transparent }) =>
        $transparent ? colors.white : colors.workwearBlack};
    }
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.5;
    }
  }
`;
