import styled from 'styled-components';

import {
  fontFamilies,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const ProductColorsContainer = styled.div``;

export const ColorList = styled.div`
  > * {
    margin-inline-end: ${spacing.xSmall};
    margin-block-end: ${spacing.xSmall};
  }
`;

export const ActiveProductColorText = styled.p`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  margin-block: 0 8px;
  font-size: 14px;
`;
