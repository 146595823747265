import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    padding-block-end: 0;

    img {
      position: static;
      block-size: 650px;
    }
  }
`;

export const ImageSliderStyled = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 0 45px;
  aspect-ratio: 16 / 9;

  .SingleItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    block-size: 650px;
    margin: 0 0 45px;
  }

  .Counter {
    display: inline-block;
    position: absolute;
    inset-inline: 50% auto;
    transform: translateX(-50%);
    inset-block-end: -44px;
    color: ${colors.gray2};
    font-size: 14px;
    block-size: 44px;
    line-height: 44px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      inset-block-end: -26px;
      inset-inline: auto 0;
      transform: none;
    }

    .ActiveSlide {
      font-size: 14px;
      color: ${colors.workwearBlack};
    }
  }

  .slick-prev,
  .slick-next {
    inline-size: 44px;
    block-size: 44px;
    position: absolute;
    z-index: 1;
    inset-block-end: -44px;
    background: url('/assets/gfx/next.svg') center center no-repeat;

    /* background-position: right; */
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.desktop}) {
      inline-size: 44px;
      block-size: 44px;
      inset-block: 48% auto;
      opacity: 0.4;
    }
  }

  .slick-prev {
    inset-inline-start: 8px;
    transform: rotate(180deg);
    background-size: 16px 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      background-size: 46px 46px;
      inset-inline-start: -75px;
    }
  }

  .slick-next {
    inset-inline-end: 8px;
    background-size: 16px 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      background-size: 46px 46px;
      inset-inline-end: -75px;
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    block-size: 100%;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    block-size: 100%;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: block;
    block-size: 100%;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: inline-start;
    block-size: 100%;
    min-block-size: 1px;

    > div {
      block-size: 100%;
    }
  }

  [dir='rtl'] .slick-slide {
    float: inline-end;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    block-size: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
