export const colors = {
  allroundworkOrange: '#F18A1C',
  allroundworkOrange2: '#621A28',
  black: 'rgb(0, 0, 0)',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  flexiworkGreen: '#78A73F',
  flexiworkGreen2: '#284A21',
  gray1: '#5E5A59',
  gray2: '#747070',
  gray3: '#C4C2C3',
  gray4: '#F2F2F2',
  gray5: '#FAFAFA',
  grayBtnHover: '#E9E8E8',
  highlightBlue: '#4068FF',
  liteworkBlue: '#0098BE',
  liteworkBlue2: '#005059',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  protecworkRed: '#BA0C2F',
  protecworkRed2: '#A30A29',
  ruffworkBrown: '#664B38',
  ruffworkBrown2: '#522C00',
  solidBlack: '#000',
  solidBlackHover: '#111',
  toggleGray: '#DEDEDE',
  warning: '#FD2020',
  white: 'rgb(255, 255, 255)',
  workwearBlack: '#232120',
  workwearBlack2: '#000000',
  workwearYellow: '#FFD600',
  workwearYellow2: '#F3CC00',
} as const;

export type Colors = keyof typeof colors;
