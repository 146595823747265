import { StyledDesktopMenuItem } from './desktop-menu-item.styled';

export interface DesktopMenuItemProps {
  active?: boolean;
  transparent?: boolean;
  children?: React.ReactNode;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

export const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({
  active,
  transparent,
  children,
  href,
  onClick,
  className,
}) => {
  return (
    <StyledDesktopMenuItem
      data-test-id="DesktopMenuItem"
      href={href}
      $active={active}
      $transparent={transparent}
      onClick={onClick}
      className={className}
    >
      {children}
    </StyledDesktopMenuItem>
  );
};
