import { TextButtonStyled } from './TextButton.styled';

export interface TextButtonProps {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  className?: string;
  iconUrl?: string;
  iconLeft?: boolean;
  center?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  center,
  iconLeft,
  iconUrl,
  children,
  className,
  onClick,
}) => {
  return (
    <TextButtonStyled
      $center={center}
      $iconLeft={iconLeft}
      $iconUrl={iconUrl}
      onClick={onClick}
      className={className}
    >
      {children}
    </TextButtonStyled>
  );
};
