import { ProductDetailsValue } from '@hultafors/shared/types';

import { ProductImage } from '@hultafors/snickers/types';

/**
 * @desc Super ugly hack matching color by color name embedded in image url
 * @param images {array} [{url | string}]
 * @param color {object} { text | string }
 * @returns
 */
export const findImagesByColor = (
  images: ProductImage[],
  color?: ProductDetailsValue | null,
) => {
  let selectedImages: ProductImage[] = images;
  const colorCode = color?.text.match(/\d+/g)?.[0]; // get numbers from color string
  if (colorCode) {
    selectedImages = images.filter((image) => {
      return image.sku && image.url.includes(`${image.sku}-${colorCode}`);
    });
  }

  return selectedImages?.length ? selectedImages : images;
};
