import { InfoBoxStyled } from './InfoBox.styled';

export interface InfoBoxProps {
  title?: string;
  icon?: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({ icon, title }) => {
  return (
    <InfoBoxStyled>
      {icon && <img src={icon} alt={title + ' icon'} />}
      <span>{title}</span>
    </InfoBoxStyled>
  );
};
