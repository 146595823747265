import { UnitsMinMax } from '@hultafors/shared/types';

import {
  AggregatedJeansSize,
  JeansSize,
  JeansSizeLists,
  Leg,
  MinMaxSizes,
  ProductSize,
  RegularSize,
  SizeCategory,
  SizeConversion,
  SizeItem,
  SizeLists,
  SizeSelection,
  SizeValidation,
  WaistList,
} from '@hultafors/snickers/types';

import {
  DEFAULT_RECOMMENDATION,
  DEFAULT_SIZE_LISTS,
  jeansSizesMen,
  jeansSizesMen3,
  jeansSizesWomen,
  SIZE_GUIDE_STEPS,
  sizeConversions,
  trousersMen,
  trousersMenWaists,
  trousersWoman,
  trousersWomanWaists,
} from './data';
import { GENDERS, getCategoryFromSku, SIZE_CATEGORIES } from './sizes';

export const MEASURE_TYPES: Record<string, string> = {
  BODY: 'body',
  JEANS: 'jeans',
  TROUSERS: 'garment',
};

export class Guide {
  sku: string;
  sizeCategory?: SizeCategory;

  constructor(sku: string) {
    this.sku = sku;
    if (sku) {
      this.sizeCategory = getCategoryFromSku(this.sku);
    }
  }

  getRecommendationsFromMeasure = (
    waistSizeParam: string | number,
    legSizeParam: string | number,
    unit: keyof UnitsMinMax,
    minMax: MinMaxSizes,
    category: keyof WaistList = 'body'
  ) => {
    let allRecommendations: SizeConversion | undefined;

    const waistSize: number = parseInt(`${waistSizeParam}`);
    const legSize = parseInt(`${legSizeParam}`);
    const sizeType: keyof SizeConversion | undefined =
      this.sku && this.sizeCategory?.gender === GENDERS['WOMEN']
        ? ('women' as keyof SizeConversion)
        : undefined;

    if (
      waistSize &&
      legSize &&
      waistSize >= minMax.waist[unit].min &&
      legSize >= minMax.legs[unit].min &&
      waistSize <= minMax.waist[unit].max &&
      legSize <= minMax.legs[unit].max
    ) {
      if (this.sku && this.sizeCategory) {
        const lists = this.getSizeListsFromSizeCategory(this.sizeCategory);

        const recommendedSize = this.getSizeFromWaistLeg(
          category,
          waistSize,
          legSize,
          unit,
          lists.sizeList,
          lists.waistList
        );
        allRecommendations = this.getAllRecommendedSizes(
          recommendedSize,
          sizeType
        );
        if (allRecommendations) {
          allRecommendations.current =
            this.getSizeFromRecommendation(allRecommendations);
        }
      } else {
        // Stand alone mode, trying mens size first
        const recommendedSize = this.getSizeFromWaistLeg(
          category,
          waistSize,
          legSize,
          unit,
          trousersMen,
          trousersMenWaists
        );

        if (!recommendedSize) {
          // Try women sizes
          const recommendedSize = this.getSizeFromWaistLeg(
            category,
            waistSize,
            legSize,
            unit,
            trousersWoman,
            trousersWomanWaists
          );
          allRecommendations = this.getAllRecommendedSizes(
            recommendedSize,
            sizeType
          );
        } else {
          allRecommendations = this.getAllRecommendedSizes(recommendedSize);
        }
      }
    }

    return allRecommendations;
  };

  getSizeFromWaistLeg = (
    category: keyof WaistList,
    waistSize: number,
    legSize: number,
    unit: keyof UnitsMinMax,
    sizeList: SizeItem[],
    waistList: WaistList
  ): number | undefined => {
    let waistIndex: number = waistList[category].findIndex(
      (item) => waistSize >= item[unit].min && waistSize <= item[unit].max
    );

    if (waistIndex === -1) {
      // Remove this if we get a span on inch leg data
      waistIndex = this.findClosestIndex(waistList, waistSize, category, unit);
    }

    let sizeCategory = sizeList.find(
      (item: SizeItem) =>
        item.leg?.[category][unit] &&
        legSize >= item.leg[category][unit].min &&
        legSize <= item.leg[category][unit].max
    );

    if (!sizeCategory) {
      // Remove this if we get a span on inch leg data
      sizeCategory = this.findClosestSizeCategory(
        sizeList,
        legSize,
        category,
        unit
      );
    }

    return sizeCategory?.sizes?.[waistIndex]?.size;
  };

  findClosestIndex = (
    waistList: WaistList,
    waistSize: number,
    category: keyof WaistList,
    unit: keyof UnitsMinMax
  ): number => {
    const values: UnitsMinMax[] = waistList[category];
    for (let index = 0, l = values.length; index < l; index++) {
      const valueUnit = values?.[index]?.[unit];
      if (
        valueUnit &&
        typeof valueUnit.min === 'number' &&
        valueUnit.min > waistSize
      ) {
        if (index > 0) {
          const diff1 = Math.abs(valueUnit.min - waistSize);
          const diff2 = Math.abs(
            (values[index - 1]?.[unit].min || 0) - waistSize
          );
          return diff1 < diff2 || diff1 === diff2 ? index : index - 1;
        } else {
          // First item is already larger, it´s the best bet we have
          return index;
        }
      }
    }
    return -1;
  };

  findClosestSizeCategory = (
    sizeList: SizeItem[],
    legSize: number,
    category: keyof Leg,
    unit: keyof UnitsMinMax
  ): SizeItem | undefined => {
    // Inch does not have a span on legs, finding the closest one
    for (let index = 0, l = sizeList.length; index < l; index++) {
      const minLeg = sizeList[index]?.leg?.[category][unit].min || 0;
      if (typeof minLeg !== 'undefined' && minLeg > legSize) {
        if (index > 0) {
          const minLegPrev =
            sizeList[index - 1]?.leg?.[category][unit].min || 0;
          const diff1 = Math.abs(minLeg - legSize);
          const diff2 =
            typeof minLegPrev !== 'undefined'
              ? Math.abs(minLegPrev - legSize)
              : diff1;

          if (diff1 < diff2 || diff1 === diff2) {
            return sizeList[index];
          } else {
            return sizeList[index - 1];
          }
        } else {
          // First item is already larger, it´s the best bet we have
          return sizeList[index];
        }
      }
    }
    return undefined;
  };

  getSizeListsFromSizeCategory = (
    sizeCategory: SizeCategory
  ): { sizeList: SizeItem[]; waistList: WaistList } => {
    switch (sizeCategory.gender) {
      case GENDERS['MEN']:
        return {
          sizeList: trousersMen,
          waistList: trousersMenWaists,
        };
      case GENDERS['WOMEN']:
        return {
          sizeList: trousersWoman,
          waistList: trousersWomanWaists,
        };
      default:
        return {
          sizeList: trousersMen,
          waistList: trousersMenWaists,
        };
    }
  };

  getRecommendationsFromOtherBrand = (size: number) => {
    if (size) {
      const allRecommendations = this.getAllRecommendedSizes(size);
      if (this.sku) {
        const current =
          allRecommendations &&
          this.getSizeFromRecommendation(allRecommendations);
        if (allRecommendations && current) {
          allRecommendations.current = current;
        }
      } // Stand alone mode
      return allRecommendations;
    }
    return null;
  };

  getSizeFromRecommendation = (
    recommendations: SizeConversion
  ): RegularSize | undefined => {
    // TODO extend with shorts, shorts3, pirates

    if (this.sizeCategory?.gender === GENDERS['MEN']) {
      if (this.sizeCategory.name === SIZE_CATEGORIES['TROUSERS']) {
        return {
          name: 'size',
          size: recommendations.size,
        };
      } else {
        return {
          name: 'size3',
          size: recommendations.size3,
        };
      }
    } else if (
      this.sizeCategory?.gender === GENDERS['WOMEN'] &&
      this.sizeCategory.name === SIZE_CATEGORIES['TROUSERS']
    ) {
      return {
        name: 'women',
        size: recommendations.women,
      };
    }
    return undefined;
  };

  /**
   * @desc Gets object with 6 and 3-series trousers, woman trousers, shorts, 3-series shorts and pirates sizes from 6-series size
   * @param int size
   * @param int sizeType what size type to convert from, default is men 6-series
   * @return object
   */
  getAllRecommendedSizes = (
    size?: number,
    sizeType: keyof SizeConversion = 'size'
  ): SizeConversion | undefined => {
    if (size) {
      const recommendation = sizeConversions.find(
        (item) => item[sizeType] === size
      );
      if (!recommendation) {
        // Sometimes with weird combinations we dont have a match here (136 for example) or Women size 116
        const conversion = { ...DEFAULT_RECOMMENDATION };

        conversion[sizeType] = size;
        return conversion;
      } else {
        return recommendation;
      }
    }
    return undefined;
  };

  getMinMaxWaistLegs = (currentStep: number) => {
    let category: keyof WaistList | keyof SizeItem['leg'];
    switch (currentStep) {
      case SIZE_GUIDE_STEPS['MEASURE_BODY']:
        category = MEASURE_TYPES['BODY'] as keyof WaistList;
        break;
      case SIZE_GUIDE_STEPS['MEASURE_TROUSERS']:
        category = MEASURE_TYPES['TROUSERS'] as keyof WaistList;
        break;
      default:
        return null;
    }

    if (category) {
      if (this.sku && this.sizeCategory) {
        const lists = this.getSizeListsFromSizeCategory(this.sizeCategory);

        return this.getMinMaxHelper(lists, category);
      } else {
        // Guide is in stand alone mode
        return this.getMinMaxHelperStandAlone(category);
      }
    }
    return null;
  };

  getMinMaxHelper = (
    lists: SizeLists,
    category: keyof WaistList | keyof SizeItem['leg']
  ) => {
    const waistListCategory = lists.waistList[category];
    const empty = {
      cm: { max: 0, min: 0 },
      inch: { max: 0, min: 0 },
    };
    const waistMin = waistListCategory[0] || empty;
    const waistMax = waistListCategory[waistListCategory.length - 1] || empty;
    const legsMin = lists.sizeList[0]?.leg?.[category] || empty;
    const legsMax =
      lists.sizeList[lists.sizeList.length - 1]?.leg?.[category] || empty;

    return this.reduceMinMax(waistMin, waistMax, legsMin, legsMax);
  };

  getMinMaxHelperStandAlone = (
    category: keyof WaistList | keyof SizeItem['leg']
  ): MinMaxSizes => {
    const empty = {
      cm: { max: 0, min: 0 },
      inch: { max: 0, min: 0 },
    };
    const waistMin = trousersWomanWaists[category][0] || empty;
    const legsMin: UnitsMinMax = trousersWoman[0]?.leg?.[category] || empty;
    const waistMax =
      trousersMenWaists[category][trousersMenWaists[category].length - 1] ||
      empty;
    const legsMax: UnitsMinMax =
      trousersMen[trousersMen.length - 1]?.leg?.[category] || empty;

    return this.reduceMinMax(waistMin, waistMax, legsMin, legsMax);
  };

  reduceMinMax = (
    waistMin: UnitsMinMax,
    waistMax: UnitsMinMax,
    legsMin: UnitsMinMax,
    legsMax: UnitsMinMax
  ): MinMaxSizes => {
    return {
      legs: {
        cm: {
          max: legsMax.cm.max,
          min: legsMin.cm.min,
        },
        inch: {
          max: legsMax.inch.max,
          min: legsMin.inch.min,
        },
      },
      waist: {
        cm: {
          max: waistMax.cm.max,
          min: waistMin.cm.min,
        },
        inch: {
          max: waistMax.inch.max,
          min: waistMin.inch.min,
        },
      },
    };
  };

  getRecommendedSizeForProduct = (
    recommendations: SizeConversion,
    productSizes: ProductSize[]
  ): ProductSize | undefined => {
    const currentSize = this.getSizeFromRecommendation(recommendations);
    if (currentSize?.size) {
      return productSizes.find(
        (size) => parseInt(size.value, 10) === currentSize.size
      );
    }
    return undefined;
  };

  getSizesForJeansGuide = (): JeansSizeLists => {
    const sizeLists: JeansSizeLists = { ...DEFAULT_SIZE_LISTS };
    if (this.sku) {
      // TODO lazy load sizes and set
      switch (this.sizeCategory?.gender) {
        case GENDERS['MEN']:
          if (this.sizeCategory.name === SIZE_CATEGORIES['TROUSERS']) {
            sizeLists.list = jeansSizesMen;
          } else {
            sizeLists.list = jeansSizesMen3;
          }
          break;
        case GENDERS['WOMEN']:
          sizeLists.list = jeansSizesWomen;
          break;
        default:
          sizeLists.list = jeansSizesMen;
      }

      return sizeLists;
    } else {
      sizeLists.men = jeansSizesMen;
      sizeLists.men3 = jeansSizesMen3;
      sizeLists.women = jeansSizesWomen;

      // Aggregate men and women sizes
      sizeLists.list = this.aggregateJeansLists(sizeLists);

      return sizeLists;
    }
  };

  aggregateJeansLists = (sizeLists: JeansSizeLists): AggregatedJeansSize[] => {
    const result: AggregatedJeansSize[] = [];

    const womenLeg = sizeLists.women.map((x) => x.leg);
    const menLeg = sizeLists.men.map((x) => x.leg);

    const combineLeg = [...womenLeg, ...menLeg].sort();

    // 3-series is contained within men and women trousers
    combineLeg.map((item) => {
      const men = sizeLists.men.find(({ leg }) => leg === item);
      if (men) {
        const newSize: AggregatedJeansSize = {
          ...men,
          gender: GENDERS['MEN'],
          sizeCategory: SIZE_CATEGORIES['TROUSERS'],
        };
        result.push(newSize);
      } else {
        const women = sizeLists.women.find((x) => x.leg === item);
        if (women) {
          const newSize: AggregatedJeansSize = {
            ...women,
            gender: GENDERS['WOMEN'],
            sizeCategory: SIZE_CATEGORIES['TROUSERS'],
          };
          result.push(newSize);
        }
      }
    });
    return result;
  };

  getRecommendationsFromJeans = (
    waistSize: string | number,
    legSize: string | number,
    sizeLists: JeansSizeLists
  ) => {
    let allRecommendations = null;

    waistSize = parseInt(`${waistSize}`);
    legSize = parseInt(`${legSize}`);

    if (waistSize && legSize) {
      if (this.sku) {
        const recommendedSize = this.getJeansSizeFromWaistLeg(
          waistSize,
          legSize,
          sizeLists.list
        );

        allRecommendations = this.getAllRecommendedSizes(recommendedSize);

        if (allRecommendations) {
          allRecommendations.current =
            this.getSizeFromRecommendation(allRecommendations);
        }
      } else {
        // Stand alone mode, trying mens 6-series first
        let recommendedSize = this.getJeansSizeFromWaistLeg(
          waistSize,
          legSize,
          sizeLists.men
        );

        if (!recommendedSize) {
          // Try trousers 3-series
          recommendedSize = this.getJeansSizeFromWaistLeg(
            waistSize,
            legSize,
            sizeLists.men3
          );

          if (!recommendedSize) {
            // Try women sizes
            recommendedSize = this.getJeansSizeFromWaistLeg(
              waistSize,
              legSize,
              sizeLists.women
            );

            if (recommendedSize) {
              allRecommendations = this.getAllRecommendedSizes(
                recommendedSize,
                'women'
              );
            }
          } else {
            allRecommendations = this.getAllRecommendedSizes(
              recommendedSize,
              'size3'
            );
          }
        } else {
          allRecommendations = this.getAllRecommendedSizes(recommendedSize);
        }
      }
    }

    return allRecommendations;
  };

  getJeansSizeFromWaistLeg = (
    waistSize: number,
    legSize: number,
    sizeList: JeansSize[]
  ): number | undefined => {
    const legList = sizeList.find((x) => x.leg === legSize);
    return (
      legList?.waist?.find((item) => item.value === waistSize)?.size ||
      undefined
    );
  };

  validateScreen = (
    screens: typeof SIZE_GUIDE_STEPS,
    currentStep: number,
    minMax: MinMaxSizes,
    selection: SizeSelection,
    validation: SizeValidation
  ) => {
    const currentValidation = { ...validation };

    switch (currentStep) {
      case screens['MEASURE_BODY']:
      case screens['MEASURE_TROUSERS']: {
        const waistSize: number = parseInt(`${selection.waistSize}`);
        const legSize: number = parseInt(`${selection.legSize}`);

        if (
          waistSize < minMax.waist[selection.unit].min ||
          waistSize > minMax.waist[selection.unit].max
        ) {
          currentValidation.waistError = true;
        } else {
          currentValidation.waistError = false;
        }
        if (
          legSize < minMax.legs[selection.unit].min ||
          legSize > minMax.legs[selection.unit].max
        ) {
          currentValidation.legsError = true;
        } else {
          currentValidation.legsError = false;
        }
        break;
      }
    }

    // Setting global error is at least one error exist (avoiding checking global error)
    let isError = false;
    for (const entry of Object.entries(currentValidation)) {
      if (entry[0] !== 'error' && entry[1]) {
        isError = true;
      }
    }
    currentValidation.error = isError;

    return currentValidation;
  };
}
