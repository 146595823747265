import Image from 'next/image';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/snickers/helpers';

import { H4 } from '../H4/H4';

export const ImageWrapper = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  margin-inline-end: 16px;

  img {
    max-inline-size: 24px;
    max-block-size: 24px;
  }
`;

export const TitleImage = styled(Image)`
  max-width: 24px;
  max-height: 24px;
  position: absolute;
  inset-inline-start: 0;
`;

export const Title = styled(H4)<{ $certifications?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.body};
  line-height: 22px;
  margin-block-start: 16px;
  padding-inline-start: 40px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    font-size: ${fontSizes.m};
  }

  ${({ $certifications }) =>
    $certifications
    && css`
      margin-block-end: 12px;
    `}
`;

export const ImageAttributeStyled = styled.div``;

const attributeStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block: ${spacing.regular} ${spacing.regular};
  padding-inline-end: ${spacing.regular};
  box-sizing: border-box;

  p {
    white-space: pre-wrap;
    margin-block: 0;
    font-size: ${fontSizes.body};

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.m};
    }
  }
`;

interface AttributeProps {
  $type?: string | null;
}
export const Attribute = styled.div<AttributeProps>`
  ${attributeStyle};
  ${({ $type }) =>
    $type === 'Certifications'
    && css`
      padding-inline-start: 40px;
      margin-block: 0;

      &:last-of-type {
        margin-block-end: ${spacing.regular};
      }

      p {
        font-size: ${fontSizes.body};
        margin-block-end: 8px;

        @media screen and (min-width: ${breakpoints.desktop}) {
          font-size: ${fontSizes.m};
        }
      }
    `}
  ${({ $type }) =>
    $type === 'Care'
    && css`
      margin-block-end: 8px;

      &:last-of-type {
        margin-block-end: 24px;
      }
    `}
  ${({ $type }) =>
    $type === 'Cobranding'
    && css`
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid ${colors.gray4};
      margin-block-start: ${spacing.regular};

      ${ImageWrapper} {
        inline-size: auto;
        block-size: auto;
        margin-block-end: ${spacing.regular};

        img {
          block-size: 40px;
          max-inline-size: 100%;
          max-block-size: 100%;
        }
      }
    `}

    ${({ $type }) =>
    $type !== 'Cobranding'
    && css`
      &:first-of-type {
        margin-block-start: 0;
      }
    `}
`;

export const DocumentWrapper = styled.a`
  ${attributeStyle};

  a:link {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${colors.gray1};
    }
  }
`;
