import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

const paragraph = css<{ $inverted?: boolean }>`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  color: ${({ $inverted }) =>
    $inverted ? colors.white : colors.workwearBlack};
  margin: 0;
  font-size: 14px;
  ${({ $inverted }) =>
    $inverted
    && css`
      text-shadow: 0 0 10px black;
    `};
`;

export const GenericPlugStyled = styled.article`
  position: relative;
`;

export const Inner = styled(RouterLink)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.Col-2 {
    @media screen and (min-width: ${breakpoints.desktop}) {
      .Content {
        .Title {
          font-size: 30px;
          line-height: 42px;
        }

        .Intro {
          font-size: 16px;
          max-inline-size: 60%;
        }
      }
    }
  }
`;

export const BackgroundColors = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.gray5};

  &::after {
    content: '';
    block-size: 100%;
    background-image: url('/svg/color-puff-bg.svg');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
`;

export const Title = styled.p<{ $inverted?: boolean; $large?: boolean }>`
  font-weight: ${fontWeights.medium};
  font-family: ${fontFamilies.mikro};
  color: ${({ $inverted }) =>
    $inverted ? colors.white : colors.workwearBlack};
  font-size: ${({ $large }) => ($large ? '30px' : '20px')};
  line-height: ${({ $large }) => ($large ? '42px' : '26px')};
  margin: 12px 0 8px;
  letter-spacing: 0.2px;
  max-width: 400px;
  ${({ $inverted }) =>
    $inverted
    && css`
      text-shadow: 0 0 10px black;
    `};

  & + svg {
    margin-block-start: 8px;
  }
`;

export const Intro = styled.p<{ $inverted?: boolean; $large?: boolean }>`
  ${paragraph};
  font-size: ${({ $large }) => ($large ? '16px' : '14px')};
  line-height: 20px;
  max-width: 75%;
  margin-block-end: 16px;
  font-weight: ${fontWeights.normal};
  ${({ $large }) =>
    $large
    && css`
      max-width: 60%;
    `};
`;

export const Name = styled.p<{ $inverted?: boolean }>`
  ${paragraph};
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    inset-block-start: 40%;
    inset-inline-start: 0;
    inline-size: 30px;
    block-size: 1px;
    margin: 0 ${spacing.tiny} 0 0;
    background-color: ${({ $inverted }) =>
      $inverted ? colors.white : colors.workwearBlack};
    ${({ $inverted }) =>
      $inverted
      && css`
        box-shadow: 0 0 4px black;
      `};
  }
`;

export const Label = styled.p<{ $inverted?: boolean }>`
  ${paragraph};
`;

export const Content = styled.div<{ $inverted?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${({ $inverted }) =>
    $inverted ? 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4))' : ''};
  padding: 16px 16px 24px;

  svg {
    g {
      fill: ${({ $inverted }) =>
        $inverted ? colors.white : colors.workwearBlack};
    }

    path.Line {
      transform: scaleX(1);
      transition: transform 0.3s ease;
    }

    ${GenericPlugStyled}:hover & {
      path.Line {
        transform: scaleX(1.2);
      }
    }
  }
`;
