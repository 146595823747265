import Image from 'next/image';

import { ImageFragment } from '@hultafors/snickers/types';

import {
  ImageContainer,
  StyledNoImageDiv,
  StyledSmallContentPlug,
  StyledTextBoxCTA,
  StyledTextBoxCTAWrapper,
  StyledTextBoxText,
  StyledTextBoxTitle,
  StyledTextBoxWrapper,
} from './SmallContentPlug.styled';

export interface SmallContentPlugProps {
  title?: string;
  url?: string;
  image?: ImageFragment;
  text?: string;
  CTAText?: string;
  className?: string;
}

export const SmallContentPlug: React.FC<SmallContentPlugProps> = ({
  title,
  url,
  image,
  text,
  CTAText,
  className,
}) => {
  return (
    <StyledSmallContentPlug href={url || ''} className={className}>
      {image
        ? (
          <ImageContainer>
            <Image
              src={image.responsiveImage?.src || ''}
              alt={image.alt || ''}
              fill
              blurDataURL={image.responsiveImage?.base64 || ''}
              placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              style={{ objectFit: 'contain' }}
            />
          </ImageContainer>
          )
        : (
          <StyledNoImageDiv />
          )}
      <StyledTextBoxWrapper>
        <StyledTextBoxTitle
          type="h3"
          styleType="header4"
          className="TextBoxTitle"
        >
          {title}
        </StyledTextBoxTitle>
        <StyledTextBoxText>{text ? text : '\u00A0'}</StyledTextBoxText>
        <StyledTextBoxCTAWrapper>
          <StyledTextBoxCTA>{CTAText}</StyledTextBoxCTA>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.87789 3.58646C5.68263 3.3912 5.68263 3.07462 5.87789 2.87936C6.07315 2.68409 6.38974 2.68409 6.585 2.87936L11.585 7.87936C11.7803 8.07462 11.7803 8.3912 11.585 8.58646L6.585 13.5865C6.38974 13.7817 6.07315 13.7817 5.87789 13.5865C5.68263 13.3912 5.68263 13.0746 5.87789 12.8794L10.5243 8.23291L5.87789 3.58646Z"
              fill="#232120"
            />
          </svg>
        </StyledTextBoxCTAWrapper>
      </StyledTextBoxWrapper>
    </StyledSmallContentPlug>
  );
};
