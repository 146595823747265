import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/snickers/helpers';

interface GridColumnGap {
  columns?: number;
  breakpoint?: Breakpoint;
  columnGap?: number;
  start?: number;
  display?: CSSProperties['display'];
}

export type GridColumnsProp =
  | GridColumnGap[]
  | number
  | 'auto-fit'
  | 'auto-fill';

export type GridColumnGapProp = GridColumnGap[] | Spacing | number;

export type GridRowGap = Spacing | number;

interface GridStyledProps {
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $columnGap?: GridColumnGapProp;
  $columns?: GridColumnsProp;
  $minColumnWidth?: string;
  $rowGap?: GridRowGap;
}

function getColumnGap({ $columnGap }: GridStyledProps) {
  if (typeof $columnGap === 'object') {
    return $columnGap.map(({ breakpoint, columnGap }) => {
      if (!breakpoint) {
        return css`
          grid-column-gap: ${columnGap}px;
        `;
      }
      return css`
        @media screen and (min-width: ${breakpoints[breakpoint]}) {
          grid-column-gap: ${columnGap}px;
        }
      `;
    });
  }
  if (typeof $columnGap === 'number') {
    return css`
      grid-column-gap: ${$columnGap}px;
    `;
  }
  if ($columnGap) {
    return css`
      grid-column-gap: ${spacing[$columnGap]};
    `;
  }
  return css``;
}

function getColumns({ $columns, $minColumnWidth }: GridStyledProps) {
  if (typeof $columns === 'object') {
    return $columns.map(({ breakpoint, columns }) => {
      if (!breakpoint) {
        return css`
          grid-template-columns: repeat(${columns}, minmax(0, 1fr));
        `;
      }
      return css`
        @media screen and (min-width: ${breakpoints[breakpoint]}) {
          grid-template-columns: repeat(${columns}, minmax(0, 1fr));
        }
      `;
    });
  }
  return css`
    grid-template-columns: repeat(
      ${$columns},
      ${$minColumnWidth ? `minmax(${$minColumnWidth}, 1fr)` : 'minmax(0, 1fr)'}
    );
  `;
}

function getRowGap({ $rowGap }: GridStyledProps) {
  if (typeof $rowGap === 'number') {
    return css`
      row-gap: ${$rowGap}px;
    `;
  }
  if ($rowGap) {
    return css`
      row-gap: ${spacing[$rowGap]};
    `;
  }
  return css``;
}
export const GridStyled = styled.div<GridStyledProps>`
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  display: grid;
  grid-auto-flow: row;
  justify-content: ${({ $center }) => ($center ? 'center' : 'inherit')};
  margin: ${({ $margin }) => $margin};
  max-width: ${({ $maxWidth }) => $maxWidth};

  ${getColumnGap};
  ${getColumns};
  ${getRowGap};
`;
