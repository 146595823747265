import MailSvg from '../../svg/mail.svg';
import NextSvg from '../../svg/next.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';
import { AccordionOld } from '../AccordionOld/AccordionOld';
import { Paragraph } from '../Paragraph/Paragraph';

import { StoreListStyled } from './StoreList.styled';

export { StoreListStyled } from './StoreList.styled';

export interface StoreListProps {
  stores: any[];
  pageContent: any;
  selectItem(...args: any[]): unknown;
  selectedItem?: any;
}

export const StoreList: React.FC<StoreListProps> = ({
  stores,
  pageContent,
  selectItem,
  selectedItem,
}) => {
  const keyPrefix = 'Store';

  return (
    <StoreListStyled>
      {stores.length > 0 && (
        <AccordionOld
          selectItem={selectItem}
          selectedItemId={selectedItem ? selectedItem.id : 0}
          keyPrefix={keyPrefix}
        >
          {stores.map((item: any, index) => {
            return (
              <li title={item.companyName} key={`Store-${index}`} id={item.id}>
                <Paragraph className="Address">
                  {item.address1}
                  <br />
                  {`${item.zipCode ? item.zipCode : ''} ${
                    item.city ? item.city : ''
                  }`}
                  <br />
                  {item.country ? item.country : ''}
                </Paragraph>
                <Paragraph className="ViewOnMap">
                  <a
                    href={item.mapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="LeftIconLink"
                  >
                    <PlaceTagSvg aria-hidden={true} focusable={false} />
                    {pageContent.ViewOnMap}
                  </a>
                </Paragraph>

                <div className="Contact EmailAndPhoneWrapper">
                  {item.emailAddress && (
                    <div className="Email">
                      <a
                        href={`mailto:${item.emailAddress}`}
                        className="LeftIconLink"
                      >
                        <MailSvg aria-hidden={true} focusable={false} />
                        {item.emailAddress}
                      </a>
                    </div>
                  )}
                  {item.phone && (
                    <div className="Phone">
                      <a href={`tel:${item.phone}`} className="LeftIconLink">
                        <PhoneSvg aria-hidden={true} focusable={false} />
                        {item.phone}
                      </a>
                    </div>
                  )}
                </div>

                {item.storeSells && item.storeSells.length > 0 && (
                  <div className="Contact StoreSells">
                    <div className="StoresWrapper">
                      <label>{pageContent.StoreAlsoSells}</label>
                      <div className="Stores">
                        {item.storeSells.map((brand: any, i: number) => {
                          return (
                            <div key={`Brand-${i}`}>
                              <a
                                href={brand.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {brand.name}
                              </a>
                              {i < item.storeSells.length - 1 && (
                                <div className="Separator">|</div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {item.webSite && (
                  <div className="Contact VisitSite">
                    <a
                      href={item.webSite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pageContent.VisitWebsite}
                      <NextSvg aria-hidden={true} focusable={false} />
                    </a>
                  </div>
                )}
              </li>
            );
          })}
        </AccordionOld>
      )}
    </StoreListStyled>
  );
};
