import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const DoubleImageStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
  width: 100%;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    inline-size: 50%;
  }
`;
