import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const TextSectionTitle = styled.p`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  font-weight: ${fontWeights.medium};
  margin-block: 0 ${spacing.tinier};
  font-size: ${fontSizes.body};
`;

interface TextSectionStyledProps {
  $left?: boolean;
}

export const TextSectionWrapper = styled.div<TextSectionStyledProps>`
  padding: ${spacing.large} ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: ${spacing.huge} ${spacing.medium};
  }

  border-top: 1px solid ${colors.gray4};
  margin-block-start: ${spacing.medium};
  width: 100%;
  text-align: ${({ $left }) => ($left ? 'left' : 'center')};
`;

export const TextSectionContent = styled.div<TextSectionStyledProps>`
  max-width: 605px;
  margin-inline-end: auto;
  margin-left: ${({ $left }) => ($left ? '0' : 'auto')};
`;
