import styled from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
} from '@hultafors/snickers/helpers';

export const ProductPriceStyled = styled.div`
  position: relative;
  font-family: ${fontFamilies.mikro};
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 1px;
  margin-block-end: 24px;
`;
export const SmallInfo = styled.span`
  margin-left: 12px;
  color: ${colors.gray2};
  font-family: ${fontFamilies.faktPro};
  font-size: ${fontSizes.mini1};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.2px;
`;
