import { ProductDetailsStyled } from './ProductDetails.styled';

export interface ProductDetailsProps {
  children?: React.ReactNode;
  className?: string;
  card?: boolean;
  large?: boolean;
  noBottomMargin?: boolean;
}
export const ProductDetails: React.FC<ProductDetailsProps> = ({
  children,
  className,
  card,
  large,
  noBottomMargin,
}) => (
  <ProductDetailsStyled
    className={className}
    $card={card}
    $large={large}
    $noBottomMargin={noBottomMargin}
  >
    {children}
  </ProductDetailsStyled>
);
