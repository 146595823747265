// Maps to snickers 6 series
const fristad = [
  {
    id: 1,
    name: 'Short',
    sizes: [
      { self: 'D 84', snickers: 88 },
      { self: 'D 88', snickers: 92 },
      { self: 'D 92', snickers: 96 },
      { self: 'D 96', snickers: 100 },
      { self: 'D 100', snickers: 104 },
      { self: 'D 104', snickers: 108 },
      { self: 'D 108', snickers: 112 },
      { self: 'D 112', snickers: 116 },
      { self: 'D 116', snickers: 120 },
      { self: 'D 120', snickers: 124 },
      { self: 'D 124', snickers: 128 },
      { self: 'D 128', snickers: 128 },
      { self: 'D 132', snickers: 132 },
      { self: 'D 136', snickers: 136 },
    ],
  },
  {
    id: 2,
    name: 'Regular',
    sizes: [
      { self: 'C 40', snickers: 40 },
      { self: 'C 42', snickers: 42 },
      { self: 'C 44', snickers: 44 },
      { self: 'C 46', snickers: 46 },
      { self: 'C 48', snickers: 48 },
      { self: 'C 50', snickers: 50 },
      { self: 'C 52', snickers: 52 },
      { self: 'C 54', snickers: 54 },
      { self: 'C 56', snickers: 56 },
      { self: 'C 58', snickers: 58 },
      { self: 'C 60', snickers: 60 },
      { self: 'C 62', snickers: 62 },
      { self: 'C 64', snickers: 64 },
      { self: 'C 66', snickers: 64 },
    ],
  },
  {
    id: 3,
    name: 'Long',
    sizes: [
      { self: 'C 144', snickers: 144 },
      { self: 'C 146', snickers: 146 },
      { self: 'C 148', snickers: 148 },
      { self: 'C 150', snickers: 150 },
      { self: 'C 152', snickers: 152 },
      { self: 'C 154', snickers: 154 },
      { self: 'C 156', snickers: 156 },
      { self: 'C 158', snickers: 158 },
      { self: 'C 160', snickers: 160 },
      { self: 'C 162', snickers: 162 },
      { self: 'C 164', snickers: 164 },
      { self: 'C 166', snickers: 164 },
      { self: 'C 168', snickers: 166 },
    ],
  },
  {
    id: 4,
    name: 'Extra Long',
    sizes: [
      { self: 'C 246', snickers: 246 },
      { self: 'C 248', snickers: 248 },
      { self: 'C 250', snickers: 250 },
      { self: 'C 252', snickers: 252 },
      { self: 'C 254', snickers: 254 },
      { self: 'C 256', snickers: 256 },
      { self: 'C 258', snickers: 258 },
      { self: 'C 260', snickers: 260 },
    ],
  },
];

const blaklader = [
  {
    id: 1,
    name: 'Short',
    sizes: [
      { self: 'D 84', snickers: 92 },
      { self: 'D 88', snickers: 96 },
      { self: 'D 92', snickers: 100 },
      { self: 'D 96', snickers: 104 },
      { self: 'D 100', snickers: 108 },
      { self: 'D 104', snickers: 112 },
      { self: 'D 108', snickers: 116 },
      { self: 'D 112', snickers: 120 },
      { self: 'D 116', snickers: 120 },
      { self: 'D 120', snickers: 124 },
      { self: 'D 124', snickers: 128 },
      { self: 'D 128', snickers: 128 },
      { self: 'D 132', snickers: 132 },
      { self: 'D 136', snickers: 136 },
    ],
  },
  {
    id: 2,
    name: 'Regular',
    sizes: [
      { self: 'C 42', snickers: 42 },
      { self: 'C 44', snickers: 44 },
      { self: 'C 46', snickers: 46 },
      { self: 'C 48', snickers: 48 },
      { self: 'C 50', snickers: 50 },
      { self: 'C 52', snickers: 52 },
      { self: 'C 54', snickers: 54 },
      { self: 'C 56', snickers: 56 },
      { self: 'C 58', snickers: 58 },
      { self: 'C 60', snickers: 60 },
      { self: 'C 62', snickers: 60 },
      { self: 'C 64', snickers: 62 },
    ],
  },
  {
    id: 3,
    name: 'Long',
    sizes: [
      { self: 'C 146', snickers: 146 },
      { self: 'C 148', snickers: 148 },
      { self: 'C 150', snickers: 150 },
      { self: 'C 152', snickers: 152 },
      { self: 'C 154', snickers: 154 },
      { self: 'C 156', snickers: 156 },
      { self: 'C 158', snickers: 158 },
    ],
  },
];

const mascot = [
  {
    id: 1,
    name: 'Short',
    sizes: [
      { self: '76 C 42', snickers: 80 },
      { self: '76 C 43', snickers: 84 },
      { self: '76 C 44', snickers: 84 },
      { self: '76 C 45', snickers: 88 },
      { self: '76 C 46', snickers: 92 },
      { self: '76 C 47', snickers: 92 },
      { self: '76 C 48', snickers: 96 },
      { self: '76 C 49', snickers: 100 },
      { self: '76 C 50', snickers: 100 },
      { self: '76 C 51', snickers: 104 },
      { self: '76 C 52', snickers: 104 },
      { self: '76 C 54', snickers: 112 },
      { self: '76 C 56', snickers: 116 },
      { self: '76 C 58', snickers: 120 },
      { self: '76 C 60', snickers: 120 },
      { self: '76 C 62', snickers: 124 },
      { self: '76 C 64', snickers: 128 },
      { self: '76 C 66', snickers: 128 },
      { self: '76 C 68', snickers: 132 },
      { self: '76 C 70', snickers: 136 },
      { self: '76 C 72', snickers: 136 },
    ],
  },
  {
    id: 2,
    name: 'Regular',
    sizes: [
      { self: '82 C 42', snickers: 40 },
      { self: '82 C 43', snickers: 42 },
      { self: '82 C 44', snickers: 42 },
      { self: '82 C 45', snickers: 44 },
      { self: '82 C 46', snickers: 46 },
      { self: '82 C 47', snickers: 46 },
      { self: '82 C 48', snickers: 48 },
      { self: '82 C 49', snickers: 50 },
      { self: '82 C 50', snickers: 50 },
      { self: '82 C 51', snickers: 52 },
      { self: '82 C 52', snickers: 52 },
      { self: '82 C 54', snickers: 56 },
      { self: '82 C 56', snickers: 58 },
      { self: '82 C 58', snickers: 60 },
      { self: '82 C 60', snickers: 60 },
      { self: '82 C 62', snickers: 62 },
      { self: '82 C 64', snickers: 64 },
      { self: '82 C 66', snickers: 64 },
      { self: '82 C 68', snickers: 66 },
      { self: '82 C 70', snickers: 68 },
      { self: '82 C 72', snickers: 68 },
    ],
  },
  {
    id: 3,
    name: 'Long',
    sizes: [
      { self: '90 C 42', snickers: 140 },
      { self: '90 C 43', snickers: 142 },
      { self: '90 C 44', snickers: 142 },
      { self: '90 C 45', snickers: 144 },
      { self: '90 C 46', snickers: 146 },
      { self: '90 C 47', snickers: 146 },
      { self: '90 C 48', snickers: 148 },
      { self: '90 C 49', snickers: 150 },
      { self: '90 C 50', snickers: 150 },
      { self: '90 C 51', snickers: 152 },
      { self: '90 C 52', snickers: 152 },
      { self: '90 C 54', snickers: 156 },
      { self: '90 C 56', snickers: 158 },
      { self: '90 C 58', snickers: 160 },
      { self: '90 C 60', snickers: 160 },
      { self: '90 C 62', snickers: 162 },
      { self: '90 C 64', snickers: 164 },
      { self: '90 C 66', snickers: 164 },
      { self: '90 C 68', snickers: 166 },
      { self: '90 C 70', snickers: 168 },
      { self: '90 C 72', snickers: 168 },
    ],
  },
];

export const otherBrandSizes = [
  {
    name: 'Fristads',
    values: fristad,
  },
  {
    name: 'Blåkläder',
    values: blaklader,
  },
  {
    name: 'Mascot',
    values: mascot,
  },
];
