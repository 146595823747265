import { JeansSize } from '@hultafors/snickers/types';

export const jeansSizesMen: JeansSize[] = [
  {
    leg: 28,
    waist: [
      {
        size: 188,
        value: 30,
      },
      {
        size: 192,
        value: 31,
      },
      {
        size: 192,
        value: 32,
      },
      {
        size: 196,
        value: 33,
      },
      {
        size: 196,
        value: 34,
      },
      {
        size: 200,
        value: 35,
      },
      {
        size: 204,
        value: 36,
      },
      {
        size: 204,
        value: 37,
      },
      {
        size: 208,
        value: 38,
      },
      {
        size: 212,
        value: 39,
      },
      {
        size: 212,
        value: 40,
      },
      {
        size: 216,
        value: 41,
      },
      {
        size: 216,
        value: 42,
      },
      {
        size: 216,
        value: 43,
      },
      {
        size: 220,
        value: 44,
      },
      {
        size: 220,
        value: 45,
      },
      {
        size: 220,
        value: 46,
      },
      {
        size: 224,
        value: 47,
      },
      {
        size: 224,
        value: 48,
      },
      {
        size: 224,
        value: 49,
      },
      {
        size: 228,
        value: 50,
      },
      {
        size: 228,
        value: 51,
      },
      {
        size: 228,
        value: 52,
      },
      {
        size: 228,
        value: 53,
      },
      {
        size: 232,
        value: 54,
      },
      {
        size: 232,
        value: 55,
      },
      {
        size: 232,
        value: 56,
      },
      {
        size: 536,
        value: 57,
      },
      {
        size: 540,
        value: 58,
      },
      {
        size: 540,
        value: 59,
      },
      {
        size: 540,
        value: 60,
      },
    ],
  },
  {
    leg: 30,
    waist: [
      {
        size: 88,
        value: 30,
      },
      {
        size: 92,
        value: 31,
      },
      {
        size: 92,
        value: 32,
      },
      {
        size: 96,
        value: 33,
      },
      {
        size: 96,
        value: 34,
      },
      {
        size: 100,
        value: 35,
      },
      {
        size: 104,
        value: 36,
      },
      {
        size: 104,
        value: 37,
      },
      {
        size: 108,
        value: 38,
      },
      {
        size: 112,
        value: 39,
      },
      {
        size: 112,
        value: 40,
      },
      {
        size: 116,
        value: 41,
      },
      {
        size: 116,
        value: 42,
      },
      {
        size: 116,
        value: 43,
      },
      {
        size: 120,
        value: 44,
      },
      {
        size: 120,
        value: 45,
      },
      {
        size: 120,
        value: 46,
      },
      {
        size: 124,
        value: 47,
      },
      {
        size: 124,
        value: 48,
      },
      {
        size: 124,
        value: 49,
      },
      {
        size: 128,
        value: 50,
      },
      {
        size: 128,
        value: 51,
      },
      {
        size: 128,
        value: 52,
      },
      {
        size: 128,
        value: 53,
      },
      {
        size: 132,
        value: 54,
      },
      {
        size: 132,
        value: 55,
      },
      {
        size: 132,
        value: 56,
      },
      {
        size: 636,
        value: 57,
      },
      {
        size: 640,
        value: 58,
      },
      {
        size: 640,
        value: 59,
      },
      {
        size: 640,
        value: 60,
      },
    ],
  },
  {
    leg: 32,
    waist: [
      {
        size: 44,
        value: 30,
      },
      {
        size: 46,
        value: 31,
      },
      {
        size: 48,
        value: 32,
      },
      {
        size: 48,
        value: 33,
      },
      {
        size: 50,
        value: 34,
      },
      {
        size: 50,
        value: 35,
      },
      {
        size: 52,
        value: 36,
      },
      {
        size: 54,
        value: 37,
      },
      {
        size: 54,
        value: 38,
      },
      {
        size: 56,
        value: 39,
      },
      {
        size: 58,
        value: 40,
      },
      {
        size: 58,
        value: 41,
      },
      {
        size: 60,
        value: 42,
      },
      {
        size: 60,
        value: 43,
      },
      {
        size: 60,
        value: 44,
      },
      {
        size: 62,
        value: 45,
      },
      {
        size: 62,
        value: 46,
      },
      {
        size: 62,
        value: 47,
      },
      {
        size: 64,
        value: 48,
      },
      {
        size: 64,
        value: 49,
      },
      {
        size: 64,
        value: 50,
      },
      {
        size: 66,
        value: 51,
      },
      {
        size: 66,
        value: 52,
      },
      {
        size: 66,
        value: 53,
      },
      {
        size: 66,
        value: 54,
      },
      {
        size: 68,
        value: 55,
      },
      {
        size: 68,
        value: 56,
      },
      {
        size: 68,
        value: 57,
      },
      {
        size: 70,
        value: 58,
      },
      {
        size: 70,
        value: 59,
      },
      {
        size: 70,
        value: 60,
      },
    ],
  },
  {
    leg: 35,
    waist: [
      {
        size: 144,
        value: 30,
      },
      {
        size: 146,
        value: 31,
      },
      {
        size: 148,
        value: 32,
      },
      {
        size: 148,
        value: 33,
      },
      {
        size: 150,
        value: 34,
      },
      {
        size: 150,
        value: 35,
      },
      {
        size: 152,
        value: 36,
      },
      {
        size: 154,
        value: 37,
      },
      {
        size: 154,
        value: 38,
      },
      {
        size: 156,
        value: 39,
      },
      {
        size: 158,
        value: 40,
      },
      {
        size: 158,
        value: 41,
      },
      {
        size: 160,
        value: 42,
      },
      {
        size: 160,
        value: 43,
      },
      {
        size: 160,
        value: 44,
      },
      {
        size: 162,
        value: 45,
      },
      {
        size: 162,
        value: 46,
      },
      {
        size: 162,
        value: 47,
      },
      {
        size: 164,
        value: 48,
      },
      {
        size: 164,
        value: 49,
      },
      {
        size: 164,
        value: 50,
      },
      {
        size: 166,
        value: 51,
      },
      {
        size: 166,
        value: 52,
      },
      {
        size: 166,
        value: 53,
      },
      {
        size: 166,
        value: 54,
      },
      {
        size: 168,
        value: 55,
      },
      {
        size: 168,
        value: 56,
      },
      {
        size: 168,
        value: 57,
      },
      {
        size: 170,
        value: 58,
      },
      {
        size: 170,
        value: 59,
      },
      {
        size: 170,
        value: 60,
      },
    ],
  },
  {
    leg: 37,
    waist: [
      {
        size: 244,
        value: 30,
      },
      {
        size: 246,
        value: 31,
      },
      {
        size: 248,
        value: 32,
      },
      {
        size: 248,
        value: 33,
      },
      {
        size: 250,
        value: 34,
      },
      {
        size: 250,
        value: 35,
      },
      {
        size: 252,
        value: 36,
      },
      {
        size: 254,
        value: 37,
      },
      {
        size: 254,
        value: 38,
      },
      {
        size: 256,
        value: 39,
      },
      {
        size: 258,
        value: 40,
      },
      {
        size: 258,
        value: 41,
      },
      {
        size: 260,
        value: 42,
      },
      {
        size: 260,
        value: 43,
      },
      {
        size: 260,
        value: 44,
      },
      {
        size: 262,
        value: 45,
      },
      {
        size: 262,
        value: 46,
      },
      {
        size: 262,
        value: 47,
      },
      {
        size: 264,
        value: 48,
      },
      {
        size: 264,
        value: 49,
      },
      {
        size: 264,
        value: 50,
      },
      {
        size: 266,
        value: 51,
      },
      {
        size: 266,
        value: 52,
      },
      {
        size: 266,
        value: 53,
      },
      {
        size: 266,
        value: 54,
      },
      {
        size: 268,
        value: 55,
      },
      {
        size: 268,
        value: 56,
      },
      {
        size: 268,
        value: 57,
      },
      {
        size: 270,
        value: 58,
      },
      {
        size: 270,
        value: 59,
      },
      {
        size: 270,
        value: 60,
      },
    ],
  },
];
