import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
} from '@hultafors/snickers/helpers';

export const SizeGuideStyled = styled.section`
  padding-bottom: 48px;

  h4 {
    font-size: 14px;

    &.title {
      font-size: ${fontSizes.l};
    }

    &.LargeH4 {
      font-family: ${fontFamilies.mikro};
      font-size: ${fontSizes.megabody};
    }
  }

  .ContentWrapper {
    padding: 20px;
    padding-block-end: 85px;
  }

  .ChangeSize {
    display: flex;

    .Inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        inline-size: 48%;
      }
    }
  }

  .DropdownWrapper {
    block-size: 96px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:not(:last-child) {
      border-block-end: 1px solid ${colors.gray4};
    }

    .label {
      font-size: 14px;
      font-weight: ${fontWeights.medium};
    }

    .DropdownBadge {
      position: relative;
      margin-inline-end: 16px;
    }

    .Dropdown {
      display: flex;
      flex-grow: 1;
      border: 1px solid ${colors.gray4};

      .DropdownLabel {
        inline-size: 100%;
        padding: 12px 24px 12px 12px;

        &::after {
          inset-inline-end: 8px;
        }
      }

      .DropdownList {
        inline-size: 100%;
        box-sizing: border-box;
      }
    }
  }

  .MeasurementImageWrapper {
    position: relative;
    display: flex;
    block-size: 280px;
    inline-size: 100%;
    justify-content: center;
    align-items: center;
    padding-block-end: 24px;
    border-block-end: 1px solid ${colors.gray4};

    img,
    svg {
      block-size: 100%;
    }
  }

  .TextAndSelectWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 16px;

    h4 {
      margin: 0;
    }

    .SelectWrapper {
      position: relative;

      select {
        appearance: none;
        border: none;
        background: none;
        padding: 10px 20px 10px 10px;
        direction: rtl;
        font-size: 14px;
      }

      &::after {
        content: '';
        block-size: 12px;
        inline-size: 12px;
        background-image: url('/assets/gfx/chevron.svg');
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .BsgBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 48px 16px 32px;

    &::before {
      content: '';
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      border-width: 16px 16px 0 0;
      border-style: solid;
    }

    &.SixSeries {
      background-color: ${colors.workwearYellow};

      &::before {
        border-color: ${colors.white} ${colors.workwearYellow2};
      }
    }

    &.Gray {
      background-color: ${colors.gray4};
      margin-block-start: 24px;

      &::before {
        border-color: ${colors.white} ${colors.gray3};
      }
    }

    .SizeLabel {
      font-size: ${fontSizes.m};
    }

    .Size {
      font-size: 70px;
      font-weight: ${fontWeights.medium};
      line-height: 1;
      margin-block-end: -8px;
      margin-inline-start: auto;
    }
  }

  .Result {
    h4 {
      font-size: ${fontSizes.l};
      line-height: 1.4;
      margin-block-end: 40px;
    }

    .NoSizeFound {
      margin: 0;
      font-size: ${fontSizes.body};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.m};
      }
    }
  }

  .ErrorMessage {
    margin: 0 -20px;
    padding: 24px 20px;
    border-block-end: 1px solid ${colors.gray4};
    text-align: center;
    font-size: ${fontSizes.m};
  }

  .Summary {
    position: fixed;
    box-sizing: border-box;
    inline-size: 100%;
    inset-block-end: 0;
    display: flex;
    flex-direction: row;
    background: white;

    button {
      block-size: 48px;
      border-radius: 0;
    }
  }

  .tabs {
    position: relative;
    display: flex;
    justify-content: center;
    border-block-end: 1px solid ${colors.gray4};
    padding-block-end: 24px;
    margin-block-end: 24px;
  }

  .tab {
    font-weight: ${fontWeights.medium};
    padding: 0 8px;

    label {
      position: relative;
      color: ${colors.gray2};
      cursor: pointer;
    }

    [type='radio'] {
      display: none;
    }

    .content {
      position: absolute;
      inset: 72px 0 0;
      background: white;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    [type='radio']:checked ~ label {
      color: ${colors.workwearBlack};

      &::after {
        content: '';
        position: absolute;
        block-size: 2px;
        inline-size: 16px;
        inset-block-end: -6px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        background: ${colors.workwearBlack};
      }
    }

    [type='radio']:checked ~ label ~ .content {
      z-index: 1;
      opacity: 1;
    }
  }

  .NotStandardSize {
    text-align: center;
    font-size: ${fontSizes.m};
  }
`;
