import dynamic from 'next/dynamic';

import type { FindRetailersTranslations } from '@hultafors/shared/components';

import { useGlobal } from '@hultafors/snickers/hooks';
import { FindRetailersPageFragment } from '@hultafors/snickers/types';

import ChevronSvg from '../../svg/chevron.svg';
import { VisibleSeoBlock } from '../ContentArea/_contentAreaBlocks';
import { ContentArea } from '../ContentArea/ContentArea';
import { HeroBlock } from '../hero-block/hero-block';
import { LinkBlock } from '../LinkBlock/LinkBlock';

const FindRetailers = dynamic(() =>
  import('@hultafors/shared/components/find-retailers/find-retailers').then(
    (mod) => mod.FindRetailers
  )
);

interface FindRetailersPageProps {
  content: FindRetailersPageFragment;
}

export const FindRetailersPageContent: React.FC<FindRetailersPageProps> = ({
  content,
}) => {
  const { global: globalContent, settings } = useGlobal();

  if (!content) {
    return null;
  }

  const defaultCoords = settings?.defaultCoords;

  const translations: FindRetailersTranslations = {
    clear: globalContent.clear,
    ...Object.fromEntries(
      Object.entries(globalContent.findRetailers || {}).filter(
        ([key, value]) => key !== '__typename' && typeof value === 'string'
      )
    ),
  };

  const showInternational = settings.market !== 'com';

  const indicator = (
    <ChevronSvg aria-hidden focusable={false} width={16} height={16} />
  );

  return (
    <>
      <HeroBlock
        id="find-retailers-hero"
        title={content.findStoreHeader || ''}
        heroIcon
        wide
        thin
        priority
      />
      {content.becomeRetailersLinkText && content.becomeRetailersLinkUrl && (
        <LinkBlock
          linkText={content.becomeRetailersLinkText}
          linkUrl={content.becomeRetailersLinkUrl}
          iconNext={true}
        />
      )}
      {content.contentArea && <ContentArea content={content.contentArea} />}
      <FindRetailers
        translations={translations}
        defaultCoords={defaultCoords}
        indicator={indicator}
        showInternational={showInternational}
        deepLink
      />
      {content.seoVisibleTitle && content.seoVisibleDescription && (
        <VisibleSeoBlock
          title={content.seoVisibleTitle}
          description={content.seoVisibleDescription}
        />
      )}
    </>
  );
};
