import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';

interface ArticleSectionStyledProps {
  $articleTop?: boolean;
}

export const ArticleSectionStyled = styled.section<ArticleSectionStyledProps>`
  padding-block-start: ${({ $articleTop }) => ($articleTop ? '0' : '25px')};
  margin: 0 20px;

  &:last-of-type {
    padding-block-end: 25px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block-start: ${({ $articleTop }) => ($articleTop ? '0' : '40px')};

    &:last-of-type {
      padding-block-end: 40px;
    }
  }

  ${({ $articleTop }) =>
    $articleTop
    && css`
      position: relative;
      margin-block-start: -60px;
      z-index: 1;

      &:last-of-type {
        padding-block-end: 0;
      }

      &::before {
        content: '';
        position: absolute;
        block-size: 100%;
        inline-size: 80vw;
        inset-inline-end: 50%;
        background-color: ${colors.white};
        z-index: -1;
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-block-start: -180px;
      }

      h1 {
        font-size: 22px;
        line-height: 28px;
        margin-block: 0 20px;
        margin-inline-end: 16px;

        @media screen and (min-width: ${breakpoints.desktop}) {
          font-size: ${fontSizes.h2};
          line-height: 38px;
          margin-block-end: 40px;
        }
      }

      .articleTopContent {
        position: relative;
        padding: 35px 0 0;
        background-color: ${colors.white};
        z-index: 3;

        @media screen and (min-width: ${breakpoints.desktop}) {
          min-block-size: 90px;
        }

        p {
          font-size: ${fontSizes.l};
          line-height: 26px;
          font-weight: ${fontWeights.medium};
          margin-block-end: 20px;

          @media screen and (min-width: ${breakpoints.desktop}) {
            letter-spacing: 0.5px;
            margin-block-end: 36px;
          }
        }
      }
    `}

  .OpenGuideButton {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    transform: translateY(-100%);

    button {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      block-size: auto;
      min-block-size: 3rem;
      max-inline-size: 270px;
      white-space: unset;
      border-radius: 0;
      text-align: end;
      line-height: 1.25;
      font-size: ${fontSizes.body};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.m};
      }

      svg {
        position: static;
        display: flex;
        margin-inline-start: 8px;
        inset-block-start: unset;
        transform: none;
        inline-size: 12px;
        block-size: 12px;
      }
    }
  }

  .InfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    block-size: 74px;
    border-block-start: 1px solid #f2f2f2;
    border-block-end: 1px solid #f2f2f2;
    margin: 36px 0;
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    line-height: 22px;

    @media screen and (min-width: ${breakpoints.bigMobile}) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 0 0 36px;
    }

    .DateWrapper {
      display: flex;
      align-items: center;

      time {
        font-size: 14px;
        font-weight: ${fontWeights.medium};
      }
    }

    .line {
      inline-size: 30px;
      block-size: 1px;
      background-color: ${colors.workwearBlack};
      margin: 0 16px 0 0;

      @media screen and (min-width: ${breakpoints.bigMobile}) {
        margin: 0 16px;
      }
    }
  }

  .MarkdownWrapper {
    p {
      line-height: 24px;
      margin: 0 0 12px;
      white-space: pre-wrap;

      strong {
        font-weight: ${fontWeights.medium};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        line-height: 26px;
      }
    }
  }

  .DocumentLinks {
    margin: 48px 0 32px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 72px 0 16px;
    }

    a {
      display: flex;
      align-items: center;
      block-size: 64px;
      border-block-start: 1px solid ${colors.gray4};
      font-size: 14px;
      letter-spacing: 0.5px;
      text-decoration: none;

      &:hover {
        color: ${colors.gray1};
      }

      &:last-of-type {
        border-block-end: 1px solid ${colors.gray4};
      }

      img {
        margin-inline-end: 12px;
      }
    }
  }

  + .Video {
    margin-block-start: 40px;
  }
`;
