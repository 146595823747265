import { FC } from 'react';

import { FeedBlockFragment, FeedBlockPlug } from '@hultafors/snickers/types';

import { DynamicContent } from '../../../DynamicContent/DynamicContent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeedBlockProps
  extends Omit<FeedBlockFragment, '__typename' | 'id'> {
  plugs: FeedBlockPlug[];
}

export const FeedBlock: FC<FeedBlockProps> = ({ plugs = [] }) => {
  return <DynamicContent content={plugs} />;
};
