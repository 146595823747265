import { memo, startTransition, useState } from 'react';

import { useRouter } from 'next/router';

import { mergeUrlSearchParams } from '@hultafors/shared/api';
import {
  ListApiPaging,
  ListApiResponse,
  ProductsApiResponse,
} from '@hultafors/shared/types';

import { useGlobal, useWorkwearGuide } from '@hultafors/snickers/hooks';
import {
  filterSpec,
  getCategories,
  getFilters,
  getWorkwearGuideFilterQuery,
  resolveCurrentTitle,
  resolveResult,
  resolveStep,
  toggleWorkwearFilters,
} from '@hultafors/snickers/product-helpers';
import {
  SnickersProduct,
  WorkwearGuideCategory,
  WorkwearGuideFilter,
  WorkwearGuideSelection,
  WorkwearGuideSelectionMulti,
  WorkwearGuideSelectionSingle,
  WorkwearGuideStep,
} from '@hultafors/snickers/types';

import { SlideIn } from '../SlideIn/SlideIn';
import { WorkwearGuideResult } from '../WorkwearGuideResult/WorkwearGuideResult';
import { WorkwearGuideRoundBoxes } from '../WorkwearGuideRoundBoxes/WorkwearGuideRoundBoxes';
import { WorkwearGuideSquareBoxes } from '../WorkwearGuideSquareBoxes/WorkwearGuideSquareBoxes';
import { WorkwearGuideStart } from '../WorkwearGuideStart/WorkwearGuideStart';

import { WorkwearGuideStyled } from './WorkwearGuide.styled';

export const WORKWEAR_GUIDE_DRAWER_ID = 'WorkwearGuideDrawer';

const defaultSelection: WorkwearGuideSelection = {
  category: undefined,
  certification: [],
  environment: [],
  features: [],
  filters: [],
  fit: [],
  garment: [],
  gender: [],
  indoorOutdoor: undefined,
  occupation: [],
  riskEnvironment: [],
};

const defaultLoadMorePaging: Partial<ProductsApiResponse['paging']> = {
  itemCount: 5,
  pageNumber: 1,
  pageSize: 5,
};

const defaultCurrentStep: WorkwearGuideStep = {
  name: 'category',
  number: 0,
};

const PRODUCTS_PAGE_SIZE = 10;
// Snickers brandsite guide node for guide products
const MAX_MATCHES_TO_SHOW = 5;

export const WorkwearGuide: React.FC = () => {
  const { settings, workwearGuide: content } = useGlobal();
  const { locale } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] =
    useState<WorkwearGuideStep>(defaultCurrentStep);
  const [loadMorePaging, setLoadMorePaging] = useState<
    Partial<ListApiPaging | undefined>
  >(defaultLoadMorePaging);
  const [numberOfSteps, setNumberOfSteps] = useState<number>(1);
  const [selection, setSelection] =
    useState<WorkwearGuideSelection>(defaultSelection);
  const [categories, setCategories] = useState<WorkwearGuideCategory[]>(
    content ? getCategories(content) : []
  );
  const [isLoadingMoreProducts, setIsLoadingMoreProducts] =
    useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<WorkwearGuideFilter[]>(
    []
  );
  const [filteredProducts, setFilteredProducts] = useState<
    Pick<ListApiResponse<SnickersProduct>, 'items'>[]
  >([]);
  const [loadedFilters, setLoadedFilters] = useState<any[]>([]);
  const [perfectMatch, setPerfectMatch] = useState<SnickersProduct[]>([]);
  const [semiPerfectMatch, setSemiPerfectMatch] = useState<SnickersProduct[]>(
    []
  );
  const [lessPerfectMatch, setLessPerfectMatch] = useState<SnickersProduct[]>(
    []
  );
  const [stepsData, setStepsData] = useState<WorkwearGuideFilter[]>([]);

  const selectCategory = (category: WorkwearGuideCategory) => {
    reset(category);
  };

  const selectFilter = (
    category: WorkwearGuideSelectionSingle,
    item: WorkwearGuideFilter
  ) => {
    // Radio selector style

    const filters = toggleWorkwearFilters({
      child: item,
      existingFilters: [...(selection.filters || [])],
      parent: {
        description: '',
        hits: item.values?.length || 0,
        id: item.parentId || -1,
        type: item.parentType,
      },
    });

    const result: WorkwearGuideFilter[] = [];

    const selectedFilter =
      selection[category]?.id === item.id ? undefined : item;

    filters.forEach((filter: WorkwearGuideFilter) => {
      if (filter.id === item.parentId) {
        if (filter.values && filter.values?.length > 1) {
          filter.values.forEach((child) => {
            if (child.id === item.id) {
              result.push(child);
            }
          });
          filter.values = result;
        }
      }
    });
    startTransition(() => {
      setSelection({
        ...selection,
        [category]: selectedFilter,
        filters,
      });
    });
  };

  const selectFilterMultiple = (
    category: WorkwearGuideSelectionMulti,
    item: WorkwearGuideFilter
  ) => {
    const filters = toggleWorkwearFilters({
      child: item,
      existingFilters: [...selection.filters],
      parent: {
        description: '',
        hits: item.values?.length || 0,
        id: item.parentId || -1,
        type: item.parentType,
      },
    });

    let selectedFilters: WorkwearGuideFilter[] = [];

    if (selection[category]?.some(({ id }) => id === item.id)) {
      // Remove
      selectedFilters =
        selection[category]?.filter(({ id }) => id !== item.id) || [];
    } else {
      // Add
      selectedFilters = [...(selection[category] || []), item];
    }
    startTransition(() => {
      setSelection({
        ...selection,
        [category]: selectedFilters,
        filters,
      });
    });
  };

  const loadProducts = async (
    productCatalogNodeId: string | number,
    filters: WorkwearGuideFilter[] = [],
    page = 1,
    pageSize = PRODUCTS_PAGE_SIZE
  ): Promise<ListApiResponse<SnickersProduct> | void> => {
    startTransition(() => {
      setLoading(true);
    });
    try {
      const query = mergeUrlSearchParams(
        new URLSearchParams({
          includeFilters: 'true',
          page: `${page}`,
          pageSize: `${pageSize}`,
          productCatalogNodeId: `${productCatalogNodeId}`,
          ...(locale && locale !== 'com' ? { locale } : {}),
        }),
        getWorkwearGuideFilterQuery(filters)
      );
      const url = `/api/productslist/${
        settings.pt?.productList
      }?${query.toString()}`;
      const response = await fetch(url);
      const data = await response.json();
      startTransition(() => {
        setLoading(false);
      });
      return data;
    } catch (error) {
      startTransition(() => {
        setLoading(false);
      });
      console.error(error);
    }
  };

  const loadMore = async () => {
    startTransition(() => {
      setIsLoadingMoreProducts(true);
    });

    // TODO load more products

    // const result = await loadProducts(
    //   selection.category,
    //   selection.filters || [],
    //   (loadMorePaging?.pageNumber || 0) + 1,
    //   MAX_MATCHES_TO_SHOW
    // );

    startTransition(() => {
      setPerfectMatch([...perfectMatch, ...[]]); // TODO set to items from result
      setLoadMorePaging(undefined); // TODO set to paging from result
      setIsLoadingMoreProducts(false);
    });
  };

  const prepareDataForResult = (result: ListApiResponse<SnickersProduct>) => {
    const matchResult = resolveResult(
      result,
      filteredProducts,
      MAX_MATCHES_TO_SHOW
    );
    startTransition(() => {
      setPerfectMatch(matchResult.perfectMatch);
      setSemiPerfectMatch(matchResult.semiPerfectMatch);
      setLessPerfectMatch(matchResult.perfectMatch);
      setLoadMorePaging(matchResult.loadMorePaging);
    });
  };

  const changeStep = async (stepNumber: number) => {
    const nextStep: WorkwearGuideStep = resolveStep(
      stepNumber,
      selection.category
    );
    startTransition(() => {
      setCurrentStep(nextStep);
      setCurrentFilters([]);
    });

    // TODO save previous products and set to memory when going back
    if (selection.category) {
      // next

      const result = await loadProducts(
        selection.category,
        selection.filters,
        1,
        PRODUCTS_PAGE_SIZE
      );

      if (result) {
        const newFilters = getFilters(result.filters || [], nextStep.name);

        // If we are at last step of current filters are empty
        if (
          currentStep.name === 'result' ||
          !newFilters ||
          newFilters.length === 0 ||
          newFilters.length === 1 ||
          result.items?.length === 0
        ) {
          prepareDataForResult(result);
          // if we have no result or only one option, redirect user to result screen
          currentStep.name = 'result';
        }
        startTransition(() => {
          setCurrentFilters(newFilters);
          setFilteredProducts([...filteredProducts, { items: result.items }]);
          setStepsData([...stepsData, ...currentFilters]);
        });
      } else {
        // TODO no products, go to result page?
      }
    } else {
      // stepping back, taking filter data from memory, but removing previous selections so user can start over
      startTransition(() => {
        const step: WorkwearGuideFilter | undefined =
          stepsData[Math.max(currentStep.number - 1, 0)];
        if (typeof step !== 'undefined') {
          setCurrentFilters([step]);
          setFilteredProducts(filteredProducts.slice(0, -1));
          setStepsData(stepsData.slice(0, -1));
        }
      });
    }
  };

  // reset guide on category selection or on open
  const reset = (category?: WorkwearGuideCategory) => {
    startTransition(() => {
      setSelection({
        ...defaultSelection,
        category: category?.nodeId || undefined,
      });
      setNumberOfSteps(category?.steps || 1);
      setCurrentStep(defaultCurrentStep);
      setCurrentFilters([]);
      setFilteredProducts([]);
      setLoadedFilters([]);
      setPerfectMatch([]);
      setSemiPerfectMatch([]);
      setLessPerfectMatch([]);
      setStepsData([]);
    });
  };

  const { toggle, isOpen } = useWorkwearGuide();
  const isMultiSelect = (stepName: WorkwearGuideStep['name']): boolean => {
    return (
      stepName === filterSpec.occupation.name ||
      stepName === filterSpec.gender.name ||
      stepName === filterSpec.features.name ||
      stepName === filterSpec.environment.name ||
      stepName === filterSpec.riskEnvironment.name ||
      stepName === filterSpec.garment.name ||
      stepName === filterSpec.fit.name ||
      stepName === filterSpec.certification.name
    );
  };

  const onHideComplete = () => {
    reset();
  };
  return (
    <WorkwearGuideStyled>
      <SlideIn
        toggle={toggle}
        isOpen={isOpen}
        headerText=""
        headerIcon="/assets/gfx/workwear.svg"
        fromLeft={false}
        isGuide
        id={WORKWEAR_GUIDE_DRAWER_ID}
        onHideComplete={onHideComplete}
        changeStepWG={changeStep}
        currentStepNumber={currentStep.number !== 0 ? currentStep.number : null}
        backText={content?.back || 'Back'}
      >
        {currentStep.name === 'category' && (
          <WorkwearGuideStart
            changeStep={changeStep}
            currentStep={currentStep}
            numberOfSteps={numberOfSteps}
            selectCategory={selectCategory}
            selection={selection}
            categories={categories}
            heroTitle={content?.headerText || ''}
            heroIntro={content?.headerIntro || ''}
            title={content?.chooseCategoryTitle || ''}
            backText={content?.back || ''}
            nextText={content?.next || ''}
            loading={loading}
          />
        )}

        {/* {stepToRender} */}

        {currentStep.name === filterSpec.indoorOutdoor.name && (
          <WorkwearGuideRoundBoxes
            changeStep={changeStep}
            selectFilter={selectFilter}
            currentStep={currentStep}
            numberOfSteps={numberOfSteps}
            selection={selection}
            title={resolveCurrentTitle(
              currentStep,
              selection.category,
              content
            )}
            backText={content.back || ''}
            nextText={content.next || ''}
            filters={currentFilters}
            loading={loading}
          />
        )}

        {isMultiSelect(currentStep.name) && (
          <WorkwearGuideSquareBoxes
            changeStep={changeStep}
            selectFilter={selectFilterMultiple}
            currentStep={currentStep}
            numberOfSteps={numberOfSteps}
            selection={selection}
            title={resolveCurrentTitle(
              currentStep,
              selection.category,
              content
            )}
            backText={content.back || ''}
            nextText={content.next || ''}
            filters={currentFilters}
            loading={loading}
          />
        )}

        {currentStep.name === 'result' && (
          <WorkwearGuideResult
            changeStep={changeStep}
            loadMore={loadMore}
            currentStep={currentStep}
            numberOfSteps={numberOfSteps}
            selection={selection}
            perfectMatch={perfectMatch}
            semiPerfectMatch={semiPerfectMatch}
            lessPerfectMatch={lessPerfectMatch}
            maxMatchingToShow={MAX_MATCHES_TO_SHOW}
            heroTitle={content?.weRecommend || ''}
            backText={content?.back || ''}
            nextText={content?.next || ''}
            personalFilterText={content?.personalFilter || ''}
            perfectText={content?.perfectMatch || ''}
            semiPerfectText={content?.semiPerfectMatch || ''}
            lessPerfectText={content?.lessPerfectMatch || ''}
            pagerLoading={isLoadingMoreProducts}
            pagerData={loadMorePaging}
            newText={content?.new || ''}
            loadMoreText={content?.loadMore || ''}
            loading={loading}
          />
        )}
      </SlideIn>
    </WorkwearGuideStyled>
  );
};

export default memo(WorkwearGuide);
