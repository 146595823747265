import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';
import { Paragraph, ParagraphProps } from '../Paragraph/Paragraph';

import {
  TextSectionContent,
  TextSectionTitle,
  TextSectionWrapper,
} from './TextSection.styled';

const ParagraphOverrider: React.FC<ParagraphProps> = ({
  children,
  ...rest
}) => {
  return (
    <Paragraph {...rest} styleType="contentDesktop">
      {children}
    </Paragraph>
  );
};

export interface TextSectionProps {
  title?: string;
  description?: string;
  left?: boolean;
}

export const TextSection: React.FC<TextSectionProps> = ({
  title,
  description,
  left,
}) => {
  return (
    <TextSectionWrapper $left={left}>
      <Grid>
        <GridChild>
          <TextSectionContent $left={left}>
            <TextSectionTitle>{title}</TextSectionTitle>
            <Markdown
              options={{
                forceBlock: true,
                overrides: { p: ParagraphOverrider },
              }}
            >
              {description ?? ''}
            </Markdown>
          </TextSectionContent>
        </GridChild>
      </Grid>
    </TextSectionWrapper>
  );
};
