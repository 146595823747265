import dynamic from 'next/dynamic';

import {
  ProductFilter,
  ProductFilterValue,
  QueryProductFilter,
} from '@hultafors/shared/types';

import {
  CheckBox,
  Filter,
  FilterIcon,
  FilterLabel,
  FilterList,
  Label,
} from './Filters.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);

export interface FiltersProps {
  filters: ProductFilter[];
  filterChange(filter: QueryProductFilter): void;
  menu?: boolean;
}
export const Filters: React.FC<FiltersProps> = ({
  filters,
  filterChange,
  menu,
}) => {
  const filterMapper = (
    { values, label = '', id, ...rest }: ProductFilter,
    index: number,
  ) => {
    const valueMapper = (filter: ProductFilterValue) => {
      const onChange = () => {
        filterChange({ AttrId: `${id}`, ValueId: `${filter.id}` });
      };
      return (
        <Filter key={`Filter-${filter.id}`}>
          {filter.image?.url && id === 739 && (
            <FilterIcon>
              <img
                src={filter.image.url}
                alt={filter.image.description || filter.description || ''}
                width="16px"
                height="16px"
              />
            </FilterIcon>
          )}
          <Label>{filter.description}</Label>
          <CheckBox
            type="checkbox"
            checked={filter.active}
            onChange={onChange}
          />
        </Filter>
      );
    };
    const labelElement = <FilterLabel>{label}</FilterLabel>;
    return (
      <AccordionItem
        label={labelElement}
        key={`Filter=${index}-${id}`}
        menu={menu}
      >
        <FilterList>{values.map(valueMapper)}</FilterList>
      </AccordionItem>
    );
  };
  return (
    <Accordion allowZeroExpanded allowMultipleExpanded>
      {filters.map(filterMapper)}
    </Accordion>
  );
};
