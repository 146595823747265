import { LinkStyled } from './Link.styled';

export interface LinkProps {
  children?: React.ReactNode;
  to?: string;
  target?: string;
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  target = '_self',
}) => {
  return (
    <LinkStyled href={to ?? ''} target={target} rel="noreferrer noopener">
      {children}
    </LinkStyled>
  );
};
