import { BreadcrumbFragment } from '@hultafors/snickers/types';

interface CreateBreadcrumbsInput {
  url: string;
  title?: string;
}

export function createBreadcrumbs(
  input: CreateBreadcrumbsInput[]
): BreadcrumbFragment {
  const id = `_breadcrumb-${Math.floor(Math.random() * 10000)}`;

  return {
    __typename: 'BreadcrumbRecord',
    id,
    links: input.map(({ title, url }) => ({
      id: `${id}-${url}`,
      title,
      url,
    })),
  };
}
