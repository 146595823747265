import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

import { GenericLargePlugProps } from './GenericLargePlug';

type GenericLargePlugStyledProps = ImageProps;

export const MobileImage = styled.div`
  position: absolute;
  inset: 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const DesktopImage = styled.div`
  position: absolute;
  inset: 0;
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

export const GenericLargePlugStyled = styled(
  RouterLink,
)<GenericLargePlugStyledProps>`
  display: flex;
  justify-content: center;
`;

export const BackgroundColors = styled.div`
  width: 100%;
  height: 424px;
  background: ${colors.gray5};

  &::after {
    content: '';
    block-size: 100%;
    background-image: url('/svg/color-puff-bg.svg');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
`;

interface ImageProps {
  $image?: boolean;
}

export const Content = styled.div<ImageProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: ${fontFamilies.faktPro};
  background: ${({ $image }) =>
    $image ? 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4))' : ''};
  padding: 16px 16px 24px;

  p {
    color: ${({ $image }) => ($image ? colors.white : colors.workwearBlack)};
    text-shadow: 0 0 10px
      ${({ $image }) => ($image ? colors.workwearBlack : colors.white)};
    margin: 0;
  }

  svg {
    margin-block-start: 8px;

    g {
      fill: ${({ $image }) => ($image ? colors.white : colors.workwearBlack)};
    }

    path.Line {
      transform: scaleX(1);
      transition: transform 0.3s ease;
    }
  }

  &:hover {
    svg path.Line {
      transform: scaleX(1.2);
    }
  }
`;

export const PlugIngress = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 45ch;
  padding: 14px 0;
  margin-block-start: 0;
`;

export const NameText = styled.p<ImageProps>`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-size: ${fontSizes.m};

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    inset-block-start: 40%;
    inset-inline-start: 0;
    inline-size: 30px;
    block-size: 1px;
    margin: 0 ${spacing.tiny} 0 0;
    background-color: ${({ $image }) =>
      $image ? colors.white : colors.workwearBlack};
  }
`;

export const Title = styled.p<ImageProps>`
  font-family: ${fontFamilies.fontHero};
  font-weight: ${fontWeights.normal};
  color: ${({ $image }) => ($image ? colors.white : colors.workwearBlack)};
  text-shadow: 0 0 10px
    ${({ $image }) => ($image ? colors.workwearBlack : colors.white)};
  font-size: 30px;
  line-height: 42px;
  margin: 12px 0 8px;
  letter-spacing: 0.2px;
  max-width: 550px;
`;

export const CenteredContent = styled.div<Pick<GenericLargePlugProps, 'image'>>`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .plug-button {
    min-inline-size: 188px;
    padding: 0 2rem;
    inline-size: fit-content;
  }
`;

export const SmallText = styled.p<ImageProps>`
  font-family: ${fontFamilies.faktPro};
  line-height: ${lineHeights.bodyMobile};
  color: ${({ $image }) => ($image ? colors.white : colors.workwearBlack)};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  text-shadow: 0 1px 8px rgb(0 0 0 / 25%);
  margin-block: 0 32px;

  @media screen and (max-width: ${breakpoints.bigMobile}) {
    display: none;
  }
`;
