import styled, { css } from 'styled-components';

import { colors, fontWeights } from '@hultafors/snickers/helpers';

import { ArrowSmall } from '../ArrowSmall/ArrowSmall';

interface GuideHeroStyledProps {
  $isBig?: boolean;
  $isResult?: boolean;
}

export const GuideHeroStyled = styled.div<GuideHeroStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $isBig }) => ($isBig ? '184px' : 'auto')};
  padding: 24px 20px;
  box-sizing: border-box;
  flex-direction: column;

  ${({ $isBig }) =>
    $isBig
    && css`
      position: relative;
      background-color: ${colors.workwearYellow};
      background-image: url('/svg/yellow-line-bg.svg');
      background-repeat: no-repeat;
      background-size: auto 184px;
      background-position: center;
      min-height: 184px;

      &::after {
        content: '';
        position: absolute;
        inset-inline-start: calc(50% + 33px);
        inset-block-start: 0;
        block-size: 100%;
        inline-size: calc(50% - 33px);
        background-color: ${colors.workwearYellow2};
      }
    `}

  p, h4 {
    text-align: center;
  }

  h3 {
    text-transform: uppercase;
    font-weight: ${fontWeights.bold};
    text-align: center;
    margin-block-end: 0;
    z-index: 1;
  }

  h4 {
    margin: 0;
    letter-spacing: 0.5px;

    ${({ $isResult }) =>
      $isResult
      && css`
        text-transform: uppercase;
        font-weight: 700;
        font-family: var(--font-mikro);
        letter-spacing: 1.2px;
        font-size: 20px;
      `}
  }

  .BackButton {
    position: absolute;
    display: flex;
    align-items: center;
    inset-block-start: 0;
    inset-inline-start: 20px;
    block-size: 56px;
    border: none;
    background: none;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    z-index: 1;
    cursor: pointer;
  }

  .StepCounter {
    position: absolute;
    inset-block-start: 16px;
    inset-inline-end: 20px;
    font-size: 14px;
  }

  .IntroText {
    z-index: 1;
  }
`;

export const BackArrow = styled(ArrowSmall)`
  transform: rotate(180deg);
  height: 14px;
  width: 10px;
  margin-inline-end: 8px;
`;
