import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/snickers/helpers';

export const StoreListStyled = styled.div`
  padding-block-end: 6rem;

  &:last-of-type {
    margin-block-end: 0;
  }

  .MapLink {
    text-decoration: none;
  }

  .LeftIconLink {
    position: relative;
    padding-inline-start: 20px;

    img,
    svg {
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: 50%;
      transform: translateY(-50%);
    }
  }

  a {
    text-decoration: none;
    font-size: 14px;
  }

  p,
  label {
    font-size: 14px;
  }

  .Address {
    margin: 0 0 30px;
  }

  .ViewOnMap {
    margin-block-end: 30px;
  }

  .Contact {
    display: flex;
    flex-direction: row;
    border-block-start: 1px solid ${colors.gray4};
    align-items: center;
    padding: 0;
    block-size: 60px;

    &.EmailAndPhoneWrapper {
      flex-direction: column;
      block-size: auto;
      padding: 20px 0;
      align-items: flex-start;

      @media screen and (min-width: ${breakpoints.mediumMobile}) {
        flex-direction: row;
        block-size: 60px;
        padding: 0;
        align-items: center;
      }
    }

    div {
      display: inline-flex;

      &.Email {
        padding: 0 0 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding: 0 ${spacing.small} 0 0;
          border-inline-end: 1px solid ${colors.gray4};
          block-size: 100%;
          align-items: center;
        }
      }

      &.Phone {
        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding-inline-start: ${spacing.small};
          block-size: 100%;
          align-items: center;
        }
      }
    }

    &.VisitSite {
      justify-content: flex-end;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          inline-size: 16px;
          block-size: 16px;
          margin-inline-start: 8px;
        }
      }
    }

    label {
      color: ${colors.gray2};
      inline-size: 100%;
      margin-block-end: 6px;
    }

    &.StoreSells {
      align-items: center;
      min-block-size: 90px;
      block-size: auto;

      .StoresWrapper {
        flex-direction: column;
      }

      a {
        text-decoration: underline;
      }
    }

    .Stores {
      inline-size: 100%;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }
`;
