import styled from 'styled-components';

/* import { colors } from '@hultafors/snickers/helpers'; */

export const ComponentStyled = styled.button`
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
  }
`;
