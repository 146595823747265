import styled from 'styled-components';

export const WorkwearGuideStyled = styled.div`
  h4 {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 30px;
  }

  .ContentWrapper {
    padding: 20px 20px 104px;
  }
`;
