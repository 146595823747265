import { JeansSize } from '@hultafors/snickers/types';

export const jeansSizesWomen: JeansSize[] = [
  {
    leg: 26,
    waist: [
      {
        size: 116,
        value: 24,
      },
      {
        size: 117,
        value: 25,
      },
      {
        size: 118,
        value: 26,
      },
      {
        size: 118,
        value: 27,
      },
      {
        size: 119,
        value: 28,
      },
      {
        size: 120,
        value: 29,
      },
      {
        size: 120,
        value: 30,
      },
      {
        size: 121,
        value: 31,
      },
      {
        size: 122,
        value: 32,
      },
      {
        size: 122,
        value: 33,
      },
      {
        size: 123,
        value: 34,
      },
      {
        size: 123,
        value: 35,
      },
      {
        size: 124,
        value: 36,
      },
      {
        size: 124,
        value: 37,
      },
      {
        size: 124,
        value: 38,
      },
      {
        size: 125,
        value: 39,
      },
      {
        size: 125,
        value: 40,
      },
      {
        size: 125,
        value: 41,
      },
      {
        size: 126,
        value: 42,
      },
      {
        size: 126,
        value: 43,
      },
      {
        size: 126,
        value: 44,
      },
      {
        size: 127,
        value: 45,
      },
      {
        size: 127,
        value: 46,
      },
      {
        size: 127,
        value: 47,
      },
    ],
  },
  {
    leg: 29,
    waist: [
      {
        size: 16,
        value: 24,
      },
      {
        size: 17,
        value: 25,
      },
      {
        size: 18,
        value: 26,
      },
      {
        size: 18,
        value: 27,
      },
      {
        size: 19,
        value: 28,
      },
      {
        size: 20,
        value: 29,
      },
      {
        size: 20,
        value: 30,
      },
      {
        size: 21,
        value: 31,
      },
      {
        size: 22,
        value: 32,
      },
      {
        size: 22,
        value: 33,
      },
      {
        size: 23,
        value: 34,
      },
      {
        size: 23,
        value: 35,
      },
      {
        size: 24,
        value: 36,
      },
      {
        size: 24,
        value: 37,
      },
      {
        size: 24,
        value: 38,
      },
      {
        size: 25,
        value: 39,
      },
      {
        size: 25,
        value: 40,
      },
      {
        size: 25,
        value: 41,
      },
      {
        size: 26,
        value: 42,
      },
      {
        size: 26,
        value: 43,
      },
      {
        size: 26,
        value: 44,
      },
      {
        size: 27,
        value: 45,
      },
      {
        size: 27,
        value: 46,
      },
      {
        size: 27,
        value: 47,
      },
    ],
  },
  {
    leg: 31,
    waist: [
      {
        size: 32,
        value: 24,
      },
      {
        size: 34,
        value: 25,
      },
      {
        size: 36,
        value: 26,
      },
      {
        size: 36,
        value: 27,
      },
      {
        size: 38,
        value: 28,
      },
      {
        size: 40,
        value: 29,
      },
      {
        size: 40,
        value: 30,
      },
      {
        size: 42,
        value: 31,
      },
      {
        size: 44,
        value: 32,
      },
      {
        size: 44,
        value: 33,
      },
      {
        size: 46,
        value: 34,
      },
      {
        size: 46,
        value: 35,
      },
      {
        size: 48,
        value: 36,
      },
      {
        size: 48,
        value: 37,
      },
      {
        size: 48,
        value: 38,
      },
      {
        size: 50,
        value: 39,
      },
      {
        size: 50,
        value: 40,
      },
      {
        size: 50,
        value: 41,
      },
      {
        size: 52,
        value: 42,
      },
      {
        size: 52,
        value: 43,
      },
      {
        size: 52,
        value: 44,
      },
      {
        size: 54,
        value: 45,
      },
      {
        size: 54,
        value: 46,
      },
      {
        size: 54,
        value: 47,
      },
    ],
  },
  {
    leg: 33,
    waist: [
      {
        size: 64,
        value: 24,
      },
      {
        size: 68,
        value: 25,
      },
      {
        size: 72,
        value: 26,
      },
      {
        size: 72,
        value: 27,
      },
      {
        size: 76,
        value: 28,
      },
      {
        size: 80,
        value: 29,
      },
      {
        size: 80,
        value: 30,
      },
      {
        size: 84,
        value: 31,
      },
      {
        size: 88,
        value: 32,
      },
      {
        size: 88,
        value: 33,
      },
      {
        size: 92,
        value: 34,
      },
      {
        size: 92,
        value: 35,
      },
      {
        size: 96,
        value: 36,
      },
      {
        size: 96,
        value: 37,
      },
      {
        size: 96,
        value: 38,
      },
      {
        size: 100,
        value: 39,
      },
      {
        size: 100,
        value: 40,
      },
      {
        size: 100,
        value: 41,
      },
      {
        size: 104,
        value: 42,
      },
      {
        size: 104,
        value: 43,
      },
      {
        size: 104,
        value: 44,
      },
      {
        size: 108,
        value: 45,
      },
      {
        size: 108,
        value: 46,
      },
      {
        size: 108,
        value: 47,
      },
    ],
  },
  {
    leg: 36,
    waist: [
      {
        size: 164,
        value: 24,
      },
      {
        size: 168,
        value: 25,
      },
      {
        size: 172,
        value: 26,
      },
      {
        size: 172,
        value: 27,
      },
      {
        size: 176,
        value: 28,
      },
      {
        size: 180,
        value: 29,
      },
      {
        size: 180,
        value: 30,
      },
      {
        size: 184,
        value: 31,
      },
      {
        size: 188,
        value: 32,
      },
      {
        size: 188,
        value: 33,
      },
      {
        size: 192,
        value: 34,
      },
      {
        size: 192,
        value: 35,
      },
      {
        size: 196,
        value: 36,
      },
      {
        size: 196,
        value: 37,
      },
      {
        size: 196,
        value: 38,
      },
      {
        size: 200,
        value: 39,
      },
      {
        size: 200,
        value: 40,
      },
      {
        size: 200,
        value: 41,
      },
      {
        size: 204,
        value: 42,
      },
      {
        size: 204,
        value: 43,
      },
      {
        size: 204,
        value: 44,
      },
      {
        size: 208,
        value: 45,
      },
      {
        size: 208,
        value: 46,
      },
      {
        size: 208,
        value: 47,
      },
    ],
  },
];
