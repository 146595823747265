import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

interface H2StyledProps {
  $center?: boolean;
}

export const H2Styled = styled.h2<H2StyledProps>`
  font-size: ${fontSizes.h2};
  line-height: ${lineHeights.xs};
  font-weight: ${fontWeights.medium};
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
`;
