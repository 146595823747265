import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

export type HTagStyleTypes = 'header2' | 'header3' | 'header4';

interface BreakpointBase {
  breakpoint?: Breakpoint;
}

type HTagStyle = BreakpointBase & {
  styleType: HTagStyleTypes;
};

export type HTagStyleTypeProp = HTagStyleTypes | HTagStyle[];

type HTagWhiteTextStyle = BreakpointBase & {
  whiteText: boolean;
};

export type HTagWhiteTextProp = boolean | HTagWhiteTextStyle[];

interface HTagStyledProps {
  $styleType?: HTagStyleTypeProp;
  $whiteText?: HTagWhiteTextProp;
  $center?: boolean;
  $uppercase?: boolean;

  $margin?: string;
}

const fontStyle = (styleType: HTagStyledProps['$styleType']) => {
  switch (styleType) {
    case 'header2':
      return css`
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
        font-weight: ${fontWeights.bold};
        font-family: ${fontFamilies.mikro};
        letter-spacing: 0.02rem;
      `;
    case 'header3':
      return css`
        font-size: ${fontSizes.header3};
        line-height: ${lineHeights.header3};
        font-weight: ${fontWeights.bold};
        font-family: ${fontFamilies.mikro};
      `;
    case 'header4':
      return css`
        font-size: ${fontSizes.header4};
        line-height: ${lineHeights.header4};
        font-weight: ${fontWeights.medium};
        font-family: ${fontFamilies.faktPro};
      `;
    default:
      return css`
        font-size: ${fontSizes.h1};
        line-height: ${lineHeights.header1};
        font-weight: ${fontWeights.normal};
        font-family: ${fontFamilies.fontHero};
      `;
  }
};

const generalFontStyle = ({
  $center,
  $uppercase,
  $styleType,
  $margin,
}: HTagStyledProps) => css`
  letter-spacing: 0.01em;
  color: ${colors.workwearBlack};
  text-align: ${$center ? 'center' : 'initial'};
  text-transform: ${$uppercase ? 'uppercase' : 'none'};
  ${handleStyleType($styleType)}
  margin: ${$margin};
`;

const handleStyleType = ($styleType: HTagStyledProps['$styleType']) => {
  if (Array.isArray($styleType)) {
    return $styleType.map(({ breakpoint, styleType }) => {
      if (breakpoint) {
        return css`
          @media screen and (min-width: ${breakpoints[breakpoint]}) {
            ${fontStyle(styleType)}
          }
        `;
      }
      return fontStyle(styleType);
    });
  }
  return fontStyle($styleType);
};

export const StyledHTag = styled.h1<HTagStyledProps>`
  ${generalFontStyle};
`;
