import { EmptyProductStyled } from './EmptyProduct.styled';

export const EmptyProduct: React.FC = () => {
  return (
    <EmptyProductStyled>
      <div className="ImagePlaceholder" />
      <div className="ProductInfoPlaceholder">
        <div className="ProductDetailsPlaceholder">
          <span />
          <span />
        </div>
        <span className="ProductNamePlaceholder" />
      </div>
    </EmptyProductStyled>
  );
};
