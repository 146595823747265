import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontFamilies,
  FontFamilies,
  fontSizes,
  FontSizes,
  fontWeights,
  FontWeights,
  lineHeights,
  spacing,
  Spacing,
} from '@hultafors/snickers/helpers';

interface HeadingGeneralStyledProps {
  $isCentered?: boolean;
  $underline?: boolean;
  $headingRank?: number;
  $weight?: FontWeights;
  $family?: FontFamilies;
  $size?: FontSizes;
  $bottomSpacing?: Spacing;
  $hasTopSpacing?: boolean;
  $topSpacing?: Spacing;
  $customFamily?: CSSProperties['fontFamily'];
  $customFontSize?: CSSProperties['fontSize'];
  $customWeight?: CSSProperties['fontWeight'];
  $uppercase?: boolean;
}

export const Heading1 = styled.h1<HeadingGeneralStyledProps>`
  font-family: ${({ $customFamily }) => $customFamily ?? fontFamilies.mikro};
  font-size: ${({ $customFontSize }) => $customFontSize ?? fontSizes.h1};
  line-height: ${lineHeights.xs};
  font-weight: ${({ $customWeight }) => $customWeight ?? fontWeights.bold};
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : '')};
  letter-spacing: 1px;
  ${({ $bottomSpacing }) =>
    $bottomSpacing
    && css`
      margin-block-end: ${spacing[$bottomSpacing]};
    `}
  ${({ $hasTopSpacing }) =>
    !$hasTopSpacing
    && css`
      margin-block-start: 0;
    `}
`;

export const Heading2 = styled.h2<HeadingGeneralStyledProps>`
  font-family: ${({ $customFamily }) => $customFamily ?? fontFamilies.mikro};
  font-size: ${({ $customFontSize }) => $customFontSize ?? fontSizes.h2};
  line-height: ${lineHeights.xs};
  font-weight: ${({ $customWeight }) => $customWeight ?? fontWeights.medium};
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : '')};
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  ${({ $bottomSpacing }) =>
    $bottomSpacing
    && css`
      margin-block-end: ${spacing[$bottomSpacing]};
    `}
  ${({ $hasTopSpacing }) =>
    !$hasTopSpacing
    && css`
      margin-block-start: 0;
    `}
`;

export const Heading3 = styled.h3<HeadingGeneralStyledProps>`
  font-family: ${({ $customFamily }) => $customFamily ?? fontFamilies.mikro};
  font-size: ${({ $customFontSize }) => $customFontSize ?? fontSizes.h3};
  line-height: 28px;
  font-weight: ${({ $customWeight }) => $customWeight ?? fontWeights.bold};
  letter-spacing: 2px;
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : '')};
  margin-block-start: 0;
  ${({ $bottomSpacing }) =>
    $bottomSpacing
    && css`
      margin-block-end: ${spacing[$bottomSpacing]};
    `}
  ${({ $hasTopSpacing }) =>
    !$hasTopSpacing
    && css`
      margin-block-start: 0;
    `}
`;

export const Heading4 = styled.h4<HeadingGeneralStyledProps>`
  font-family: ${({ $customFamily }) => $customFamily ?? fontFamilies.mikro};
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : '')};
  font-size: ${({ $customFontSize }) => $customFontSize ?? fontSizes.header4};
  font-weight: ${({ $customWeight }) => $customWeight ?? fontWeights.medium};
  text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};

  ${({ $bottomSpacing }) =>
    $bottomSpacing
    && css`
      margin-block-end: ${spacing[$bottomSpacing]};
    `}
  ${({ $hasTopSpacing }) =>
    !$hasTopSpacing
    && css`
      margin-block-start: 0;
    `}
@media screen and (min-width: ${breakpoints.mobileMax}) {
    text-decoration: none;
  }
`;

export const Heading5 = styled.h5<HeadingGeneralStyledProps>`
  font-family: ${({ $customFamily }) => $customFamily ?? fontFamilies.mikro};
  text-align: ${({ $isCentered }) => ($isCentered ? 'center' : '')};
  font-size: ${({ $customFontSize }) => $customFontSize ?? fontSizes.m};
  font-weight: ${({ $customWeight }) => $customWeight ?? fontWeights.medium};
  text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
  margin: 0;

  ${({ $bottomSpacing }) =>
    $bottomSpacing
    && css`
      margin-block-end: ${spacing[$bottomSpacing]};
    `}
  ${({ $hasTopSpacing }) =>
    !$hasTopSpacing
    && css`
      margin-block-start: 0;
    `}
@media screen and (min-width: ${breakpoints.mobileMax}) {
    text-decoration: none;
  }
`;
