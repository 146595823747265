import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { WebPage, WithContext } from 'schema-dts';

import { MetaData } from '@hultafors/shared/helpers';
import { Language } from '@hultafors/shared/types';

import { useGlobal, useOrganization } from '@hultafors/snickers/hooks';

import { PageStyled } from './Page.styled';

const DynamicWorkwearGuide = dynamic(
  () => import('../WorkwearGuide/WorkwearGuide'),
);

const DynamicSizeGuide = dynamic(() => import('../SizeGuide/SizeGuide'));

const MicroData = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MicroData),
);

export interface PageProps {
  metadata?: MetaData;
  children?: React.ReactNode;
  className?: string;
  fullscreen?: boolean;
  canonicalPath?: string;
  testId?: string;
  hrefLanguages?: Language[];
  microData?: Partial<WebPage>;
}

/**
 * Abstract view wrapper
 * Implements optional metadata with a <Head /> components
 */
export const Page: React.FC<PageProps> = ({
  metadata,
  children,
  className,
  fullscreen,
  canonicalPath,
  testId,
  hrefLanguages,
  microData,
}) => {
  const { asPath, query } = useRouter();
  const { settings } = useGlobal();
  const organization = useOrganization();
  const pageMicroData: WithContext<WebPage> | undefined = useMemo(() => {
    if (!microData) {
      return;
    }
    return {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      ...microData,
    };
  }, [microData]);
  const url = canonicalPath || asPath.split('?')[0];
  let canonical = `https://www.snickersworkwear.com${url}`.replace(/\/$/, '');
  if (settings?.hostname) {
    canonical = `https://${settings.hostname}${url}`.replace(/\/$/, '');
  }

  /* The US site has different product ID's than the other domains.
     That means that the US site puts the wrong hreflang in the tags when
     on the product page. All other pages are good.
  */

  function hasUniqueProductId(lang: string) {
    return (
      lang.toLowerCase() === 'en-us'
      && Object.prototype.hasOwnProperty.call(query, 'productId')
    );
  }

  let usIsDefault = false;

  if (settings?.language?.lang) {
    usIsDefault = hasUniqueProductId(settings?.language?.lang);
  }

  // Removes the trailing slash at the end of the url
  function getSlicedUrl(url?: string) {
    if (url?.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  function languagesMapper({
    lang,
    urlPrefix,
    hostname: marketHostname,
  }: Language) {
    const hrefLang = lang.toLowerCase();
    let path = getSlicedUrl(url);
    if (urlPrefix) {
      path = `/${urlPrefix}${getSlicedUrl(url)}`;
    }
    const href = `https://${marketHostname}${path}`;

    if (hasUniqueProductId(lang)) {
      return null;
    }
    if (hrefLang === 'en') {
      return (
        <link rel="alternate" hrefLang="x-default" href={href} key={href} />
      );
    }
    return <link rel="alternate" hrefLang={hrefLang} href={href} key={href} />;
  }

  return (
    <PageStyled
      className={className}
      $fullscreen={fullscreen}
      data-test-id={testId}
    >
      <Head>
        {canonical && <link rel="canonical" href={canonical} key="canonical" />}
        {metadata?.title && (
          <>
            <title>{metadata.title}</title>
            <meta property="og:title" content={metadata.title} />
            <meta name="twitter:title" content={metadata.title} />
          </>
        )}
        {metadata?.description && (
          <>
            <meta name="description" content={metadata.description} />
            <meta property="og:description" content={metadata.description} />
            <meta name="twitter:description" content={metadata.description} />
          </>
        )}
        {metadata?.type && <meta property="og:type" content={metadata.type} />}
        {metadata?.image?.url && (
          <>
            <meta property="og:image" content={metadata.image.url} />
            <meta property="twitter:image" content={metadata.image.url} />
          </>
        )}
        {metadata?.image?.width && (
          <meta property="og:image:width" content={`${metadata.image.width}`} />
        )}
        {metadata?.image?.height && (
          <meta
            property="og:image:height"
            content={`${metadata.image.height}`}
          />
        )}
        {metadata?.image?.alt && (
          <meta property="twitter:image:alt" content={metadata.image.alt} />
        )}
        <meta name="twitter:card" content="summary" />
        {!usIsDefault && hrefLanguages?.map(languagesMapper)}
        {usIsDefault && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={canonical}
            key={canonical}
          />
        )}
      </Head>
      {children}
      {/* // TODO make dynamic workwearguide & sizeGuide */}
      <DynamicWorkwearGuide />
      <DynamicSizeGuide />
      <MicroData data={organization} />
      {pageMicroData && <MicroData data={pageMicroData} />}
    </PageStyled>
  );
};
