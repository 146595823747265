import { TabsStyled } from './Tabs.styled';

export interface TabsProps {
  children?: React.ReactNode;
  desktopMenu?: boolean;
}

export const Tabs: React.FC<TabsProps> = ({ desktopMenu, children }) => {
  return <TabsStyled $desktopMenu={desktopMenu}>{children}</TabsStyled>;
};
