import Image from 'next/image';

import { PersonInfoFragment } from '@hultafors/snickers/types';

import { Paragraph } from '../../../Paragraph/Paragraph';

import {
  Byline,
  BylineWrapper,
  Container,
  Details,
  DetailsWrapper,
  IntervieweeName,
} from './PersonInfoBlock.styled';

type PersonInfoProps = Omit<PersonInfoFragment, 'id'>;

export const PersonInfoBlock: React.FC<PersonInfoProps> = ({
  age = 0,
  ageLabel = 'Age',
  family = '',
  familyLabel = 'Family',
  favoriteGarment = '',
  favoriteGarmentLabel = 'Favorite garment',
  image,
  lives = '',
  name,
  placeOfWork = '',
  profession = '',
  professionLabel = 'Profession',
  size = '',
  sizeLabel = 'Size',
  whereILiveLabel = 'Where I live',
  workplaceLabel = 'Place of work',
}) => {
  return (
    <Container>
      <BylineWrapper>
        <Byline>
          {image?.responsiveImage?.src && (
            <Image
              src={image.responsiveImage.src}
              alt={image.alt || ''}
              fill
              blurDataURL={image.responsiveImage?.base64 || ''}
              placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              sizes="calc(100vw - 40px), (min-width: 600px) calc(50vw - 30px), (min-width: 980px) 460px, (min-width: 1025px) 455px"
            />
          )}
        </Byline>
      </BylineWrapper>
      <DetailsWrapper>
        <Details>
          {name && <IntervieweeName>{name}</IntervieweeName>}
          {size && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {sizeLabel}
                :
                {' '}
              </span>
              {size}
            </Paragraph>
          )}
          {age && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {ageLabel}
                :
                {' '}
              </span>
              {age}
            </Paragraph>
          )}
          {family && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {familyLabel}
                :
                {' '}
              </span>
              {family}
            </Paragraph>
          )}
          {lives && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {whereILiveLabel}
                :
                {' '}
              </span>
              {lives}
            </Paragraph>
          )}
          {profession && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {professionLabel}
                :
                {' '}
              </span>
              {profession}
            </Paragraph>
          )}
          {placeOfWork && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {workplaceLabel}
                :
                {' '}
              </span>
              {placeOfWork}
            </Paragraph>
          )}
          {favoriteGarment && (
            <Paragraph>
              <span className="IntervieweeLabel">
                {favoriteGarmentLabel}
                :
                {' '}
              </span>
              {favoriteGarment}
            </Paragraph>
          )}
        </Details>
      </DetailsWrapper>
    </Container>
  );
};
