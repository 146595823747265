import { useCart } from '@hultafors/snickers/hooks';

import { H4 } from '../H4/H4';
import { LinkButton } from '../LinkButton/LinkButton';
import { ProductListItem } from '../ProductListItem/ProductListItem';
import { SlideIn } from '../SlideIn/SlideIn';

import { CartStyled } from './Cart.styled';

export const CART_DRAWER_ID = 'CartDrawer';

export interface CartProps {
  isOpen: boolean;
  toggleMenu<T = string>(key?: T | null): void;
  header: string;
  clearText: string;
  sendText: string;
  emailText: string;
  shoppingListText: string;
  sendShoppingListText: string;
  sizeText?: string;
}

export const Cart: React.FC<CartProps> = ({
  isOpen,
  toggleMenu,
  header,
  clearText,
  sendText,
  emailText,
  shoppingListText,
  sendShoppingListText,
  sizeText = 'Size',
}) => {
  const { cart, deleteCartItem, deleteCart } = useCart();
  const getCartString = (cart: any) => {
    let result = '';
    cart.map((item: any) => {
      let size = '';

      if (item.size) {
        size = item.size.name
          ? `${item.size.name} ${item.size.value}`
          : item.size.value;
      }

      result += `${item.sku} ${item.name}, ${item.color.text}`;
      result += ` ${size}`;
      result += '%0D%0A%0D%0A';
    });
    return result;
  };

  const createMailToLink = (cart: any) => {
    return `?subject=${shoppingListText}&body=${getCartString(cart)}`;
  };

  return (
    <CartStyled>
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={`${header} (${cart.length})`}
        headerIcon="/assets/gfx/basket.svg"
        clearText={clearText}
        clearAll={deleteCart}
        padContent
        fromLeft={false}
        isCart
        id={CART_DRAWER_ID}
      >
        {cart.map((product, i) => {
          return (
            <ProductListItem
              key={`CartRow-${i}`}
              product={product}
              removeItem={deleteCartItem}
              sizeText={sizeText}
            />
          );
        })}

        <div className="SendListWrapper">
          <H4 large>{sendShoppingListText}</H4>
          <LinkButton
            large
            text={sendText}
            mailTo
            mailToLink={createMailToLink(cart)}
          />
        </div>
      </SlideIn>
    </CartStyled>
  );
};
