import { FC } from 'react';

import { RouterLink } from '../RouterLink/RouterLink';

interface CTAButtonProps {
  ctaText: string;
  ctaLink: string;
}

export const CTAButton: FC<CTAButtonProps> = ({ ctaLink, ctaText }) => {
  return <RouterLink href={ctaLink}>{ctaText}</RouterLink>;
};
