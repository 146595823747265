import FilterSvg from './filter.svg';
import {
  FilterWrapper,
  MobileFilterBar,
  MobileFilterButton,
} from './FilterSort.styled';
export interface FilterSortProps {
  toggleFilter(...args: any[]): unknown;
  children?: React.ReactNode;
}

export const FilterSort: React.FC<FilterSortProps> = ({
  toggleFilter,
  children,
}) => (
  <>
    <MobileFilterBar>
      <MobileFilterButton onClick={toggleFilter} aria-label="Filter button">
        <FilterSvg
          aria-hidden={false}
          focusable={false}
          width={24}
          height={24}
        />
      </MobileFilterButton>
    </MobileFilterBar>
    <FilterWrapper>{children}</FilterWrapper>
  </>
);
