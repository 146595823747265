import { OfficeRecord } from '@hultafors/snickers/types';

import LinkSvg from './link.svg';
import MailSvg from './mail.svg';
import {
  Address,
  EmailAndPhoneWrapper,
  LeftIconLink,
  OfficeCardStyled,
  ViewOnMap,
  Website,
} from './OfficeCard.styled';
import PhoneSvg from './phone.svg';
import PlaceTagSvg from './place-tag.svg';

interface OfficeCardProps {
  office: OfficeRecord;
  viewOnMapLabel?: string;
  localWebLabel?: string;
  companyName?: string;
}

export const OfficeCard: React.FC<OfficeCardProps> = ({
  office,
  viewOnMapLabel = '',
  localWebLabel = '',
}) => {
  const generateGoogleMapLinks = ({
    name,
    country,
    companyName,
    address,
    zipcode,
  }: Partial<OfficeRecord>) => {
    // direct links to google maps based on address data
    const baseLink = 'https://www.google.com/maps/search/?';
    const query = new URLSearchParams({
      api: '1',
      query: [name, country, companyName, address, zipcode]
        .filter((value) => !!value)
        .join('+'),
    }).toString();
    return `${baseLink}${query}`;
  };
  return (
    <OfficeCardStyled>
      <Address>
        {office.companyName}
        {office.address}
        {office.zipcode && (
          <>
            <br />
            {office.zipcode}
          </>
        )}
        {office.country && (
          <>
            <br />
            {office.country}
          </>
        )}
      </Address>
      <ViewOnMap>
        <LeftIconLink
          href={generateGoogleMapLinks(office)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PlaceTagSvg focusable={false} aria-hidden={true} width={11} />
          {viewOnMapLabel}
        </LeftIconLink>
      </ViewOnMap>

      <EmailAndPhoneWrapper>
        {office.email && (
          <LeftIconLink href={`mailto:${office.email}`}>
            <MailSvg focusable={false} aria-hidden={true} width={14} />
            {office.email}
          </LeftIconLink>
        )}
        {office.phoneNumber && (
          <LeftIconLink href={`tel:${office.phoneNumber}`}>
            <PhoneSvg focusable={false} aria-hidden={true} width={10} />
            {office.phoneNumber}
          </LeftIconLink>
        )}
      </EmailAndPhoneWrapper>
      {office.companyWebsite && (
        <Website
          href={office.companyWebsite}
          target="_blank"
          rel="noopener noreferrer"
        >
          {localWebLabel}
          <LinkSvg focusable={false} aria-hidden={true} width={16} />
        </Website>
      )}
    </OfficeCardStyled>
  );
};
