import { createGlobalStyle, css } from 'styled-components';
import '@hultafors/shared/style/main.scss';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';
export const HEADER_HEIGHT = '56px' as const;
export const OPTIONS_HEIGHT = '59px' as const;

const cssColors = css`
  --color-allroundwork-orange: ${colors.allroundworkOrange};
  --color-allroundwork-orange2: ${colors.allroundworkOrange2};
  --color-black: ${colors.black};
  --color-boxshadow-transparent: ${colors.boxshadowTransparent};
  --color-flexiwork-green: ${colors.flexiworkGreen};
  --color-flexiwork-green2: ${colors.flexiworkGreen2};
  --color-gray1: ${colors.gray1};
  --color-gray2: ${colors.gray2};
  --color-gray3: ${colors.gray3};
  --color-gray4: ${colors.gray4};
  --color-gray5: ${colors.gray5};
  --color-gray-btn-hover: ${colors.grayBtnHover};
  --color-highlight-blue: ${colors.highlightBlue};
  --color-litework-blue: ${colors.liteworkBlue};
  --color-litework-blue2: ${colors.liteworkBlue2};
  --color-overlay-transparent: ${colors.overlayTransparent};
  --color-protecwork-red: ${colors.protecworkRed};
  --color-protecwork-red2: ${colors.protecworkRed2};
  --color-ruffwork-brown: ${colors.ruffworkBrown};
  --color-ruffwork-brown2: ${colors.ruffworkBrown2};
  --color-solidBlack: ${colors.solidBlack};
  --color-solidBlackHover: ${colors.solidBlackHover};
  --color-toggleGray: ${colors.toggleGray};
  --color-warning: ${colors.warning};
  --color-white: ${colors.white};
  --color-workwearBlack: ${colors.workwearBlack};
  --color-workwearBlack2: ${colors.workwearBlack2};
  --color-workwearYellow: ${colors.workwearYellow};
  --color-workwearYellow2: ${colors.workwearYellow2};
`;

const cssFontSizes = css`
  --font-size-header2: ${fontSizes.header2};
  --font-size-header3: ${fontSizes.header3};
  --font-size-header4: ${fontSizes.header4};
  --font-size-content-mobile: ${fontSizes.contentMobile};
  --font-size-mini1: ${fontSizes.mini1};
  --font-size-mini2: ${fontSizes.mini2};
  --font-size-mini2-mobile: ${fontSizes.mini2mobile};
  --font-size-s: ${fontSizes.s};
  --font-size-m: ${fontSizes.m};
  --font-size-l: ${fontSizes.l};
  --font-size-body: ${fontSizes.body};
  --font-size-mega-body: ${fontSizes.megabody};
  --font-size-super-mega-body: ${fontSizes.supermegabody};
  --font-size-product-mobile: ${fontSizes.productMobile};
  --font-size-hero-title: ${fontSizes.heroTitle};
  --font-size-h3: ${fontSizes.h3};
  --font-size-h2: ${fontSizes.h2};
  --font-size-h1: ${fontSizes.h1};
  --font-size-h1-mobile: ${fontSizes.h1Mobile};
  --font-size-h1-large: ${fontSizes.h1Large};
  --font-size-h1-large-mobile: ${fontSizes.h1LargeMobile};
  --font-size-header1-content: ${fontSizes.header1Content};
  --font-size-header2-content: ${fontSizes.header2Content};
  --font-size-header3-content: ${fontSizes.header3Content};
`;

const cssFontWeights = css`
  --font-weight-normal: ${fontWeights.normal};
  --font-weight-medium: ${fontWeights.medium};
  --font-weight-thick: ${fontWeights.thick};
  --font-weight-thicker: ${fontWeights.thicker};
  --font-weight-bold: ${fontWeights.bold};
  --font-weight-black: ${fontWeights.black};
`;

const cssVariables = css`
  :root {
    ${cssColors};
    ${cssFontSizes};
    ${cssFontWeights};
    --header-height: ${HEADER_HEIGHT};
    --options-height: ${OPTIONS_HEIGHT};
    --content-max-width: 1440px;
    --page-margin: 20px;
    --page-margin-2x: 40px;
  }
`;

const globalStyle = css`
  html {
    font-size: 14px;
    min-height: 100vh;
  }

  body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: ${fontWeights.normal};
    color: ${colors.workwearBlack};
    letter-spacing: 0.2px;
    background-color: ${colors.white};
    overflow-x: hidden;
  }

  p,
  div,
  form,
  input,
  textarea,
  button,
  select,
  article,
  section {
    font-size: 1rem;
    font-weight: 300;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  #root {
    min-height: 100vh;
  }

  .map {
    position: relative;
    width: 100%;
  }

  .IE-Modal-Blur {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: ${colors.black};
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .IE-Modal-Contents {
    width: 90%;
    max-width: 500px;
    text-align: center;
    color: ${colors.black};
    background-color: ${colors.white};
    padding: 20px;
    margin: 0 auto;
    transform: translateY(50%);
  }

  .IE-Modal-Contents img {
    width: 60px;
    margin-block-end: 16px;
  }

  #__next {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
