import Image from 'next/image';

import { WorkwearGuideCategory } from '@hultafors/snickers/types';

import AngleSvg from './angle.svg';
import {
  CategoryCard,
  ImageWrapper,
  TextWrapper,
  Title,
} from './WorkwearGuideCategoryCard.styled';

interface WorkwearGuideCategoryCardProps {
  selectCategory(category: WorkwearGuideCategory): void;
  category: WorkwearGuideCategory;
  selected?: boolean;
}
export const WorkwearGuideCategoryCard: React.FC<
  WorkwearGuideCategoryCardProps
> = ({ selectCategory, category, selected }) => {
  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      selectCategory(category);
    }
  };
  const onClick = () => {
    selectCategory(category);
  };
  return (
    <CategoryCard $selected={selected} onClick={onClick} onKeyDown={onKeyDown}>
      <ImageWrapper>
        {category?.image?.responsiveImage?.src && (
          <Image
            src={category.image.responsiveImage.src}
            fill
            style={{ objectFit: 'cover' }}
            alt={category.name}
            blurDataURL={category.image.responsiveImage?.base64 || ''}
            placeholder={
              category.image.responsiveImage?.base64 ? 'blur' : 'empty'
            }
          />
        )}
      </ImageWrapper>
      <TextWrapper>
        <AngleSvg width={14} height={32} aria-hidden={true} focusable="false" />
        <Title>{category.name}</Title>
      </TextWrapper>
    </CategoryCard>
  );
};
