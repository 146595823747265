import { JeansSizeLists } from '@hultafors/snickers/types';

import { aggregateJeansLists } from '../aggregate-jeans-lists/aggregate-jeans-lists';
import {
  DEFAULT_SIZE_LISTS,
  jeansSizesMen,
  jeansSizesMen3,
  jeansSizesWomen,
} from '../data';
import { GENDERS, getCategoryFromSku, SIZE_CATEGORIES } from '../sizes';

export const getSizesForJeansGuide = (sku: string): JeansSizeLists => {
  const sizeLists: JeansSizeLists = { ...DEFAULT_SIZE_LISTS };
  if (sku) {
    const { gender, name } = getCategoryFromSku(sku);
    // TODO lazy load sizes and set
    switch (gender) {
      case GENDERS['MEN']:
        if (name === SIZE_CATEGORIES['TROUSERS']) {
          sizeLists.list = jeansSizesMen;
        } else {
          sizeLists.list = jeansSizesMen3;
        }
        break;
      case GENDERS['WOMEN']:
        sizeLists.list = jeansSizesWomen;
        break;
      default:
        sizeLists.list = jeansSizesMen;
    }
  } else {
    sizeLists.men = jeansSizesMen;
    sizeLists.men3 = jeansSizesMen3;
    sizeLists.women = jeansSizesWomen;

    // Aggregate men and women sizes
    sizeLists.list = aggregateJeansLists(sizeLists);
  }
  return sizeLists;
};
