import { ProductDetailsValue } from '@hultafors/shared/types';

import { ProductColor } from '../ProductColor/ProductColor';

import {
  ActiveProductColorText,
  ColorList,
  ProductColorsContainer,
} from './DetailsViewProductColors.styled';

export interface DetailsViewProductColorsProps {
  colors: ProductDetailsValue[];
  setColor(color: ProductDetailsValue): void;
  color: ProductDetailsValue;
}

export const DetailsViewProductColors: React.FC<
  DetailsViewProductColorsProps
> = ({ colors = [], color, setColor }) => {
  const colorMapper = (item: ProductDetailsValue) => {
    return (
      <ProductColor
        key={`product-color-${item.text}`}
        color={item}
        setColor={setColor}
        selectedColor={color}
      />
    );
  };
  return (
    <ProductColorsContainer>
      {color?.text && (
        <ActiveProductColorText>{color.text}</ActiveProductColorText>
      )}
      <ColorList>{colors.map(colorMapper)}</ColorList>
    </ProductColorsContainer>
  );
};
