import ArrowSmallSvg from './arrow-small.svg';

interface ArrowSmallProps extends React.SVGProps<SVGSVGElement> {
  title?: string;
  className?: string;
}

export const ArrowSmall: React.FC<ArrowSmallProps> = ({
  className,
  width = 7,
  height = 12,
  ...rest
}) => {
  return (
    <ArrowSmallSvg
      aria-hidden={true}
      focusable={false}
      className={className}
      width={width}
      height={height}
      {...rest}
    />
  );
};
