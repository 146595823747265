import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/snickers/helpers';

import { AccordionItem } from '../AccordionItem/AccordionItem';
import { MobileMenuWrapper } from '../MobileMenuWrapper/MobileMenuWrapper';
import { RouterLink } from '../RouterLink/RouterLink';

export const Inner = styled.div`
  overflow-x: hidden;
`;
export const SearchWrapper = styled(MobileMenuWrapper)`
  background-color: ${colors.gray5};
  min-height: 48px;
`;

export const MenuWrapper = styled(MobileMenuWrapper)`
  margin-block-start: 20px;
  border-block-end: 1px solid ${colors.gray4};
`;

export const MenuLinksWrapper = styled.div`
  > * {
    margin-block-start: 8px;
  }
`;

export const MenuStyled = styled.div`
  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

const MenuLinkStyle = css`
  display: flex;
  align-items: center;
  min-height: 48px;
  font-size: 20px;
  letter-spacing: 0.5px;
  padding-block: 8px;

  &:empty {
    display: none;
  }

  &:not(:first-child) {
    margin-block-start: 8px;
  }
`;

export const MenuLinkLabel = styled.span`
  ${MenuLinkStyle};
  padding: 0;
  min-height: inherit;
`;

interface MenuLinkProps {
  $level?: number;
}

export const MenuLink = styled(RouterLink)<MenuLinkProps>`
  ${MenuLinkStyle};

  &:empty {
    display: none;
  }

  ${({ $level }) =>
    $level
    && css`
      padding-inline-start: ${$level * 20}px;
    `};
`;

export const MenuAccordionItem = styled(AccordionItem)``;
