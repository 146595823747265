import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const FindRetailersListStyled = styled.div`
  margin-block-end: ${spacing.medium};

  .Accordion > div {
    border-block-start: 1px solid ${colors.gray4};

    &:first-of-type {
      border-block-start: none;
    }
  }

  .CollapseHeader {
    block-size: 72px;
    cursor: pointer;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
  }

  .CollapseContent {
    padding: 0;

    p {
      margin-block-end: ${spacing.small};
      line-height: ${lineHeights.body};
    }
  }
`;

export const StoreLabel = styled.span``;

export const StoreTitle = styled.span``;

export const StoreSubTitle = styled.span`
  font-size: ${fontSizes.m};
  color: ${colors.gray1};
  width: 100%;
  display: inline-block;
  margin-block-start: 0.25rem;
`;
