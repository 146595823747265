import { useEffect, useRef, useState } from 'react';

import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import { useWindowSize } from 'react-use';

import { breakpointsRaw } from '@hultafors/shared/constants';

import { VideoFragment } from '@hultafors/snickers/types';

import {
  ControlButton,
  MuxPlayerStyled,
  VideoControls,
  VideoWrapper,
} from './VideoPlayerBlock.styled';

export const VideoPlayerBlock: React.FC<VideoFragment> = ({
  video,
  videoThumbnail,
}) => {
  const videoPlayer = useRef<MuxPlayerRefAttributes | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [paused, setPaused] = useState<boolean>(true);
  const [playbackId, setPlaybackId] = useState<string | undefined>(
    video?.video?.muxPlaybackId ?? undefined,
  );
  const [placeholder, setPlaceholder] = useState<string | undefined>(
    video?.blurUpThumb ?? undefined,
  );

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
      setPlaceholder(video?.blurUpThumb ?? undefined);
    }
  }, [width]);

  function playVideo() {
    if (!videoPlayer?.current) {
      return;
    }
    if (paused === true) {
      setPaused(false);
      videoPlayer.current.play();
    }

    if (paused === false) {
      setPaused(true);
      videoPlayer.current.pause();
    }
  }

  if (!playbackId) {
    return null;
  }

  return (
    <VideoWrapper $paused={paused}>
      <MuxPlayerStyled
        ref={videoPlayer}
        autoPlay={false}
        poster={videoThumbnail?.url}
        playbackId={playbackId}
        placeholder={placeholder}
      />
      <VideoControls>
        <ControlButton
          ref={buttonRef}
          title="play"
          onClick={playVideo}
          id="controls"
        />
      </VideoControls>
    </VideoWrapper>
  );
};
