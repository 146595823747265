import { SIZE_GUIDE_STEPS } from '@hultafors/snickers/product-helpers';
import { SizeSelection } from '@hultafors/snickers/types';

import { Button } from '../../Button/Button';
import { GuideHero } from '../../GuideHero/GuideHero';
import { H4 } from '../../H4/H4';
import { SizeGuideStyled } from '../SizeGuide.styled';

import { SizeButton, Sizes } from './MeasureJeans.styled';

export interface MeasureJeansProps {
  toggleGuide(...args: any[]): unknown;
  changeStep(...args: any[]): unknown;
  changeSize(...args: any[]): unknown;
  selection: SizeSelection;
  sku?: string;
  sizes?: any;
  title?: string;
  backText?: string;
  waistText?: string;
  insideLegText?: string;
  showMySize?: string;
  sizeList?: any[];
  introText?: string;
}

export const MeasureJeans: React.FC<MeasureJeansProps> = ({
  toggleGuide,
  changeStep,
  changeSize,
  selection,
  sku = '',
  sizes,
  title = '',
  backText = '',
  waistText = 'Waist',
  insideLegText = 'Inside leg',
  showMySize = 'Show my size',
  sizeList,
  introText,
}) => {
  const validateScreen = () => {
    // return selection.waistSize && selection.legSize ? false : true;
    return false;
  };

  function waistMapper(item: any, index: number) {
    const onClick: React.MouseEventHandler = () => {
      changeSize(item.value, 'waistSize');
    };
    return (
      <SizeButton
        $selected={item.value === selection.waistSize}
        key={`Size-Guide-Waist-Group-${index}`}
        onClick={onClick}
      >
        {`${item.value}"`}
      </SizeButton>
    );
  }

  function legMapper(item: any, index: number) {
    const onClick: React.MouseEventHandler = () => {
      changeSize(item.leg, 'legSize');
    };
    return (
      <SizeButton
        $selected={`${item.leg}`.trim() === `${selection.legSize}`.trim()}
        key={`Size-Guide-Leg-Group-${index}`}
        onClick={onClick}
      >
        {`${item.leg}"`}
      </SizeButton>
    );
  }

  return (
    <SizeGuideStyled>
      <GuideHero
        title={title}
        introText={introText}
        isBig={false}
        backText={backText}
        currentStep={1}
        changeStep={changeStep}
        guideType="size"
      />

      {!!sizeList?.length && (
        <>
          <H4 center>{waistText}</H4>
          <Sizes>{sizeList[0].waist.map(waistMapper)}</Sizes>
          <H4 center>{insideLegText}</H4>
          <Sizes>{sizeList.map(legMapper)}</Sizes>
        </>
      )}

      <div className="Summary">
        <Button
          disabled={validateScreen()}
          onClick={() => changeStep(SIZE_GUIDE_STEPS['RESULT'])}
        >
          {showMySize}
        </Button>
      </div>
    </SizeGuideStyled>
  );
};
