import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/snickers/types';

import { DoubleImageStyled, ImageContainer } from './DoubleImage.styled';

export interface DoubleImageProps {
  images: ImageFragment[];
}

export const DoubleImage: React.FC<DoubleImageProps> = ({ images }) => {
  const imageMapper = (image: ImageFragment, index: number) => {
    return (
      <ImageContainer key={`dual-image-${index}`}>
        <Image
          alt={image.alt || ''}
          src={image.responsiveImage?.src || ''}
          blurDataURL={image.responsiveImage?.base64 || ''}
          placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
          fill
          sizes={`100vw, (min-width (min-width: ${breakpoints.mobileMax})) 50vw`}
        />
      </ImageContainer>
    );
  };
  return <DoubleImageStyled>{images.map(imageMapper)}</DoubleImageStyled>;
};
