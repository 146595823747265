import { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  GridColumnGapProp,
  GridColumnsProp,
  GridRowGap,
  GridStyled,
} from './Grid.styled';

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  columnGap?: GridColumnGapProp;
  columns?: GridColumnsProp;
  minColumnWidth?: string;
  rowGap?: GridRowGap;
}

export const Grid: React.FC<GridProps> = ({
  children,
  align = 'stretch',
  columnGap = [{ columnGap: 40 }, { breakpoint: 'mobileMax', columnGap: 20 }],
  margin = '0 auto',
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  maxWidth = `${breakpoints.maxPageWidth}`,
  minColumnWidth,
  rowGap = 0,
  className,
}) => {
  return (
    <GridStyled
      $align={align}
      $columnGap={columnGap}
      $columns={columns}
      $margin={margin}
      $maxWidth={maxWidth}
      $minColumnWidth={minColumnWidth}
      $rowGap={rowGap}
      className={className}
    >
      {children}
    </GridStyled>
  );
};
