import { useId } from 'react';

import Head from 'next/head';
import Script from 'next/script';

import { VideoStyled } from './AzureVideoPlayer.styled';
export interface AzureVideoPlayerProps {
  src: string;
  autoplay?: boolean;
  poster?: string;
}

export const AzureVideoPlayer: React.FC<AzureVideoPlayerProps> = ({
  src,
  poster,
  autoplay,
}) => {
  const id = useId();
  const cacheBuster = `ckcachebust=${id}`;

  return (
    <VideoStyled>
      <Head>
        <link
          href="//amp.azure.net/libs/amp/latest/skins/amp-flush/azuremediaplayer.min.css"
          rel="stylesheet"
        />
      </Head>
      <Script
        src={`//amp.azure.net/libs/amp/latest/azuremediaplayer.min.js?${cacheBuster}`}
        strategy="afterInteractive"
      />
      <video
        id={`azure-video-${id}`}
        className="azuremediaplayer amp-flush-skin"
        controls={true}
        poster={poster}
        autoPlay={autoplay}
        data-setup='{"nativeControlsForTouch": false,"fluid":true}'
      >
        <source src={src} type="application/vnd.ms-sstr+xml" />
        {/* <p className="amp-no-js">
          To view this video please enable JavaScript, and consider
          upgrading to a web browser that supports HTML5 video
        </p> */}
      </video>
    </VideoStyled>
  );
};
