import { PlugsGridStyled } from './PlugsGrid.styled';

export interface PlugsGridProps {
  children?: React.ReactNode;
  className?: string;
  threeColumns?: boolean;
}

export const PlugsGrid: React.FC<PlugsGridProps> = ({
  children,
  threeColumns,
  className,
}) => {
  return (
    <PlugsGridStyled $threeColumns={threeColumns} className={className}>
      {children}
    </PlugsGridStyled>
  );
};
