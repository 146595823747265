import dynamic from 'next/dynamic';

import { OfficeRecord, OfficesFragment } from '@hultafors/snickers/types';

import MailSvg from '../../svg/mail.svg';
import NextSvg from '../../svg/next.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';
import { StoreListStyled } from '../StoreList/StoreList.styled';

import { Heading } from './contact-stores-styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion)
);
const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem)
);

export interface ContactStoresProps {
  salesOfficesTitle: string;
  exportPartnersTitle: string;
  viewOnMapLabel: string;
  localWebLabel: string;
  offices: Partial<OfficeRecord>[];
  companyName?: string;
  priority?: boolean;
}

interface OfficesProps extends OfficesFragment {
  priority?: boolean;
}

export const ContactStores: React.FC<ContactStoresProps | OfficesProps> = ({
  salesOfficesTitle,
  exportPartnersTitle,
  viewOnMapLabel,
  localWebLabel,
  offices = [],
  priority,
}) => {
  const salesOffices: Partial<OfficeRecord>[] =
    offices.filter((item) => !item.isExportMarket) || [];
  const exportMarkets: Partial<OfficeRecord>[] =
    offices.filter((item) => item.isExportMarket) || [];

  const renderAccordion = (items: Partial<OfficeRecord>[]) => (
    <StoreListStyled>
      <Accordion allowZeroExpanded>
        {items.map(
          (
            {
              name,
              address,
              zipcode,
              companyName,
              country,
              email,
              phoneNumber,
              companyWebsite,
            },
            index
          ) => {
            return (
              <AccordionItem label={name || ''} key={`Store-${index}`}>
                {companyName && (
                  <Paragraph className="CompanyName">{companyName}</Paragraph>
                )}
                <Paragraph className="Address">
                  {address}
                  {zipcode && (
                    <>
                      <br />
                      {zipcode}
                    </>
                  )}
                  {country && (
                    <>
                      <br />
                      {country}
                    </>
                  )}
                </Paragraph>
                <Paragraph className="ViewOnMap">
                  <a
                    href={generateGoogleMapLinks({
                      address,
                      country,
                      name,
                      zipcode,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="LeftIconLink"
                  >
                    <PlaceTagSvg
                      width={11}
                      height={15}
                      aria-hidden={true}
                      focusable={false}
                    />
                    {viewOnMapLabel}
                  </a>
                </Paragraph>

                <div className="Contact EmailAndPhoneWrapper">
                  {email && (
                    <div className="Email">
                      <a href={`mailto:${email}`} className="LeftIconLink">
                        <MailSvg
                          width={11}
                          aria-hidden={true}
                          focusable={false}
                        />
                        {email}
                      </a>
                    </div>
                  )}
                  {phoneNumber && (
                    <div className="Phone">
                      <a href={`tel:${phoneNumber}`} className="LeftIconLink">
                        <PhoneSvg
                          width={11}
                          aria-hidden={true}
                          focusable={false}
                        />
                        {phoneNumber}
                      </a>
                    </div>
                  )}
                </div>
                {companyWebsite && (
                  <div className="Contact VisitSite">
                    <a
                      href={companyWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {localWebLabel}
                      <NextSvg
                        width={11}
                        height={15}
                        aria-hidden={true}
                        focusable={false}
                      />
                    </a>
                  </div>
                )}
              </AccordionItem>
            );
          }
        )}
      </Accordion>
    </StoreListStyled>
  );

  const generateGoogleMapLinks = ({
    name,
    country,
    address,
    zipcode,
  }: Partial<OfficeRecord>) => {
    // direct links to google maps based on address data
    const baseLink = 'https://www.google.com/maps/search/?';
    const query = new URLSearchParams({
      api: '1',
      query: [name, country, address, zipcode]
        .filter((value) => !!value)
        .join('+'),
    }).toString();
    return `${baseLink}${query}`;
  };

  if (!offices?.length || !salesOfficesTitle) {
    return null;
  }

  return (
    <Section largeMarginTop={!priority}>
      <Grid
        columnGap={[
          { columnGap: 15 },
          { breakpoint: 'mobileMax', columnGap: 20 },
        ]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {salesOfficesTitle && salesOffices.length > 0 && (
            <Heading>{salesOfficesTitle}</Heading>
          )}
          {salesOffices.length > 0 && renderAccordion(salesOffices)}
          {exportPartnersTitle && exportMarkets.length > 0 && (
            <Heading>{exportPartnersTitle}</Heading>
          )}
          {exportMarkets.length > 0 && renderAccordion(exportMarkets)}
        </GridChild>
      </Grid>
    </Section>
  );
};
