import {
  SnickersProduct,
  SnickersProductDetails,
} from '@hultafors/snickers/types';

export const convertDetailsToList = (
  details: SnickersProductDetails,
  productListId: string
): SnickersProduct => {
  const listItem: SnickersProduct = {
    category: details.category,
    certificates: details.certifications,
    currency: details.price?.currency,
    image: {
      url: details.images[0]?.url || '',
    },
    isDefault: false,
    isNew: details.isNew,
    name: details.name,
    // parentSlug?: string;
    // slu?: string;
    // attributes?: { label: string; value: string | number }[];
    // TODO these are dubious
    // isPremium?: boolean;
    // categorySlug?: string;
    premiumLabel: '',

    price: details.price?.value,

    productId: details.id,

    productListId,
    sku: details.sku,
    sustainableLabel: details.sustainability[0] || '',
    // pictograms?: any[];
    // colors?: ProductDetailsValue[];
  };
  return listItem;
};
