import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/snickers/helpers';

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
  }
`;

export const StyledSelect = styled.select`
  appearance: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.xSmall};
  border: 1px solid ${colors.gray3};
  font-weight: ${fontWeights.normal};
  background: url('/assets/gfx/arrow-down.svg');
  background-size: ${fontSizes.mini1};
  background-position: calc(100% - ${spacing.xSmall}) center;
  background-repeat: no-repeat;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.xSmall};
  }

  &:focus {
    outline: none;
  }

  option {
    padding: ${spacing.small} ${spacing.tiny};
    line-height: 1.4em !important;

    &:disabled {
      color: ${colors.gray2};
    }
  }
`;
