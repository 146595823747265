import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontFamilies } from '@hultafors/snickers/helpers';

export interface LinkButtonStyledProps {
  $large?: boolean;
}

export const LinkButtonStyled = styled(Link)<LinkButtonStyledProps>`
  display: block;
  height: ${({ $large }) => ($large ? '48px' : '40px')};
  padding: 0 16px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${colors.workwearYellow};
  background-color: ${colors.workwearYellow};

  &.Grey {
    background-color: ${colors.gray4};
    border-color: ${colors.gray4};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.grayBtnHover};
      border-color: ${colors.grayBtnHover};
    }
  }

  &:hover {
    background-color: ${colors.workwearYellow2};
    border-color: ${colors.workwearYellow2};
  }

  color: ${colors.workwearBlack};
  text-decoration: none;
  font-family: ${fontFamilies.mikro};
  font-size: 14px;
  line-height: ${({ $large }) => ($large ? '48px' : '40px')};
  letter-spacing: 2px;
  text-transform: uppercase;
`;
