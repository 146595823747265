import { Container } from './WorkwearGuideInner.styled';

interface WorkwearGuideInnerProps {
  children?: React.ReactNode;
}

export const WorkwearGuideInner: React.FC<WorkwearGuideInnerProps> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};
