import { ApiSettings } from '@hultafors/shared/types';

import { PageNotFoundFragment } from '@hultafors/snickers/types';

import { HeroBlock } from '../hero-block/hero-block';
import { NotFoundProducts } from '../NotFoundProducts/NotFoundProducts';

interface NotFoundPageContentProps {
  settings: ApiSettings;
  content: PageNotFoundFragment;
}
export const NotFoundPageContent: React.FC<NotFoundPageContentProps> = ({
  // settings: defaultSettings,
  // content: defaultContent,
  content,
}) => {
  // const [, setSettings] = useState<ApiSettings>(defaultSettings);
  // const [content, setContent] = useState<PageNotFoundFragment | undefined>(
  //   defaultContent,
  // );
  // const { setGlobal, setAllMenuItems, setFooter } = useGlobal();
  // const { data, error } = useSWR<PageNotFoundResponse>('/api/pageNotFound', {
  //   revalidateOnMount: false,
  // });

  // useEffect(() => {
  //   startTransition(() => {
  //     setSettings(getApiSettingsFromHost(window.location.hostname));
  //   });
  // }, []);

  // useEffect(() => {
  //   if (data?.content) {
  //     startTransition(() => {
  //       setContent(data.content);
  //     });
  //   }
  //   if (data?.global) {
  //     startTransition(() => {
  //       setGlobal(data.global);
  //     });
  //   }
  //   if (data?.footer) {
  //     startTransition(() => {
  //       setFooter(data.footer);
  //     });
  //   }
  //   if (data?.allMenuItems?.length && setAllMenuItems) {
  //     startTransition(() => {
  //       setAllMenuItems(data.allMenuItems);
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (error) {
  //     startTransition(() => {
  //       setContent(defaultContent);
  //     });
  //   }
  // }, [error]);

  if (!content) {
    return null;
  }
  return (
    <>
      <HeroBlock
        id="404-hero"
        heroIcon={true}
        description={content.heroDescription}
        title={content.heroTitle}
        subTitle={content.heroSubTitle}
      />
      {content?.products && <NotFoundProducts products={content.products} />}
    </>
  );
};
