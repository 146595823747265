import styled, { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';

interface CollapseStyledProps {
  $height?: CSSProperties['height'];
  $small?: boolean;
  $noPadding?: boolean;
  $inTabs?: boolean;
}

export const CollapseStyled = styled.div<CollapseStyledProps>`
  border-top: thin solid ${colors.gray4};
  height: auto;
  padding: ${({ $noPadding, $inTabs }) =>
    $noPadding ? '0' : $inTabs ? '0' : '0 20px'};
  margin: ${({ $inTabs }) => ($inTabs ? '0' : '0 -20px 0 -20px')};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0;
    padding: 0;
  }

  &:first-of-type {
    border-block-start: ${({ $inTabs }) =>
      $inTabs ? 'none' : '1px solid ' + colors.gray4};
  }

  &:last-of-type:not(:nth-of-type(1)) {
    border-block-end: 1px solid ${colors.gray4};
  }

  .CollapseHeader {
    position: relative;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    inline-size: 100%;
    cursor: pointer;
    block-size: ${({ $small }) => ($small ? '56px' : '70px')};
    margin: 0;
    padding: ${({ $noPadding }) => ($noPadding ? '0 20px' : '0')};
    padding-inline-end: 16px;

    .InnerCollapse {
      display: flex;
      flex-direction: column;

      h4 {
        inline-size: 100%;
        margin: 0;
      }

      .SubLabel {
        font-size: ${fontSizes.m};
        color: ${colors.gray1};
        inline-size: 100%;
        display: inline-block;
        margin-block-start: 0.25rem;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-flex;
      inset-inline-end: ${({ $noPadding }) => ($noPadding ? '20px' : '0')};
      inset-block-start: 50%;
      inline-size: 12px;
      block-size: 12px;
      background: url('/assets/gfx/chevron.svg') no-repeat center center;
      transform: translateY(-50%);
      transition: transform 0.3s;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .AccordionSubItem a {
    block-size: auto;
    inline-size: 100%;
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    line-height: 56px;
    text-decoration: none;
  }

  .CollapseContent {
    block-size: auto;
    max-block-size: 0;
    transition: max-height 0.4s;
    overflow-y: hidden;
    box-sizing: border-box;

    &.isOpen {
      max-block-size: ${({ $height }) =>
        typeof $height === 'number' ? `${$height}px` : $height};
    }

    span {
      display: flex;
      block-size: 56px;
      inline-size: 100%;
      border-block-start: 1px solid ${colors.gray4};

      a {
        text-decoration: none;
        inline-size: 100%;
        margin: 0;
        line-height: 56px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .notCollapsibleItem h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 56px;
      letter-spacing: 0.2px;
    }

    /* Nestled accordions: */
    .CollapseHeader {
      block-size: 56px;
      font-size: 14px;
      font-weight: 400;
      line-height: 56px;
      letter-spacing: 0.2px;

      .InnerCollapse {
        display: flex;
        flex-direction: column;

        h4 {
          inline-size: 100%;
          margin: 0;
        }

        .SubLabel {
          font-size: ${fontSizes.m};
          color: ${colors.gray1};
          inline-size: 100%;
          display: inline-block;
          margin-block-start: 0.25rem;
        }
      }
    }

    .CollapseContent {
      span {
        block-size: 40px;
        padding-inline-start: 15px;
        border: none;

        &:last-of-type {
          margin-block-end: 15px;
        }

        &.AccordionSubItem {
          inline-size: auto;
        }
      }

      a {
        font-weight: ${fontWeights.normal};
        line-height: 40px;
      }
    }

    .notCollapsibleItem {
      block-size: 56px;
    }
  }

  .AccordionSubItem {
    display: flex;
    block-size: 56px;
    border-block-start: 1px solid ${colors.gray4};
  }
`;
