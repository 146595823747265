import { AccordionOldStyled } from './AccordionOld.styled';

export interface AccordionOldProps {
  children?: React.ReactNode;
  small?: boolean;
  allowMultipleOpen?: boolean;
  callBack?(...args: any[]): unknown;
  selectItem?(...args: any[]): unknown;
  selectedItemId?: string;
  noPadding?: boolean;
  showCollapseButton?: boolean;
  keyPrefix?: string;
  collapseAllText?: string;
  inTabs?: boolean;
  className?: string;
  items?: React.ReactNode[];
  openItems?: number[];
}

export const AccordionOld: React.FC<AccordionOldProps> = ({
  items = [],
  openItems = [],
  children,
  className,
  ...rest
}) => {
  return (
    <AccordionOldStyled
      {...rest}
      className={['Accordion', className].filter(Boolean).join(' ')}
    >
      {children}
    </AccordionOldStyled>
  );
};
