import { SizeItem, WaistList } from '@hultafors/snickers/types';

export const trousersWomanWaists: WaistList = {
  body: [
    { cm: { max: 71, min: 68 }, inch: { max: 28, min: 27 } },
    { cm: { max: 75, min: 72 }, inch: { max: 29, min: 29 } },
    { cm: { max: 79, min: 76 }, inch: { max: 31, min: 30 } },
    { cm: { max: 83, min: 80 }, inch: { max: 32, min: 32 } },
    { cm: { max: 87, min: 84 }, inch: { max: 34, min: 33 } },
    { cm: { max: 91, min: 88 }, inch: { max: 36, min: 35 } },
    { cm: { max: 95, min: 92 }, inch: { max: 37, min: 37 } },
    { cm: { max: 99, min: 96 }, inch: { max: 39, min: 38 } },
    { cm: { max: 107, min: 100 }, inch: { max: 42, min: 40 } },
    { cm: { max: 115, min: 108 }, inch: { max: 45, min: 43 } },
    { cm: { max: 123, min: 116 }, inch: { max: 48, min: 46 } },
    { cm: { max: 131, min: 124 }, inch: { max: 51, min: 49 } },
    { cm: { max: 139, min: 132 }, inch: { max: 54, min: 52 } },
    { cm: { max: 147, min: 140 }, inch: { max: 58, min: 55 } },
    { cm: { max: 155, min: 148 }, inch: { max: 61, min: 59 } },
    { cm: { max: 163, min: 156 }, inch: { max: 64, min: 62 } },
    { cm: { max: 171, min: 164 }, inch: { max: 67, min: 65 } },
    { cm: { max: 179, min: 172 }, inch: { max: 70, min: 68 } },
  ],
  garment: [
    { cm: { max: 68, min: 65 }, inch: { max: 27, min: 26 } },
    { cm: { max: 72, min: 69 }, inch: { max: 28, min: 28 } },
    { cm: { max: 76, min: 73 }, inch: { max: 30, min: 29 } },
    { cm: { max: 80, min: 77 }, inch: { max: 31, min: 31 } },
    { cm: { max: 84, min: 81 }, inch: { max: 33, min: 32 } },
    { cm: { max: 88, min: 85 }, inch: { max: 35, min: 34 } },
    { cm: { max: 92, min: 89 }, inch: { max: 36, min: 36 } },
    { cm: { max: 96, min: 93 }, inch: { max: 38, min: 37 } },
    { cm: { max: 102, min: 97 }, inch: { max: 40, min: 39 } },
    { cm: { max: 108, min: 103 }, inch: { max: 43, min: 41 } },
    { cm: { max: 114, min: 109 }, inch: { max: 45, min: 44 } },
    { cm: { max: 120, min: 115 }, inch: { max: 47, min: 46 } },
    { cm: { max: 126, min: 121 }, inch: { max: 50, min: 48 } },
    { cm: { max: 132, min: 127 }, inch: { max: 52, min: 51 } },
    { cm: { max: 138, min: 133 }, inch: { max: 54, min: 53 } },
    { cm: { max: 144, min: 139 }, inch: { max: 57, min: 55 } },
    { cm: { max: 150, min: 145 }, inch: { max: 59, min: 58 } },
    { cm: { max: 156, min: 151 }, inch: { max: 61, min: 60 } },
  ],
};

export const trousersWoman: SizeItem[] = [
  {
    id: 'trousers_w_1',
    leg: {
      body: {
        cm: { max: 70, min: 65 },
        inch: { max: 27, min: 26 },
      },
      garment: {
        cm: { max: 70, min: 65 },
        inch: { max: 27, min: 26 },
      },
    },
    name: 'Extra Short',
    sizes: [
      {
        size: 116,
      },
      {
        size: 117,
      },
      {
        size: 118,
      },
      {
        size: 119,
      },
      {
        size: 120,
      },
      {
        size: 121,
      },
      {
        size: 122,
      },
      {
        size: 123,
      },
      {
        size: 124,
      },
      {
        size: 125,
      },
      {
        size: 126,
      },
      {
        size: 127,
      },
      {
        size: 128,
      },
      {
        size: 129,
      },
      {
        size: 130,
      },
      {
        size: 131,
      },
      {
        size: 132,
      },
      {
        size: 133,
      },
    ],
  },
  {
    id: 'trousers_w_2',
    leg: {
      body: {
        cm: { max: 76, min: 71 },
        inch: { max: 30, min: 28 },
      },
      garment: {
        cm: { max: 76, min: 71 },
        inch: { max: 30, min: 28 },
      },
    },
    name: 'Short',
    sizes: [
      {
        size: 16,
      },
      {
        size: 17,
      },
      {
        size: 18,
      },
      {
        size: 19,
      },
      {
        size: 20,
      },
      {
        size: 21,
      },
      {
        size: 22,
      },
      {
        size: 23,
      },
      {
        size: 24,
      },
      {
        size: 25,
      },
      {
        size: 26,
      },
      {
        size: 27,
      },
      {
        size: 628,
      },
      {
        size: 629,
      },
      {
        size: 630,
      },
      {
        size: 631,
      },
      {
        size: 632,
      },
      {
        size: 633,
      },
    ],
  },
  {
    id: 'trousers_w_3',
    leg: {
      body: {
        cm: { max: 82, min: 77 },
        inch: { max: 31, min: 31 },
      },
      garment: {
        cm: { max: 82, min: 77 },
        inch: { max: 32, min: 31 },
      },
    },
    name: 'Regular',
    sizes: [
      {
        size: 32,
      },
      {
        size: 34,
      },
      {
        size: 36,
      },
      {
        size: 38,
      },
      {
        size: 40,
      },
      {
        size: 42,
      },
      {
        size: 44,
      },
      {
        size: 46,
      },
      {
        size: 48,
      },
      {
        size: 50,
      },
      {
        size: 52,
      },
      {
        size: 54,
      },
      {
        size: 756,
      },
      {
        size: 758,
      },
      {
        size: 760,
      },
      {
        size: 762,
      },
      {
        size: 764,
      },
      {
        size: 766,
      },
    ],
  },
  {
    id: 'trousers_w_4',
    leg: {
      body: { cm: { max: 88, min: 83 }, inch: { max: 34, min: 33 } },
      garment: { cm: { max: 88, min: 83 }, inch: { max: 34, min: 33 } },
    },
    name: 'Long',
    sizes: [
      {
        size: 64,
      },
      {
        size: 68,
      },
      {
        size: 72,
      },
      {
        size: 76,
      },
      {
        size: 80,
      },
      {
        size: 84,
      },
      {
        size: 88,
      },
      {
        size: 92,
      },
      {
        size: 96,
      },
      {
        size: 100,
      },
      {
        size: 104,
      },
      {
        size: 108,
      },
      {
        size: 112,
      },
      {
        size: 816,
      },
      {
        size: 820,
      },
      {
        size: 824,
      },
      {
        size: 828,
      },
      {
        size: 832,
      },
    ],
  },
  {
    id: 'trousers_w_5',
    leg: {
      body: {
        cm: { max: 94, min: 89 },
        inch: { max: 37, min: 35 },
      },
      garment: { cm: { max: 94, min: 89 }, inch: { max: 37, min: 35 } },
    },
    name: 'Extra Long',
    sizes: [
      {
        size: 164,
      },
      {
        size: 168,
      },
      {
        size: 172,
      },
      {
        size: 176,
      },
      {
        size: 180,
      },
      {
        size: 184,
      },
      {
        size: 188,
      },
      {
        size: 192,
      },
      {
        size: 196,
      },
      {
        size: 200,
      },
      {
        size: 204,
      },
      {
        size: 208,
      },
      {
        size: 212,
      },
      {
        size: 216,
      },
      {
        size: 220,
      },
      {
        size: 224,
      },
      {
        size: 228,
      },
      {
        size: 232,
      },
    ],
  },
];
