import styled, { CSSProperties } from 'styled-components';

interface FlexStyledProps {
  $align?: CSSProperties['alignItems'];
  $justify?: CSSProperties['justifyContent'];
  $dir?: CSSProperties['flexDirection'];
  $m?: CSSProperties['margin'];
  $p?: CSSProperties['padding'];
  $wrap?: boolean;
}

export const FlexStyled = styled.div<FlexStyledProps>`
  display: flex;
  flex-flow: ${({ $dir }) => $dir} ${({ $wrap }) => ($wrap ? 'wrap' : 'nowrap')};
  justify-content: ${({ $justify }) => $justify};
  align-items: ${({ $align }) => $align};
  margin: ${({ $m }) => $m};
  padding: ${({ $p }) => $p};
`;
