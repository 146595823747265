import Link from 'next/link';
import styled from 'styled-components';

import { colors, spacing } from '@hultafors/snickers/helpers';

export interface RouterLinkStyledProps {
  $type?: 'special';
}

export const Inner = styled.span<RouterLinkStyledProps>``;

export const RouterLinkStyled = styled(Link)<RouterLinkStyledProps>`
  display: inline-flex;
  gap: ${spacing.tinier};
  align-items: center;
  height: 100%;
  color: #333;
  border-bottom: ${({ $type }) =>
    $type === 'special' ? 'solid 1px #aaa' : 'none'};
  margin-block-start: ${({ $type }) =>
    $type === 'special' ? '30px' : 'unset'};
  margin-inline-end: ${({ $type }) => ($type === 'special' ? '20px' : 'unset')};
  text-decoration: none;
  transition: all 0.2s;
  position: relative;

  &:hover {
    border-color: #555;
    color: ${colors.grayBtnHover};
    transition: all 0.2s;
  }

  &:focus {
    color: inherit;
    box-shadow: none !important;
    outline: 0;
    border: 3px auto -webkit-focus-ring-color !important;
  }

  &:active {
    color: inherit;
  }
`;
