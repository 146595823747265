import { useGlobal } from '@hultafors/snickers/hooks';

import ClearSvg from './clear.svg';
import SearchSvg from './search.svg';
import {
  ClearButton,
  SearchButton,
  SearchField,
  SearchFieldForm,
  SearchFieldWrapper,
} from './SearchInput.styled';

interface SearchInputProps {
  onSubmit: React.FormEventHandler;
  onChange: React.ChangeEventHandler;
  onClear(): void;
  value: string;
  name?: string;
  placeholder?: string;
  className?: string;
  tall?: boolean;
  transparent?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  onChange,
  onClear,
  name = 'q',
  placeholder,
  value,
  tall,
  transparent,
  className,
}) => {
  const { global } = useGlobal();
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      onClear();
    }
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchFieldWrapper $tall={tall} className={className}>
      <SearchFieldForm onSubmit={onSubmit} autoComplete="off">
        <SearchField
          type="text"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          name={name}
          $transparent={transparent}
          onKeyDown={handleKeyDown}
        />
        <ClearButton
          $transparent={transparent}
          $show={value.length > 0}
          onClick={onClear}
          aria-label={global?.clear || ''}
        >
          <ClearSvg
            focusable="false"
            aria-hidden="true"
            width={12}
            height={12}
          />
        </ClearButton>
        <SearchButton
          $transparent={transparent}
          disabled={!value.length}
          type="submit"
          aria-label={global?.search || ''}
        >
          <SearchSvg
            focusable="false"
            aria-hidden="true"
            width={16}
            height={16}
          />
        </SearchButton>
      </SearchFieldForm>
    </SearchFieldWrapper>
  );
};
